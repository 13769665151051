import LogoLoader from 'components/Loader/LogoLoader.component';

type Props = {
  title: string;
};

const LoadingScreen = ({ title }: Props) => {
  return (
    <div className="grid h-screen place-items-center">
      <div className="flex flex-col items-center">
        <LogoLoader />
        <div className="text-4xl mt-8">{title}</div>
      </div>
    </div>
  );
};
export default LoadingScreen;
