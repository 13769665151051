import {
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  Box,
  AccordionPanel,
  List,
  ListItem,
  Progress,
} from '@chakra-ui/react';
import './AccordBarChartList.style.css';
import { useState } from 'react';
import { BarChartMultiData } from 'types/DataSet.type';

type AccordBarChartListProps = {
  data: BarChartMultiData[];
  isExpandAll: boolean;
  colorKey?: Record<string, string>;
};

const AccordBarChartList = ({ data, isExpandAll, colorKey }: AccordBarChartListProps) => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>(
    isExpandAll ? data.map((_, i) => i) : []
  );
  const toggleIndex = (index: number) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes((activeIndexes) => activeIndexes.filter((i) => i !== index));
    } else {
      setActiveIndexes((activeIndexes) => [...activeIndexes, index]);
    }
  };
  return (
    <>
      <Accordion allowMultiple index={activeIndexes}>
        {data.map((group, i) => {
          const groupKeys = Object.keys(group).filter((key) => key !== 'name');
          const groupTotalCount = groupKeys.reduce((acc, key) => {
            return acc + Number(group[key]);
          }, 0);
          return (
            <AccordionItem key={i}>
              <h2>
                <AccordionButton
                  onClick={() => {
                    toggleIndex(i);
                  }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    {`${group.name} (${groupTotalCount})`}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <List className={`list-acc-${i}`}>
                  {colorKey && (
                    <style>
                      {`.list-acc-${i} div[role="progressbar"] {
                            background:${colorKey?.[group.name.toUpperCase()] || 'green'}
                          }`}
                    </style>
                  )}
                  {groupKeys.map((key, i) => {
                    const percent = (100 * (Number(group[key]) || 0)) / groupTotalCount;
                    return (
                      <ListItem key={i} className="mb-4">
                        <div className={'flex mt-2 ' + `group-item-${i}`}>
                          <div className="w-full">
                            <Progress colorScheme="whatsapp" height="32px" value={percent} />
                          </div>
                          <div className="flex w-[94%] mt-2 -ml-[96%] z-10 text-sm font-bold text-gray-800 justify-between">
                            <span>{key}</span>
                            <span>
                              {group[key]}{' '}
                              <span className="text-gray-600 text-xs">({percent.toFixed(1)}%)</span>
                            </span>
                          </div>
                        </div>
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
};

export default AccordBarChartList;
