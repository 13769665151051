/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ResponseColumn } from 'types/DataSet.type';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { IconButton, Link, Tooltip } from '@chakra-ui/react';
import TransKeys from 'services/i18n/keys/transKeys';
//import { Link } from 'react-router-dom';
const columnHelper = createColumnHelper<ResponseColumn>();

function getSource(links: string[] | undefined) {
  if (!links || links.length === 0) {
    return undefined;
  }
  const uniqueLinks = [...new Set(links)];
  return (
    <>
      {uniqueLinks.map((link) => (
        <Link href={link} target="_blank" key={link}>
          <Tooltip label={link}>
            <IconButton
              isRound={true}
              variant="outline"
              colorScheme="facebook"
              aria-label="Done"
              size={'xs'}
              icon={<ExternalLinkIcon />}
            />
          </Tooltip>
        </Link>
      ))}
    </>
  );
}

const responseColumns: (
  meta: string[],
  hideQuestions: boolean,
  hasLinks: boolean
) => ColumnDef<ResponseColumn, any>[] = (meta, hideQuestions, hasLinks) => {
  const questions = !hideQuestions
    ? [
        columnHelper.accessor('question', {
          cell: (info) => info.getValue(),
          header: TransKeys.responseTable.question,
          size: 80,
        }),
      ]
    : [];
  const links = hasLinks
    ? [
        columnHelper.accessor('links', {
          cell: (info) => getSource(info.getValue() as string[]),
          header: TransKeys.responseTable.link,
          size: 10,
        }),
      ]
    : [];
  return [
    ...questions,
    columnHelper.accessor('response', {
      cell: (info) => info.getValue(),
      header: TransKeys.responseTable.response,
      meta: {
        isResponse: true,
        vw: 40,
      },
      size: 500,
    }),
    ...links,
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: TransKeys.responseTable.name,
    }),
    ...meta.map((m) =>
      columnHelper.accessor(m, {
        cell: (info) => info.getValue(),
        header: m,
      })
    ),

    columnHelper.accessor('themes', {
      cell: (info) => info.getValue(),
      header: TransKeys.responseTable.themes,
      meta: {
        isLabel: true,
        vw: 14,
      },
      size: 300,
    }),
  ];
};

export { responseColumns };
