import Footer from 'components/Footer/Footer.component';
import NavBar from 'components/MainNavBar/MainNavBar.component';
import ScrollToTop from 'components/Utility/ScrollToTop.component';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

const InformationalLayout = ({ children }: Props) => {
  return (
    <div className="h-full w-full min-h-screen">
      <NavBar />
      <ScrollToTop />
      {children}
      <div className="w-full absolute bottom-0">
        <Footer />
      </div>
    </div>
  );
};

export default InformationalLayout;
