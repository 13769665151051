import OpenAI from 'openai';

type CMessages = OpenAI.Chat.Completions.ChatCompletionMessage;

const getTranscript = (messages: CMessages[], response: string) => {
  const transcript = messages.map(
    ({ role, content }) => `${role == 'assistant' ? 'user' : 'Interviewer'}: ${content}`
  );
  transcript.push(`user: ${response}`);
  return transcript.join('\n');
};

export { getTranscript };
