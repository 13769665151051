const Test = () => {
  return <div>Test.component</div>;
};

// eslint-disable-next-line import/no-unused-modules
export default Test;

// import React, { useState, useRef } from 'react';

// const AudioRecorder: React.FC = () => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [audioURL, setAudioURL] = useState<string | null>(null);
//   const mediaRecorderRef = useRef<MediaRecorder | null>(null);
//   const audioChunksRef = useRef<Blob[]>([]);

//   const startRecording = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       const mediaRecorder = new MediaRecorder(stream);
//       mediaRecorderRef.current = mediaRecorder;
//       audioChunksRef.current = [];

//       mediaRecorder.ondataavailable = (event) => {
//         audioChunksRef.current.push(event.data);
//       };

//       mediaRecorder.onstop = () => {
//         const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
//         const audioUrl = URL.createObjectURL(audioBlob);
//         setAudioURL(audioUrl);
//       };

//       mediaRecorder.start();
//       setIsRecording(true);
//     } catch (error) {
//       console.error('Error accessing microphone:', error);
//     }
//   };

//   const stopRecording = () => {
//     if (mediaRecorderRef.current) {
//       mediaRecorderRef.current.stop();
//       setIsRecording(false);
//     }
//   };

//   return (
//     <div className="flex flex-col items-center">
//       {!isRecording ? (
//         <button
//           onClick={startRecording}
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//         >
//           Start Recording
//         </button>
//       ) : (
//         <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2">
//           <button
//             onClick={stopRecording}
//             className="bg-red-500 hover:bg-red-700 text-white font-bold p-4 rounded-full flex items-center justify-center"
//           >
//             <span className="mr-2">Stop</span>
//             <div className="w-4 h-4 bg-white rounded-full animate-pulse"></div>
//           </button>
//         </div>
//       )}
//       {audioURL && (
//         <audio className="mt-4" controls src={audioURL}>
//           Your browser does not support the audio element.
//         </audio>
//       )}
//     </div>
//   );
// };

// export default AudioRecorder;

// // import React, { useState, useRef, useCallback, useEffect } from 'react';
// // import { firebase } from 'services/Firebase/Firebase.service';

// // const Test: React.FC = () => {
// //   const [isRecording, setIsRecording] = useState(false);
// //   const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
// //   const [recordedAudioBlob, setRecordedAudioBlob] = useState<Blob | null>(null);
// //   const [error, setError] = useState<string | null>(null);
// //   const mediaRecorderRef = useRef<MediaRecorder | null>(null);
// //   const audioRecorderRef = useRef<MediaRecorder | null>(null);
// //   const videoRef = useRef<HTMLVideoElement>(null);
// //   const streamRef = useRef<MediaStream | null>(null);

// //   useEffect(() => {
// //     if (typeof MediaRecorder === 'undefined') {
// //       setError('MediaRecorder is not supported in this browser.');
// //     }
// //   }, []);

// //   const startRecording = useCallback(async () => {
// //     try {
// //       const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
// //       streamRef.current = stream;

// //       if (videoRef.current) {
// //         videoRef.current.srcObject = stream;
// //       }

// //       if (typeof MediaRecorder !== 'undefined') {
// //         // Video recorder
// //         const videoRecorder = new MediaRecorder(stream);
// //         mediaRecorderRef.current = videoRecorder;

// //         const videoChunks: BlobPart[] = [];
// //         videoRecorder.ondataavailable = (event) => {
// //           if (event.data.size > 0) {
// //             videoChunks.push(event.data);
// //           }
// //         };

// //         videoRecorder.onstop = () => {
// //           const blob = new Blob(videoChunks, { type: 'video/webm' });
// //           setRecordedBlob(blob);
// //         };

// //         // Audio recorder
// //         const audioStream = new MediaStream(stream.getAudioTracks());
// //         const audioRecorder = new MediaRecorder(audioStream);
// //         audioRecorderRef.current = audioRecorder;

// //         const audioChunks: BlobPart[] = [];
// //         audioRecorder.ondataavailable = (event) => {
// //           if (event.data.size > 0) {
// //             audioChunks.push(event.data);
// //           }
// //         };

// //         audioRecorder.onstop = () => {
// //           const blob = new Blob(audioChunks, { type: 'audio/webm' });
// //           setRecordedAudioBlob(blob);
// //         };

// //         videoRecorder.start();
// //         audioRecorder.start();
// //         setIsRecording(true);
// //       } else {
// //         console.warn('MediaRecorder not supported, using fallback method');
// //         // Implement a fallback method here if needed
// //       }
// //     } catch (error) {
// //       console.error('Error accessing media devices:', error);
// //       setError(
// //         'Failed to access media devices. Please check your camera and microphone permissions.'
// //       );
// //     }
// //   }, []);

// //   const stopRecording = useCallback(() => {
// //     if (mediaRecorderRef.current) {
// //       mediaRecorderRef.current.stop();
// //     }
// //     if (audioRecorderRef.current) {
// //       audioRecorderRef.current.stop();
// //     }
// //     setIsRecording(false);

// //     if (streamRef.current) {
// //       streamRef.current.getTracks().forEach((track) => track.stop());
// //     }
// //   }, []);

// //   if (error) {
// //     return (
// //       <div className="max-w-2xl mx-auto p-6 bg-red-100 rounded-lg shadow-md">
// //         <h2 className="text-2xl font-bold mb-4 text-center text-red-800">Error</h2>
// //         <p className="text-red-700">{error}</p>
// //       </div>
// //     );
// //   }

// //   return (
// //     <div className="max-w-2xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
// //       <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Media Recorder</h2>

// //       <div className="mb-4">
// //         <video ref={videoRef} autoPlay muted className="w-full h-auto rounded-lg shadow-sm" />
// //       </div>

// //       <div className="flex justify-center mb-4">
// //         {!isRecording ? (
// //           <button
// //             onClick={startRecording}
// //             className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
// //           >
// //             Start Recording
// //           </button>
// //         ) : (
// //           <button
// //             onClick={stopRecording}
// //             className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
// //           >
// //             Stop Recording
// //           </button>
// //         )}
// //       </div>

// //       {recordedBlob && (
// //         <div className="mt-6 p-4 bg-white rounded-lg shadow">
// //           <h3 className="text-xl font-semibold mb-2 text-gray-700">Recorded Video:</h3>
// //           <video
// //             controls
// //             src={URL.createObjectURL(recordedBlob)}
// //             className="w-full h-auto rounded-lg shadow-sm mb-4"
// //           />
// //           <div className="flex space-x-2">
// //             <a
// //               href={URL.createObjectURL(recordedBlob)}
// //               download="recorded-video.webm"
// //               className="inline-block px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
// //             >
// //               Download Video
// //             </a>
// //             {recordedAudioBlob && (
// //               <a
// //                 href={URL.createObjectURL(recordedAudioBlob)}
// //                 download="recorded-audio.webm"
// //                 className="inline-block px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 transition duration-300"
// //               >
// //                 Download Audio
// //               </a>
// //             )}
// //           </div>
// //         </div>
// //       )}
// //       <button
// //         onClick={async () => {
// //           if (recordedAudioBlob)
// //             await firebase().uploadAudio({
// //               audioBlob: recordedAudioBlob,
// //               cid: 'cid1',
// //               fileName: 'recorded-audio.webm',
// //               instanceId: 'insId1',
// //               userId: 'uid1',
// //             });
// //         }}
// //       >
// //         CLICK
// //       </button>
// //     </div>
// //   );
// // };

// // export default Test;
