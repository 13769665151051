/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/media-has-caption */
import {
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanels,
  TableContainer,
  Tabs,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { FaEye, FaFilter } from 'react-icons/fa6';
import BarChartDemo from 'components/Charts/BarChart.component';
import { BsChevronDoubleUp, BsChevronDoubleDown } from 'react-icons/bs';
import DataTable from 'components/Table/Table.component';
import PieChart from 'components/Charts/PieChart.component';
import HeaderStats from 'components/Header/Header.component';
import Navbar from 'components/Navbar/Navbar.component';
import {
  BarChartMultiData,
  Filter,
  FilterType,
  InsightData,
  PieChartData,
  ResponseColumn,
  ThemesData,
} from 'types/DataSet.type';
import { useDashboard } from 'services/Providers/StoreProvider';
import { observer } from 'mobx-react-lite';
import logger from 'services/Logger/Pino';
import { useNavigate, useParams } from 'react-router-dom';
import { firebase } from 'services/Firebase/Firebase.service';
import usePromise from 'hooks/usePromise';
import LoadingScreen from './LoadingScreen.view';
import MainError from './MainError.view';
import Show from 'components/Show.component';
import { AiOutlineClear } from 'react-icons/ai';
import AccordList from 'components/Charts/AccordList.component';
import FilterSideBar from 'components/SideBar/FilterSideBar.component';
import AccordBarChartList from 'components/Charts/AccordBarChartList.component';
import transKeys from 'services/i18n/keys/transKeys';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@chakra-ui/icons';
import { ChartContainer } from '../components/Charts/ChartContainer.component';

const log = logger.child({ context: 'Dashboard' });
// const {
//   demoTableData,
//   genderData,
//   locationData,
//   ageData,
//   sentimentData,
//   themesData,
//   totalCount,
//   questionTableData,
//   insightData,
// } = rawToStructured(sampleData);

type DemographicProps = {
  onPieChartSelection: (selectedValues: string[], key: FilterType) => void;
  participantsPieCharts: Record<string, PieChartData[]>;
  title: string;
  tooltipSuffix?: string;
};

const InsightModal = ({
  insightData,
  isOpen,
  onClose,
}: {
  insightData: InsightData[];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [isExpandAll, setExpandAll] = useBoolean();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW={'80%'}>
          <ModalHeader>Insights (beta)</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <div className="flex justify-between">
                <div className="text-gray-600 text-lg mb-4">
                  Generated insights based on the data. Click on the insight to expand
                </div>
                <IconButton
                  colorScheme="blue"
                  aria-label="Expand all"
                  size="md"
                  variant={'ghost'}
                  icon={isExpandAll ? <BsChevronDoubleDown /> : <BsChevronDoubleUp />}
                  onClick={() => setExpandAll.toggle()}
                />
              </div>

              <AccordList data={insightData} isExpandAll={isExpandAll} key={String(isExpandAll)} />
            </>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const PieChartDropDown = ({
  onPieChartSelection,
  participantsPieCharts,
  title,
  tooltipSuffix,
}: DemographicProps) => {
  const chartNames = Object.keys(participantsPieCharts);
  const [pieChartKey, setPieChartKey] = useState(chartNames[0] || '');
  const onSelectionChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setPieChartKey(event.target.value);
  }, []);
  return (
    <ChartContainer category="" title={title} downloadPng={true}>
      <>
        <Show ifTrue={chartNames.length > 1}>
          <Select
            size={'xs'}
            width={'200px'}
            onChange={onSelectionChange}
            defaultValue="ageData"
            defaultChecked={true}
          >
            {chartNames.map((chartName) => (
              <option key={chartName} value={chartName}>
                {chartName}
              </option>
            ))}
          </Select>
        </Show>
        {chartNames.map((chartName) => (
          <div key={chartName} style={{ display: pieChartKey == chartName ? 'contents' : 'none' }}>
            <PieChart
              data={participantsPieCharts[chartName]}
              key={chartName}
              onSelection={(values) => {
                onPieChartSelection(values, chartName);
              }}
              tooltipSuffix={tooltipSuffix}
            />
          </div>
        ))}
      </>
    </ChartContainer>
  );
};

const DashboardErrorPage = () => {
  return (
    <MainError message="Could not find requested dashboard">
      <div>
        <p>
          if this issue persists please contact us at{' '}
          <a
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            href="mailto:support@rise-mind.com."
          >
            support@rise-mind.com.
          </a>{' '}
        </p>
      </div>
    </MainError>
  );
};

const ClearFilterBar = ({ show, onClear }: { show: boolean; onClear: () => void }) => {
  const filterClearclassActive =
    'transition-all duration-1000 fixed top-0 z-20 w-full bg-red-300/60 h-20';
  const filterClearclassDiabled =
    'transition-all duration-1000 fixed top-0 z-20 w-full bg-red-300/60 -h-20 hidden';
  return (
    <div className={!show ? filterClearclassDiabled : filterClearclassActive}>
      <div className="absolute right-4 top-4 z-40">
        <Button
          leftIcon={<AiOutlineClear />}
          colorScheme="red"
          variant="solid"
          size={'lg'}
          onClick={() => {
            onClear();
          }}
        >
          Clear All Filters
        </Button>
      </div>
    </div>
  );
};

function subThemeToPieChartData(subThemeList: ThemesData): PieChartData[] {
  const subtheme: BarChartMultiData = subThemeList.data[0];
  const pieData: PieChartData[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.keys(subtheme).forEach((key: string) => {
    if (key === 'name') return;
    pieData.push({
      name: key,
      value: Number(subtheme[key]),
      color: subThemeList.colorKey[key],
    });
  });
  return pieData;
}

const Insights = () => {
  log.trace('render insights start');
  const [selectedThemesIndex, setSelectedThemesIndex] = useState<number[]>([]);
  const { isOpen: isSideBarOpen, onClose: onSideBarClose, onToggle } = useDisclosure();
  const [tableSearchInput, setTableSearchInput] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [chartKey, setChartKey] = useState(1);
  const dashboard = useDashboard();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const fb = firebase();
  const { cid } = useParams();
  const { data, error, isPending } = usePromise(
    () => fb.getDashboardsData(cid || ''),
    (dashboardData) => {
      if (dashboardData) {
        const { data, defaultDashboard } = dashboardData;
        dashboard.updateDashboardGroup(data, defaultDashboard);
      }
    }
  );
  const currentDashData = dashboard.selectedDashboardData;
  const clearFilter = () => {
    onSideBarClose();
    dashboard.setFilter([], true);
    setSelectedThemesIndex([]);
    setChartKey((k) => (k + 1) % 2);
  };
  const onFilterSelection = useCallback(
    (selectedValues: string[], key: FilterType) => {
      log.debug(`onFilterSelection([${selectedValues.join(',')}],${key}) : `);
      const filter: Filter[] = selectedValues.map<Filter>((value) => ({
        key,
        value,
        join: 'or',
      }));
      if (selectedValues.length == 0) {
        dashboard.removeFilter(key);
      } else {
        dashboard.setFilter(filter);
      }
    },
    [dashboard]
  );

  useEffect(() => {
    if (error && error.includes('Permission denied')) {
      navigate(`/login?redirect=/${cid}/dashboard`);
    }
  }, [error, cid, navigate]);

  if (isPending) {
    return <LoadingScreen title="Loading Dashboard" />;
  } else if (!data || currentDashData == null) {
    log.error('data: ' + JSON.stringify(data));
    log.error('currentDashData: ' + JSON.stringify(currentDashData));
    return <DashboardErrorPage />;
  }
  // dashboard.updateData(data);
  const {
    demoTableData,
    participantsPieCharts,
    analysisPieCharts,
    themesData,
    totalCount,
    questionTableData,
    insightData,
    subThemesData,
  } = currentDashData;

  const filterTableData = (tableData: ResponseColumn[], searchTerm: string): ResponseColumn[] => {
    if (!searchTerm || searchTerm === '') return tableData;
    const filteredTableData = tableData.filter((row) => {
      const rowValuesString = JSON.stringify(row).toLowerCase();
      return rowValuesString.includes(searchTerm);
    });
    return filteredTableData;
  };

  //const subThemesData = dashboard.selectedDashboardUnfilteredData?.subThemesData;
  const hasSubThemes = !!dashboard.selectedDashboardUnfilteredData?.subThemesData;
  const hasQuestions = questionTableData.data && questionTableData.data.length > 0;
  const subThemePieChartData =
    subThemesData?.data.length == 1 ? subThemeToPieChartData(subThemesData) : [];
  log.trace('render insights before return');
  return (
    <>
      <FilterSideBar
        onClickOutside={onSideBarClose}
        key={`dash${chartKey}`}
        dashData={currentDashData}
        isOpen={isSideBarOpen}
        onClose={onSideBarClose}
        onSelection={(section, selection) => {
          if (section == 'theme') {
            const selectedIndex = dashboard.selectedDashboardUnfilteredData?.themesData.data
              .map(({ name }, i) => (selection.includes(name) ? i : -1))
              .filter((i) => i >= 0);
            setSelectedThemesIndex(selectedIndex || []);
          }
          onFilterSelection(selection, section);
        }}
      />
      <InsightModal insightData={insightData} isOpen={isOpen} onClose={onClose} />
      <ClearFilterBar show={dashboard.filter.length !== 0} onClear={clearFilter} />
      <Navbar />
      {/* <div className="relative md:ml-64 bg-gray-100"> */}

      <div className="pl-4 relative bg-[#0284c7] pt-20">
        <div className="px-4 md:px-10 mx-auto w-full">
          <Tabs
            variant="soft-rounded"
            colorScheme="blue"
            index={dashboard.selectedDashboardGroupKeyIndex}
          >
            <Show ifTrue={dashboard.dashboardGroupKeys.length > 1}>
              <TabList>
                {dashboard.dashboardGroupKeys.map((key) => (
                  <Tab
                    color={'white'}
                    onClick={() => {
                      onSideBarClose();
                      dashboard.selectedDashboardGroupKey = key;
                      setChartKey((k) => (k + 1) % 2);
                    }}
                    key={key}
                  >
                    {key}
                  </Tab>
                ))}
              </TabList>
            </Show>
            <div className="flex mt-4 -ml-10">
              <>
                <Button
                  size={'sm'}
                  leftIcon={<FaFilter />}
                  className="ml-10 mb-4"
                  onClick={onToggle}
                >
                  Advanced Filter
                </Button>
              </>

              <Show ifTrue={insightData.length > 0}>
                <Button
                  size={'sm'}
                  leftIcon={<FaEye />}
                  className="ml-2"
                  colorScheme="twitter"
                  onClick={onOpen}
                >
                  Insights (Beta)
                </Button>
              </Show>
            </div>
            <TabPanels></TabPanels>
          </Tabs>
        </div>
      </div>

      <HeaderStats totalCount={totalCount} />
      <div className="px-4 md:px-10 mx-auto w-full -m-24" key={chartKey}>
        <div className="flex flex-wrap">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
            <ChartContainer
              category=""
              title={t(transKeys.dashboard.themes)}
              h={360}
              downloadPng={true}
            >
              <BarChartDemo
                selectedBarsIndex={selectedThemesIndex}
                data={themesData.data}
                colorKey={themesData.colorKey}
                onSelection={(selectedValues) => {
                  onFilterSelection(selectedValues, 'theme');
                }}
              />
            </ChartContainer>
          </div>
          {/* <div className="w-full xl:w-4/12 px-4">
            <ChartContainer category="" title="Sentiment">
              <PieChart
                data={sentimentData}
                onSelection={(values) => onFilterSelection(values, 'sentiment')}
              />
            </ChartContainer>
          </div> */}
        </div>
        <div className="flex flex-wrap mt-4">
          {hasSubThemes && (
            <div className="w-full 2xl:w-4/12 mb-12 px-4">
              <Show
                ifTrue={subThemesData?.data?.length === 1}
                elseRender={
                  <ChartContainer category="" title={t(transKeys.dashboard.subthemes)}>
                    <div className="h-full  overflow-auto slim-sb">
                      <AccordBarChartList
                        data={subThemesData?.data || []}
                        isExpandAll={false}
                        colorKey={demoTableData.labelColorKey}
                      />
                    </div>
                  </ChartContainer>
                }
              >
                <ChartContainer
                  category=""
                  title={`Sub-Themes: ${subThemesData?.data[0]?.name}`}
                  downloadPng={true}
                >
                  <PieChart
                    data={subThemePieChartData}
                    onSelection={(selectedValues) => {
                      onFilterSelection(selectedValues, 'subTheme');
                    }}
                    tooltipSuffix="citations"
                  />
                </ChartContainer>
              </Show>
            </div>
          )}
          <div className={'w-full xl:w-6/12 2xl:w-4/12 mb-12 xl:mb-0 px-4'}>
            <PieChartDropDown
              title={t(transKeys.dashboard.ResponseInsights)}
              participantsPieCharts={analysisPieCharts}
              onPieChartSelection={onFilterSelection}
              tooltipSuffix={'citations'}
            />
          </div>
          <div className="w-full xl:w-6/12 2xl:w-4/12 px-4">
            <PieChartDropDown
              title={t(transKeys.dashboard.participantDemographics)}
              participantsPieCharts={participantsPieCharts}
              onPieChartSelection={onFilterSelection}
              tooltipSuffix={'participants'}
            />
          </div>
          {!hasSubThemes && hasQuestions && (
            <div className="w-full hidden 2xl:block 2xl:w-4/12 mb-12 xl:mb-0 px-4">
              <ChartContainer
                category=""
                title={t(transKeys.dashboard.Questions)}
                headerColor="#ffe9e1"
              >
                <TableContainer
                  height={'100%'}
                  overflowX={'clip'}
                  overflowY={'auto'}
                  className="slim-sb"
                >
                  <DataTable
                    height={300}
                    columns={questionTableData.columns}
                    data={questionTableData.data}
                    colorKey={{}}
                    onSelectedRow={(rows) => {
                      onFilterSelection(Object.keys(rows), 'question');
                    }}
                  />
                </TableContainer>
              </ChartContainer>
            </div>
          )}
        </div>
        <div className="flex flex-wrap mt-4">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
            <ChartContainer
              category=""
              title={t(transKeys.dashboard.UserResponses)}
              headerColor="#d5ffde"
              h={400}
            >
              <>
                <InputGroup className="m-2 p-1 h-[14%]">
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon />
                  </InputLeftElement>
                  <Input
                    placeholder="Search Table"
                    size="md"
                    type="search"
                    maxWidth={200}
                    onChange={(e) => {
                      setTableSearchInput(e.target.value);
                    }}
                  />
                </InputGroup>
                <TableContainer
                  height={'86%'}
                  overflowY={'auto'}
                  overflowX={'auto'}
                  className="slim-sb"
                >
                  <DataTable
                    height={340}
                    columns={demoTableData.columns}
                    data={filterTableData(demoTableData.data, tableSearchInput)}
                    colorKey={demoTableData.labelColorKey}
                  />
                </TableContainer>
              </>
            </ChartContainer>
          </div>
        </div>
        <div className="flex flex-wrap">
          <Show ifTrue={hasQuestions}>
            <div
              className={
                hasSubThemes
                  ? 'w-full xl:w-12/12 block px-4'
                  : 'w-full xl:w-12/12 block 2xl:hidden px-4'
              }
            >
              <ChartContainer
                category=""
                title={t(transKeys.dashboard.Questions)}
                headerColor="#ffe9e1"
              >
                <TableContainer
                  height={'100%'}
                  overflowX={'clip'}
                  overflowY={'auto'}
                  className="slim-sb"
                >
                  <DataTable
                    height={300}
                    columns={questionTableData.columns}
                    data={questionTableData.data}
                    colorKey={{}}
                    onSelectedRow={(rows) => {
                      onFilterSelection(Object.keys(rows), 'question');
                    }}
                  />
                </TableContainer>
              </ChartContainer>
            </div>
          </Show>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default observer(Insights);

/* <div className="w-full xl:w-4/12 px-4">
            <div
              style={{
                width: '100%',
                height: '0px',
                position: 'relative',
                paddingBottom: '56.250%',
              }}
            >
              <iframe
                title="vid"
                src="https://streamable.com/e/jca8c0?quality=highest"
                frameborder="0"
                width="100%"
                height="100%"
                allowfullscreen
                style={{ width: '100%', height: '100%', position: 'absolute' }}
              ></iframe>
              <video
                src="https://res.cloudinary.com/ds9c5zpur/video/upload/v1698796898/elbcq9w3kdxblylzsj7u.mp4"
                controls
              >
                <track></track>
              </video>
            </div>
          </div> */
