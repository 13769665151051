import { useEffect, useState } from 'react';
import {
  Input,
  InputGroup,
  InputRightElement,
  Button,
  FormHelperText,
  Textarea,
} from '@chakra-ui/react';
import Show from 'components/Show.component';

type InputComponentProps = {
  placeholder?: string;
  onSubmit: (text: string) => void;
  error?: string;
  submitText?: string;
  initialText?: string;
  multiLine?: boolean;
  disablePaste?: boolean;
};

const InputComponent = ({
  placeholder,
  onSubmit,
  submitText,
  error,
  initialText,
  multiLine,
  disablePaste,
}: InputComponentProps) => {
  const [text, setText] = useState(initialText || '');

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        //event.preventDefault();
        onSubmit(text);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);
  const handlePaste = (
    e: React.FormEvent<HTMLInputElement> | React.ClipboardEvent<HTMLTextAreaElement>
  ) => {
    if (disablePaste) e.preventDefault();
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => setText(event.target.value);

  submitText = submitText || 'submit';
  return (
    <>
      <InputGroup size="md" className="mt-2 mr-2 mb-2">
        <Show ifTrue={!multiLine}>
          <>
            <Input
              pr="4.5rem"
              type="text"
              placeholder={placeholder}
              size="lg"
              onChange={handleChange}
              value={text}
              onPaste={handlePaste}
            />
            <InputRightElement width="fit-content">
              <Button
                className="mt-2 mr-1"
                size="md"
                onClick={() => {
                  onSubmit(text);
                }}
                colorScheme="blue"
              >
                {submitText}
              </Button>
            </InputRightElement>
          </>
        </Show>
        <Show ifTrue={!!multiLine}>
          <div className="flex flex-col w-full">
            <Textarea
              placeholder={placeholder}
              onChange={handleChange}
              value={text}
              onPaste={handlePaste}
            />
            <Button
              className="mt-2 w-fit"
              size="md"
              onClick={() => {
                onSubmit(text);
              }}
              colorScheme="blue"
            >
              {submitText}
            </Button>
          </div>
        </Show>
      </InputGroup>

      <Show ifTrue={!!error}>
        <FormHelperText color="red.500">{error}</FormHelperText>
      </Show>
    </>
  );
};

export default InputComponent;
