/* eslint-disable @typescript-eslint/ban-ts-comment */
//import { createColumnHelper } from '@tanstack/react-table';
import {
  Filter,
  RawData,
  FilterType,
  Participant,
  Response,
  TotalCount,
  PieChartData,
  DashBoardDTO,
  ThemesData,
  QuestionColumn,
  BarChartData,
  Question,
  ResponseColumn,
  PieSegment,
  BarChartMultiData,
  Analysis,
} from 'types/DataSet.type';
import { deepCopy, getColor, getUnique, groupBy, jstr } from 'utils/general.utils';
import { questionColumns } from 'components/Table/Question.conf';
import { GenderColorKey, SentimentColorKey } from 'utils/consts/Color.const';
import { responseColumns } from 'components/Table/Response.conf';
import logger from 'services/Logger/Pino';

const log = logger.child({ context: 'dataProvider' });

type SegmentKeys = 'theme' | 'sentiment';

//const columnHelper = createColumnHelper<any>();
//const resId = ({ pid, qid }: Response) => `${pid}#${qid}`;

function filterByQuestion(rawData: RawData, filter: Filter) {
  const filteredResponses: Response[] = rawData.responses.filter(
    ({ qid }) => String(qid) == String(filter.value)
  );

  const filteredData: RawData = {
    responses: filteredResponses.map((res) => Object.assign({}, res)),
    participants: Object.assign({}, rawData.participants),
    questions: rawData.questions,
    themes: Object.assign({}, rawData.themes),
    insights: [...(rawData.insights || [])],
    pieKeys: rawData.pieKeys,
    pieSegments: rawData.pieSegments,
  };
  return filteredData;
}

function filterBySegment(rawData: RawData, filter: Filter) {
  log.trace('filterBySegment start');
  const nFilter = Object.assign({}, filter);
  if (nFilter.key == 'theme') {
    const selTheme = Object.entries(rawData.themes).find(([, value]) => value == nFilter.value);
    if (selTheme) nFilter.value = selTheme[0];
    else return rawData;
  }

  const { participants } = rawData;
  const filteredResponses: Response[] = [];
  rawData.responses.forEach((res) => {
    const filteredAnalysis = res.analysis.filter(
      (aLine) => aLine[nFilter.key as SegmentKeys] == nFilter.value
    );
    res.analysis = [];
    if (filteredAnalysis.length > 0)
      filteredResponses.push(Object.assign({}, res, { analysis: filteredAnalysis }));
  });
  const filteredPartKeys = [...new Set(filteredResponses.map(({ pid }) => pid))];
  const filteredParticipants: Record<number, Participant> = {};
  filteredPartKeys.forEach(
    (pid) => (filteredParticipants[pid] = Object.assign({}, participants[pid]))
  );

  const filteredData: RawData = {
    responses: filteredResponses,
    participants: filteredParticipants,
    questions: rawData.questions,
    themes: Object.assign({}, rawData.themes),
    insights: [...(rawData.insights || [])],
    pieKeys: rawData.pieKeys,
    pieSegments: rawData.pieSegments,
  };
  //log.debug('filterBySegment :: ' + JSON.stringify(filteredData.responses, null, 2));
  log.trace('filterBySegment end');
  return filteredData;
}

function filterByParticipant(rawData: RawData, filter: Filter) {
  const { participants } = rawData;
  const { key, value } = filter;

  const filteredPartKeys = Object.keys(participants)
    .map(Number)
    .filter((pid) => {
      const curPart: Participant = participants[pid];
      //@ts-ignore
      let curVal = String(curPart[key]).toLowerCase();
      if (rawData.pieSegments?.[key]) {
        const segments = rawData.pieSegments;
        curVal = pieChartMapper(segments[key], curVal);
      }
      return curVal == value.toLocaleLowerCase();
    });

  const filteredResponses: Response[] = rawData.responses.filter(({ pid }) =>
    filteredPartKeys.includes(pid)
  );
  // const questionsIds = [...new Set(filteredResponses.map(({ qid }) => qid))];
  // const filteredQuestionsKeys = Object.keys(rawData.questions).filter((qid) =>
  //   questionsIds.includes(Number(qid))
  // );
  const filteredParticipants: Record<number, Participant> = {};
  filteredPartKeys.forEach(
    (pid) => (filteredParticipants[pid] = Object.assign({}, participants[pid]))
  );
  // const filterdQuestions: Record<number, Question> = {};
  // filteredQuestionsKeys.forEach(
  //   (qid) => (filterdQuestions[Number(qid)] = Object.assign({}, rawData.questions[Number(qid)]))
  // );

  const filteredData: RawData = {
    responses: filteredResponses,
    participants: filteredParticipants,
    questions: rawData.questions,
    themes: Object.assign({}, rawData.themes),
    insights: [...(rawData.insights || [])],
    pieKeys: rawData.pieKeys,
    pieSegments: rawData.pieSegments,
  };

  return filteredData;
}

function getAllParticipantKeys(data: RawData) {
  const CUSTOM_IGNORE_KEYS = Object.entries(data.pieKeys?.participants || {})
    .filter(([, val]) => !val)
    .map(([key]) => key);
  const DEFAULT_IGNORE_KEYS: string[] = ['pid', 'name'];
  const IGNORE_KEYS = [...DEFAULT_IGNORE_KEYS, ...CUSTOM_IGNORE_KEYS];
  const allKeys = Object.values(data.participants).map((p) => Object.keys(p));
  const uniqueKeys = [...new Set(allKeys.flat())].filter((key) => !IGNORE_KEYS.includes(key));
  return uniqueKeys;
}

function getParticipantDataCharts(data: RawData) {
  const uniqueKeys = getAllParticipantKeys(data);

  const allParticipantsData: Record<string, PieChartData[]> = {};
  uniqueKeys.forEach((key) => {
    allParticipantsData[key] = getParticipantData(data, key);
  });
  return allParticipantsData;
}

function pieChartMapper(segments: PieSegment[], value: string) {
  const isNumberRange = segments.every(
    (seg) => seg.values?.length == 2 && typeof seg.values[0] == 'number'
  );
  const segment = segments.find((seg) => {
    if (isNumberRange) {
      const [start, end] = seg.values as [number, number];
      if (Number(value) >= start && Number(value) <= end) return true;
    } else {
      return seg.values.map(String).includes(value);
    }
  });

  return segment?.label || value;
}

function getParticipantData(data: RawData, key: string): PieChartData[] {
  let allKeyItems = Object.values(data.participants)
    .map((p) => p[key])
    .filter(Boolean);

  if (data.pieSegments?.[key]) {
    const segments = data.pieSegments;
    allKeyItems = allKeyItems.map((item) => pieChartMapper(segments[key], String(item || 'N/A')));
  }
  const pieChartData: PieChartData[] = [];
  allKeyItems.forEach((item) => {
    const foundLoc = pieChartData.find(({ name }) => name == item);
    if (foundLoc) {
      foundLoc.value = foundLoc.value + 1;
    } else {
      pieChartData.push({
        name: String(item),
        value: 1,
        //TODO move to config
        color:
          key === 'gender'
            ? GenderColorKey[String(item).toLowerCase()]
            : getColor(String(item), 'light', JSON.stringify(data.themes)),
      });
    }
  });
  return pieChartData;
}

function getSentimentData(rawData: RawData): PieChartData[] {
  const responseSentiment = rawData.responses.map((response) => {
    return response.analysis.map((analysis) => analysis.sentiment);
  });
  const flatResSentiment = responseSentiment.flat();
  const res: PieChartData[] = [];
  flatResSentiment.forEach((s) => {
    const item = res.find((item) => item.name == s);
    if (!item) res.push({ name: s, value: 1, color: SentimentColorKey[s.toLowerCase()] });
    else item.value = item.value + 1;
  });

  return res;
}

function getAnalysisKeys(rawData: RawData) {
  const CUSTOM_IGNORE_KEYS = Object.entries(rawData.pieKeys?.analysis || {})
    .filter(([, val]) => !val)
    .map(([key]) => key);
  const DEFAULT_IGNORE_KEYS: string[] = ['id', 'theme', 'reference', 'subTheme'];
  const IGNORE_KEYS = [...DEFAULT_IGNORE_KEYS, ...CUSTOM_IGNORE_KEYS];
  const allResponseAnalysis = rawData.responses.map((response) => {
    return response.analysis;
  });
  const allAnalysisKeys = getUnique(
    allResponseAnalysis
      .map((analysisList) => {
        return analysisList.map((a) => Object.keys(a)).flat();
      })
      .flat()
  ).filter((key) => !IGNORE_KEYS.includes(key));
  return allAnalysisKeys;
}

function getAnalysisPieData(rawData: RawData): Record<string, PieChartData[]> {
  const allResponseAnalysis = rawData.responses.map((response) => {
    return response.analysis;
  });

  const allAnalysisKeys = getAnalysisKeys(rawData);

  const res: Record<string, PieChartData[]> = {};

  allAnalysisKeys.forEach((key) => {
    res[key] = [];
    allResponseAnalysis.forEach((analysisList) => {
      const recordedItems = new Set();
      analysisList.forEach((analysis) => {
        const aValue = analysis[key];
        if (recordedItems.has(aValue)) return;
        recordedItems.add(aValue);
        let item = res[key].find(({ name }) => name === String(aValue));
        if (!item)
          res[key].push(
            (item = {
              name: String(aValue),
              value: 0,
              color:
                key === 'sentiment'
                  ? SentimentColorKey[String(aValue)]
                  : getColor(String(aValue), 'light', JSON.stringify(rawData.themes)),
            })
          );
        item.value = item.value + 1;
      });
    });
  });
  return res;
}

function getQuestionTableData(rawData: RawData) {
  // const followUpCount = Object.values(rawData.responses).map(
  //   ({ response }) => response.filter(({ followup }) => Boolean(followup)).length
  // );
  if (!rawData.questions) return { data: [], columns: [] };
  const questionData = Object.entries(rawData.questions).map<QuestionColumn>(
    ([qNum, { question }]) => ({
      qNum: String(qNum),
      question,
      followups: Math.trunc(Math.random() * 10), //followUpCount.reduce((prev, num) => num + prev, 0),
    })
  );

  const columns = questionColumns;
  return { data: questionData, columns };
}

//TODO:M add generic meta data
function getResponseTableData(rawData: RawData) {
  const pKeys = getAllParticipantKeys(rawData);
  const answersData: ResponseColumn[] = rawData.responses.map<ResponseColumn>(
    ({ analysis, qid, pid, response }): ResponseColumn => {
      const meta: Record<string, string | number | undefined> = {};
      pKeys.forEach((key) => {
        meta[key] = rawData.participants[pid][key];
      });
      const links = analysis
        .map(({ reference }) => (typeof reference !== 'string' ? reference.links : undefined))
        .flat();
      return {
        response: response,
        pid: String(pid),
        name: rawData.participants[pid].name,
        ...meta,
        question: String(qid),
        links: links.filter(Boolean) as string[],
        themes: [
          ...new Set(
            analysis.map(({ theme, sentiment, subTheme }) => ({
              theme: rawData.themes[theme],
              subTheme,
              sentiment,
              color: SentimentColorKey[sentiment],
            }))
          ),
        ],
      };
    }
  );
  const hideQuestions =
    !rawData.questions ||
    Object.keys(rawData.questions).length == 0 ||
    rawData.responses.some(({ qid }) => qid === undefined);
  const hasLinks = answersData.some(({ links }) => links?.length > 0);
  return { answersData, columns: responseColumns(pKeys, hideQuestions, hasLinks) };
}

function getSubThemesData(
  data: RawData,
  originalData: RawData
): { themesData: ThemesData; hasSubThemes: boolean } {
  const subThemeColorMap: Record<string, string> = {};
  const themesData: BarChartMultiData[] = [];
  const totalAnalysis = data.responses.map(({ analysis }) => analysis).flat();
  const totalOriginalAnalysis = originalData.responses.map(({ analysis }) => analysis).flat();
  const hasSubThemes = totalOriginalAnalysis.some(({ subTheme }) => Boolean(subTheme));

  totalAnalysis.forEach(({ theme, subTheme }) => {
    const themeName = data.themes[theme];
    const subThemeName = subTheme || themeName;
    if (subThemeName && !subThemeColorMap[subThemeName]) {
      subThemeColorMap[subThemeName] = getColor(subThemeName, 'dark', 'subTheme');
    }

    const tData = themesData.find(({ name }) => name === themeName);

    if (!tData) themesData.push({ name: themeName, [subThemeName]: 1 });
    else {
      tData[subThemeName] = (Number(tData[subThemeName]) || 0) + 1;
    }
  });

  return { themesData: { data: themesData, colorKey: subThemeColorMap }, hasSubThemes };
}

function getThemesData(data: RawData) {
  const themesData: BarChartData[] = [];
  const totalAnalysis = data.responses.map(({ analysis }) => analysis).flat();
  totalAnalysis.forEach(({ sentiment, theme }) => {
    const themeName = data.themes[theme];
    const tData = themesData.find(({ name }) => name === themeName);
    if (!tData) themesData.push({ name: themeName, [sentiment]: 1 });
    else {
      tData[sentiment] = (tData[sentiment] || 0) + 1;
    }
  });
  return themesData;
  // {
  //   name: 'Battery Life',
  //   positive: 10,
  //   Neutral: 4,
  //   Negative: 2,
  // },
}

function getTotalsData(raw: RawData): TotalCount {
  const totalParticipants = Object.keys(raw.participants).length;
  const totalQuestion = raw.questions ? Object.keys(raw.questions).length : 0;
  const themeCount = Object.keys(raw.themes).length;
  const insightCount = Object.keys(raw.insights || []).length;
  return { totalParticipants, totalQuestion, themeCount, insightCount };
}

function addResponseData(responses: Response[], unionData: RawData) {
  //const resId = ({ pid, qid }: Response) => `${pid}#${qid}`;
  responses.forEach((res) => {
    const doesExistInUnion = unionData.responses.some((uRes) => {
      res.id === uRes.id; //JSON.stringify(res) === JSON.stringify(uRes);
    });
    if (!doesExistInUnion) {
      unionData.responses.push(res);
    }
  });
}

function intersectAnalysisData(analysis1: Analysis[], analysis2: Analysis[]) {
  //function intersectionById(array1: ObjectWithId[], array2: ObjectWithId[]): ObjectWithId[] {
  const set2Ids = new Set(analysis2.map((item) => item.id));
  return analysis1.filter((item) => set2Ids.has(item.id));
  //}
  //return intersectionById(analysis1, analysis2);
}

function intersectResponseData(responses1: Response[], responses2: Response[], unionData: RawData) {
  responses1.forEach((res1) => {
    const doBothHaveRes = responses2.some((res2) => res1.id === res2.id); //responses2.some((res2) => JSON.stringify(res1) == JSON.stringify(res2));

    if (doBothHaveRes) {
      const r2Analysis = responses2.find((res2) => res1.id === res2.id)?.analysis;
      if (r2Analysis) {
        res1.analysis = intersectAnalysisData(res1.analysis, r2Analysis);
        unionData.responses.push(res1);
      }
    }
  });
}

function addObjectData(
  section: Record<number, Participant | Question>,
  unionData: RawData,
  key: 'participants' | 'questions'
) {
  Object.entries(section).forEach(([id, val]) => {
    //@ts-ignore
    if (!unionData[key][Number(id)]) {
      //@ts-ignore
      unionData[key][Number(id)] = val;
    }
  });
}

function intersectObjectData(
  section1: Record<number, Participant | Question>,
  section2: Record<number, Participant | Question>,
  unionData: RawData,
  key: 'participants' | 'questions'
) {
  Object.entries(section1).forEach(([id, val]) => {
    if (section2[Number(id)]) {
      //@ts-ignore
      unionData[key][Number(id)] = Object.assign({}, val);
    }
  });
}

function emptyRawData(rawData: RawData): RawData {
  return {
    responses: [],
    participants: {},
    questions: {},
    insights: [...(rawData.insights || [])],
    themes: Object.assign({}, rawData.themes),
    pieKeys: rawData.pieKeys,
    pieSegments: rawData.pieSegments,
  };
}

function union(data1: RawData, data2: RawData): RawData {
  const unionData: RawData = emptyRawData(data1);
  addResponseData(data1.responses, unionData);
  addResponseData(data2.responses, unionData);
  addObjectData(data1.participants, unionData, 'participants');
  addObjectData(data2.participants, unionData, 'participants');

  if (data1.questions && data2.questions) {
    addObjectData(data1.questions, unionData, 'questions');
    addObjectData(data2.questions, unionData, 'questions');
  }
  return unionData;
}

function intersect(data1: RawData, data2: RawData) {
  const unionData: RawData = emptyRawData(data1);
  intersectResponseData(data1.responses, data2.responses, unionData);
  const participantList = unionData.responses
    .map(({ pid }) => data1.participants[pid])
    .filter(Boolean);
  unionData.participants = {};
  participantList.forEach((participant) => {
    unionData.participants[participant.pid] = participant;
  });
  //intersectObjectData(data1.participants, data2.participants, unionData, 'participants');
  if (data1.questions && data2.questions)
    intersectObjectData(data1.questions, data2.questions, unionData, 'questions');
  return unionData;
}

function rawToStructured(rawData: RawData, filters: Filter[] = []): DashBoardDTO {
  const participantKeys: FilterType[] = getAllParticipantKeys(rawData);
  const responseKeys: FilterType[] = [...getAnalysisKeys(rawData), 'theme', 'subTheme'];
  //const rawCopy: RawData = deepCopy(rawData);

  let filteredData: RawData = deepCopy(rawData);
  let finalFilteredData: RawData = deepCopy(rawData);
  const filterGroups = Object.values(groupBy(filters, 'key'));
  log.debug('filterGroups ' + jstr(filterGroups));
  log.debug('Filtering ' + JSON.stringify(filters, null, 2));
  filterGroups.forEach((group) => {
    let currentData: RawData = emptyRawData(rawData);
    log.debug('Group KEY: ' + group?.[0]?.key);
    group.forEach((filter) => {
      log.debug('Filtering :: ' + filter.key + ' : ' + filter.value + ' : [' + filter.join + ']');
      if (participantKeys.includes(filter.key)) {
        log.debug(`Filtering by participant  ${filter.key}:${filter.value}`);
        filteredData = filterByParticipant(deepCopy(rawData), filter);
      }
      if (responseKeys.includes(filter.key)) {
        log.debug(`Filtering by segment ${filter.key}:${filter.value}`);
        filteredData = filterBySegment(deepCopy(rawData), filter);
      }
      if (filter.key == 'question') {
        log.debug(`Filtering by question ${filter.key}:${filter.value}`);
        filteredData = filterByQuestion(deepCopy(rawData), filter);
      }
      log.trace('union: ' + filter.key);
      currentData = union(currentData, filteredData);
    });
    log.debug('intersect for group: ' + jstr(group));
    finalFilteredData = intersect(currentData, finalFilteredData);
  });

  log.trace('getParticipantDataCharts');
  //Participants PieCharts
  const participantsPieCharts = getParticipantDataCharts(finalFilteredData);

  log.trace('getSentimentData');
  // Sentiment PieChart
  const sentimentData = getSentimentData(finalFilteredData);

  log.trace('getAnalysisPieData');
  //analysis Pie Charts
  const analysisPieCharts = getAnalysisPieData(finalFilteredData);

  log.trace('getResponseTableData');
  // Response Table
  const { answersData, columns } = getResponseTableData(finalFilteredData);
  const labelColorKey: Record<string, string> = {};
  Object.values(rawData.themes).forEach((theme: string) => {
    labelColorKey[theme.toUpperCase()] = getColor(
      theme,
      'light',
      JSON.stringify(rawData.themes) + 'themes'
    );
  });
  const demoTableData = { data: answersData, columns, labelColorKey };

  log.trace('getQuestionTableData');
  // Question Table
  const questionTableData = getQuestionTableData(rawData);

  log.trace('getThemesData');
  // Bar Chart
  const themesData: ThemesData = {
    data: getThemesData(finalFilteredData),
    colorKey: SentimentColorKey,
  };

  log.trace('getSubThemesData');
  const subThemesData = getSubThemesData(finalFilteredData, rawData);

  //Insight data
  const insightData =
    rawData.insights?.map(({ title, description }) => ({
      title,
      points: description,
    })) || [];

  return {
    demoTableData,
    questionTableData,
    sentimentData,
    participantsPieCharts,
    themesData,
    subThemesData: subThemesData.hasSubThemes ? subThemesData.themesData : undefined,
    analysisPieCharts,
    totalCount: getTotalsData(finalFilteredData),
    insightData,
  };
}

export { rawToStructured };
