/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import pino, { Logger } from 'pino';

type LogData = {
  context?: string;
  [key: string]: unknown;
};
const DEFAULT_PROD_LOG_LEVEL = 'error';
const DEFAULT_DEV_LOG_LEVEL = 'trace';
const IS_DEVELOPMENT = import.meta.env.DEV;
const LOG_LEVEL_OVVERIDE = localStorage.getItem('LOG_LEVEL');

function formatter(data: Record<string, any>, hideMeta = false) {
  const LOG_MAP: Record<string, string> = {
    '10': 'trace',
    '20': 'debug',
    '30': 'info',
    '40': 'warn',
    '50': 'error',
    '60': 'fatal',
  };
  const preDefKeys = ['time', 'context', 'level', 'msg'];
  const context = data['context'] || 'Gen';
  const level = data['level'];
  const time = new Date(data['time']);
  const msg = data['msg'];
  let output = hideMeta
    ? `[${context}]`
    : `[${time.toLocaleTimeString()}][${LOG_MAP[String(level)]}][${context}]`;
  Object.keys(data).forEach((key) => {
    if (!preDefKeys.includes(key)) output += output + `[${key}=${data[key]}]`;
  });
  output += `: ${msg}`;
  return output;
}

class LoggerWrapper {
  public logger: Logger;

  constructor(
    private context: string[],
    private formatter?: (data: object, options?: any) => unknown,
    private showMeta = true
  ) {
    this.logger = this.initLogger();
  }

  write = (data: object) => {
    let formatted: object | unknown = data;
    if (this.formatter) formatted = this.formatter(data, !this.showMeta);
    const msgContext = (data as LogData)?.context;
    if (msgContext && this.context.length > 0) {
      if (this.context.includes(msgContext)) {
        console.log(formatted);
      }
    } else {
      console.log(formatted);
    }
  };

  initLogger() {
    let level = IS_DEVELOPMENT ? DEFAULT_DEV_LOG_LEVEL : DEFAULT_PROD_LOG_LEVEL;
    if (LOG_LEVEL_OVVERIDE) {
      level = LOG_LEVEL_OVVERIDE;
    }

    return pino({
      browser: {
        asObject: true,
        write: this.write,
      },
      level,
    });
  }
}

const logWrapper = new LoggerWrapper([], formatter, true);
const logger = logWrapper.logger;

export default logger;
