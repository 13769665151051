import { useState } from 'react';
import { Component, OnComplete, Validation } from 'types/ClientForm';
import InputComponent from 'components/widgets/Input.component';
import { buildYup } from 'schema-to-yup';
import NumberComponent from 'components/widgets/NumberInput.component';
import ButtonSelection from 'components/widgets/ButtonSelection.component';
import VideoInput from 'components/widgets/VideoInput.component';
import ChatInput from 'components/widgets/ChatInput.component';
import { CHAT_DEFAULT_QUESTION_LIMIT, CONTROL_DEFAULT_SUBMIT_TEXT } from 'utils/consts/Site.consts';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MessageComponent from 'components/widgets/Message.component';
import SurveySingleComposition, {
  ChatComposition,
} from 'components/SurveySingleComposition/SurveySingleComposition.component';

type Props = {
  component: Component;
  onSubmit: OnComplete;
  title?: string;
};

type ValidationResult = {
  isValid: boolean;
  errors?: string[];
};

const validateComponent = async (
  validation: Validation | undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: Record<string, string | number>
): Promise<ValidationResult> => {
  let res: ValidationResult = { isValid: true };

  if (validation?.yup) {
    const { schema, errorMessage } = JSON.parse(JSON.stringify(validation.yup));
    const yupSchema = buildYup(schema, errorMessage);
    try {
      const valid = await yupSchema.validate(data);
      res.isValid = valid;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      res = { isValid: false, errors: e?.errors || ['invalid format'] };
    }
  }
  return res;
};

const WidgetSelector = ({ component, onSubmit, title }: Props) => {
  const { type, settings, id } = component;
  const [error, setError] = useState('');
  const [initialText, setInitialText] = useState('');
  const { cid, instanceId } = useParams();
  const submitText = settings.submitText || CONTROL_DEFAULT_SUBMIT_TEXT;

  let Component: () => JSX.Element = () => <div>Unavailable</div>;
  if (type == 'message') {
    Component = () => (
      <SurveySingleComposition label={component.label} title={title} isInvalid={!!error}>
        <MessageComponent
          submitText={submitText}
          onSubmit={() => {
            onSubmit({});
          }}
        />
      </SurveySingleComposition>
    );
  }
  if (type == 'input')
    Component = () => {
      return (
        <SurveySingleComposition label={component.label} title={title} isInvalid={!!error}>
          <InputComponent
            placeholder={settings?.placeholder}
            onSubmit={(text) => {
              validateComponent(settings?.validation, { [id]: text }).then(
                ({ isValid, errors }) => {
                  if (!isValid) {
                    setError(errors?.[0] || 'Invalid format');
                    setInitialText(text);
                  } else {
                    onSubmit({ [id]: text });
                  }
                }
              );
            }}
            submitText={submitText}
            error={error}
            initialText={initialText}
            multiLine={settings.multiLine}
            disablePaste={settings.disablePaste}
          />
        </SurveySingleComposition>
      );
    };
  if (type == 'number') {
    Component = () => (
      <SurveySingleComposition label={component.label} title={title} isInvalid={!!error}>
        <NumberComponent
          min={settings.min}
          max={settings.max}
          start={settings.start}
          onSubmit={(number) => {
            onSubmit({ [id]: number });
          }}
          submitText={submitText}
        />
      </SurveySingleComposition>
    );
  }
  if (type == 'buttonSelection') {
    Component = () => (
      <SurveySingleComposition label={component.label} title={title} isInvalid={!!error}>
        <ButtonSelection
          options={settings.options}
          defaultValue={settings.defaultValue}
          submitOnSelect={settings.submitOnSelect}
          onSubmit={(option) => {
            onSubmit({ [id]: option });
          }}
        />
      </SurveySingleComposition>
    );
  }
  if (type == 'video') {
    Component = () => (
      <ChatComposition label={component.label} title={title}>
        <VideoInput
          onSubmit={(videoData) => {
            onSubmit({ [id]: videoData });
          }}
          submitText={submitText}
        />
      </ChatComposition>
    );
  }
  if (type == 'chat') {
    Component = () => (
      <ChatComposition label={component.label} title={title}>
        <ChatInput
          closingMessage={settings.closingMessage}
          maxQuestions={settings.maxFollowups || CHAT_DEFAULT_QUESTION_LIMIT}
          questions={settings.questions}
          submitText={submitText}
          formId={cid || ''}
          instanceId={instanceId || ''}
          onSubmit={(messages) => {
            onSubmit({ [id]: messages });
          }}
        />
      </ChatComposition>
    );
  }

  return <Component />;
};

export default observer(WidgetSelector);
