import { RawData } from 'types/DataSet.type';

const sampleData: RawData = {
  responses: [
    {
      qid: 0,
      pid: 0,
      response: [
        {
          q: 0,
          a: "I'm absolutely in love with the new smartphone. It's a game-changer!",
        },
      ],
      analysis: [
        {
          id: 'q0p0a1',
          theme: 0,
          subTheme: 'Size and Portability',
          sentiment: 'positive',
          reference: {
            resNum: 1,
            links: ['https://en.wikipedia.org/wiki/Reference'],
            range: 'all',
          },
        },
        {
          id: 'q0p0a2',
          theme: 0,
          subTheme: 'Color Options',
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
        {
          id: 'q0p0a3',
          theme: 1,
          subTheme: 'Pricing Tiers',
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 0,
      pid: 1,
      response: [
        {
          q: 0,
          a: "The phone's sleek design is a sight to behold; it's an instant eye-catcher.",
        },
      ],
      analysis: [
        {
          id: 'q0p1a1',
          theme: 1,
          subTheme: 'Pricing Tiers',
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 0,
      pid: 2,
      response: [
        {
          q: 0,
          a: "I'm underwhelmed by the phone's appearance, it feels like another generic design.",
        },
      ],
      analysis: [
        {
          id: 'q0p2a1',
          theme: 1,
          subTheme: 'Brand Perception',
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 0,
      pid: 3,
      response: [
        {
          q: 0,
          a: "My initial impression is pretty neutral; it's just another smartphone.",
        },
      ],
      analysis: [
        {
          id: 'q0p3a1',
          theme: 0,
          subTheme: 'Material Quality',
          sentiment: 'neutral',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 0,
      pid: 4,
      response: [
        {
          q: 0,
          a: 'Setting up the phone was a bit complicated for a first-time user like me.',
        },
      ],
      analysis: [
        {
          id: 'q0p4a1',
          theme: 1,
          subTheme: 'Brand Perception',
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 0,
      pid: 5,
      response: [
        {
          q: 0,
          a: "The camera's low-light performance is a letdown, but the rest is impressive.",
        },
      ],
      analysis: [
        {
          id: 'q0p5a1',
          theme: 2,
          subTheme: 'Random t2s1',
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 0,
      pid: 6,
      response: [
        {
          q: 0,
          a: "The phone looks stylish, but I'm still getting used to it.",
        },
      ],
      analysis: [
        {
          id: 'q0p6a1',
          theme: 1,
          subTheme: 'Brand Perception',
          sentiment: 'neutral',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 0,
      pid: 7,
      response: [
        {
          q: 0,
          a: "I feel neutral about the phone's design; it's neither impressive nor disappointing.",
        },
      ],
      analysis: [
        {
          id: 'q0p7a1',
          theme: 1,
          subTheme: 'Brand Perception',
          sentiment: 'neutral',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 0,
      pid: 8,
      response: [
        {
          q: 0,
          a: 'Setting up the phone was hassle-free, which is a relief.',
        },
      ],
      analysis: [
        {
          id: 'q0p8a1',
          theme: 1,
          subTheme: 'Brand Perception',
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 0,
      pid: 9,
      response: [
        {
          q: 0,
          a: 'The camera is good, but not exceptional; it meets my basic photography needs.',
        },
      ],
      analysis: [
        {
          id: 'q0p9a1',
          theme: 2,
          subTheme: 'Random t2s2',
          sentiment: 'neutral',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 1,
      pid: 0,
      response: [
        {
          q: 1,
          a: "My daily tasks have become a breeze with this phone; it's a time-saver.",
        },
      ],
      analysis: [
        {
          id: 'q1p0a1',
          theme: 1,
          subTheme: 'After-sales Services',
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 1,
      pid: 1,
      response: [
        {
          q: 1,
          a: "The faster performance is a game-changer; I'm more efficient than ever.",
        },
      ],
      analysis: [
        {
          id: 'q1p1a1',
          theme: 3,
          subTheme: 'Random t3s1',
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 1,
      pid: 2,
      response: [
        {
          q: 1,
          a: 'The facial recognition is a hit-or-miss, often failing to recognize me quickly.',
        },
      ],
      analysis: [
        {
          id: 'q1p2a1',
          theme: 1,
          subTheme: 'After-sales Services',
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 1,
      pid: 3,
      response: [
        {
          q: 1,
          a: 'The battery life is good, but I expected it to last even longer.',
        },
      ],
      analysis: [
        {
          id: 'q1p3a1',
          theme: 4,
          subTheme: 'Random t5s1',
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 1,
      pid: 4,
      response: [
        {
          q: 1,
          a: 'The GPS accuracy is exceptional, making travel a breeze.',
        },
      ],
      analysis: [
        {
          id: 'q1p4a1',
          theme: 5,
          subTheme: 'Random t5s1',
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 1,
      pid: 5,
      response: [
        {
          q: 1,
          a: "The phone has turbocharged my productivity, and I can't imagine life without it.",
        },
      ],
      analysis: [
        {
          id: 'q1p5a1',
          theme: 3,
          subTheme: 'Random t3s1',
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 1,
      pid: 6,
      response: [
        {
          q: 1,
          a: "The phone's faster performance is a breath of fresh air in my busy schedule.",
        },
      ],
      analysis: [
        {
          id: 'q1p6a1',
          theme: 3,
          subTheme: 'Random t3s2',
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 1,
      pid: 7,
      response: [
        {
          q: 1,
          a: 'The facial recognition is convenient, but sometimes it fails to recognize me.',
        },
      ],
      analysis: [
        {
          id: 'q1p7a1',
          theme: 1,
          subTheme: 'After-sales Services',
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 1,
      pid: 8,
      response: [
        {
          q: 1,
          a: 'Battery life is excellent, but it could be even better for heavy users like me.',
        },
      ],
      analysis: [
        {
          id: 'q1p8a1',
          theme: 4,
          subTheme: 'Random t4s1',
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 1,
      pid: 9,
      response: [
        {
          q: 1,
          a: 'The GPS is a bit wonky; it occasionally leads me in the wrong direction.',
        },
      ],
      analysis: [
        {
          id: 'q1p9a1',
          theme: 5,
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 2,
      pid: 0,
      response: [
        {
          q: 2,
          a: "The phone's camera is the star of the show, capturing amazing shots every time.",
        },
      ],
      analysis: [
        {
          id: 'q2p0a1',
          theme: 2,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 2,
      pid: 1,
      response: [
        {
          q: 2,
          a: '5G connectivity is revolutionary, making streaming and downloads lightning-fast.',
        },
      ],
      analysis: [
        {
          id: 'q2p1a1',
          theme: 5,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 2,
      pid: 2,
      response: [
        {
          q: 2,
          a: "The voice assistant is handy, but I don't use it as often as I thought I would.",
        },
      ],
      analysis: [
        {
          id: 'q2p2a1',
          theme: 1,
          sentiment: 'neutral',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 2,
      pid: 3,
      response: [
        {
          q: 2,
          a: 'The expandable storage is a must-have; I never worry about running out of space.',
        },
      ],
      analysis: [
        {
          id: 'q2p3a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 2,
      pid: 4,
      response: [
        {
          q: 2,
          a: "Customizing the phone's interface is fun, but it can be overwhelming for some.",
        },
      ],
      analysis: [
        {
          id: 'q2p4a1',
          theme: 1,
          sentiment: 'neutral',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 2,
      pid: 5,
      response: [
        {
          q: 2,
          a: 'The camera is an absolute gem, capturing stunning photos and videos.',
        },
      ],
      analysis: [
        {
          id: 'q2p5a1',
          theme: 2,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 2,
      pid: 6,
      response: [
        {
          q: 2,
          a: 'The 5G connectivity has changed the way I stream and download content.',
        },
      ],
      analysis: [
        {
          id: 'q2p6a1',
          theme: 5,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 2,
      pid: 7,
      response: [
        {
          q: 2,
          a: 'I find the voice assistant quite useful for quick tasks and inquiries.',
        },
      ],
      analysis: [
        {
          id: 'q2p7a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 2,
      pid: 8,
      response: [
        {
          q: 2,
          a: 'The expandable storage is a lifesaver for someone who loves hoarding photos.',
        },
      ],
      analysis: [
        {
          id: 'q2p8a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 2,
      pid: 9,
      response: [
        {
          q: 2,
          a: "Customizing the phone's interface to match my style is a fun experience.",
        },
      ],
      analysis: [
        {
          id: 'q2p9a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 3,
      pid: 0,
      response: [
        {
          q: 3,
          a: "The phone's performance justifies the price, but it's not affordable for everyone.",
        },
      ],
      analysis: [
        {
          id: 'q3p0a1',
          theme: 0,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 3,
      pid: 1,
      response: [
        {
          q: 3,
          a: 'Fast charging would be a game-changer; waiting for a full charge can be frustrating.',
        },
      ],
      analysis: [
        {
          id: 'q3p1a1',
          theme: 4,
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 3,
      pid: 2,
      response: [
        {
          q: 3,
          a: "The phone's weight is substantial, making it feel solid, but less portable.",
        },
      ],
      analysis: [
        {
          id: 'q3p2a1',
          theme: 1,
          sentiment: 'neutral',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 3,
      pid: 3,
      response: [
        {
          q: 3,
          a: 'Pre-installed bloatware apps are annoying; they clutter the interface.',
        },
      ],
      analysis: [
        {
          id: 'q3p3a1',
          theme: 6,
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 3,
      pid: 4,
      response: [
        {
          q: 3,
          a: "I've experienced a few software glitches, which can be frustrating at times.",
        },
      ],
      analysis: [
        {
          id: 'q3p4a1',
          theme: 6,
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 3,
      pid: 5,
      response: [
        {
          q: 3,
          a: "The price is steep, but the phone's performance justifies the cost.",
        },
      ],
      analysis: [
        {
          id: 'q3p5a1',
          theme: 0,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 3,
      pid: 6,
      response: [
        {
          q: 3,
          a: 'The battery life is good, but fast charging would have been a game-changer.',
        },
      ],
      analysis: [
        {
          id: 'q3p6a1',
          theme: 4,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 3,
      pid: 7,
      response: [
        {
          q: 3,
          a: 'The phone feels solid but slightly heavier than my old device.',
        },
      ],
      analysis: [
        {
          id: 'q3p7a1',
          theme: 1,
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 3,
      pid: 8,
      response: [
        {
          q: 3,
          a: 'I dislike the pre-installed bloatware apps; they clutter my screen. for me this is a no go, would not use this product',
        },
      ],
      analysis: [
        {
          id: 'q3p8a1',
          theme: 6,
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 3,
      pid: 9,
      response: [
        {
          q: 3,
          a: "I've had occasional software glitches that are annoying.",
        },
      ],
      analysis: [
        {
          id: 'q3p9a1',
          theme: 6,
          sentiment: 'negative',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 4,
      pid: 0,
      response: [
        {
          q: 4,
          a: 'This smartphone is a performance beast, leaving my old phone in the dust.',
        },
      ],
      analysis: [
        {
          id: 'q4p0a1',
          theme: 3,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 4,
      pid: 1,
      response: [
        {
          q: 4,
          a: "The camera quality is a significant step up, and it's my favorite upgrade.",
        },
      ],
      analysis: [
        {
          id: 'q4p1a1',
          theme: 2,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 4,
      pid: 2,
      response: [
        {
          q: 4,
          a: "Gaming performance is top-notch, and I'm enjoying lag-free gaming sessions.",
        },
      ],
      analysis: [
        {
          id: 'q4p2a1',
          theme: 3,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 4,
      pid: 3,
      response: [
        {
          q: 4,
          a: 'The battery life surpasses my old device, which is a welcome change.',
        },
      ],
      analysis: [
        {
          id: 'q4p3a1',
          theme: 4,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 4,
      pid: 4,
      response: [
        {
          q: 4,
          a: "The display's sharpness and brightness are noticeably superior, enhancing my experience.",
        },
      ],
      analysis: [
        {
          id: 'q4p4a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 4,
      pid: 5,
      response: [
        {
          q: 4,
          a: "This smartphone outperforms my previous one in every aspect, and I'm thrilled.",
        },
      ],
      analysis: [
        {
          id: 'q4p5a1',
          theme: 3,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 4,
      pid: 6,
      response: [
        {
          q: 4,
          a: 'The camera quality is a major upgrade and elevates my photography game.',
        },
      ],
      analysis: [
        {
          id: 'q4p6a1',
          theme: 2,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 4,
      pid: 7,
      response: [
        {
          q: 4,
          a: 'Gaming performance is top-notch; I can play without any lag or issues.',
        },
      ],
      analysis: [
        {
          id: 'q4p7a1',
          theme: 3,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 4,
      pid: 8,
      response: [
        {
          q: 4,
          a: 'The battery life is significantly better than my old device.',
        },
      ],
      analysis: [
        {
          id: 'q4p8a1',
          theme: 4,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 4,
      pid: 9,
      response: [
        {
          q: 4,
          a: 'The display is a visual treat, making everything look stunning.',
        },
      ],
      analysis: [
        {
          id: 'q4p9a1',
          theme: 0,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 5,
      pid: 0,
      response: [
        {
          q: 5,
          a: 'The wireless charging pad is a game-changer, and it adds convenience to my life.',
        },
      ],
      analysis: [
        {
          id: 'q5p0a1',
          theme: 7,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 5,
      pid: 1,
      response: [
        {
          q: 5,
          a: 'Water resistance provides peace of mind, especially during outdoor activities.',
        },
      ],
      analysis: [
        {
          id: 'q5p1a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 5,
      pid: 2,
      response: [
        {
          q: 5,
          a: 'The adaptive screen refresh rate helps save battery and is a cool feature.',
        },
      ],
      analysis: [
        {
          id: 'q5p2a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 5,
      pid: 3,
      response: [
        {
          q: 5,
          a: "I didn't expect the IR blaster, but it's proven to be quite handy in various situations.",
        },
      ],
      analysis: [
        {
          id: 'q5p3a1',
          theme: 7,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 5,
      pid: 4,
      response: [
        {
          q: 5,
          a: 'The smartphone seamlessly integrates with my smart home devices, making life more convenient.',
        },
      ],
      analysis: [
        {
          id: 'q5p4a1',
          theme: 5,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 5,
      pid: 5,
      response: [
        {
          q: 5,
          a: 'The wireless charging pad is a game-changer, and I love its convenience.',
        },
      ],
      analysis: [
        {
          id: 'q5p5a1',
          theme: 7,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 5,
      pid: 6,
      response: [
        {
          q: 5,
          a: 'The water resistance gives me peace of mind, especially in unpredictable weather.',
        },
      ],
      analysis: [
        {
          id: 'q5p6a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 5,
      pid: 7,
      response: [
        {
          q: 5,
          a: 'The adaptive screen refresh rate is a neat feature for saving battery.',
        },
      ],
      analysis: [
        {
          id: 'q5p7a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 5,
      pid: 8,
      response: [
        {
          q: 5,
          a: "The phone's AI features are a pleasant surprise; they make life easier.",
        },
      ],
      analysis: [
        {
          id: 'q5p8a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
    {
      qid: 5,
      pid: 9,
      response: [
        {
          q: 5,
          a: "I love the phone's ability to wirelessly charge other devices.",
        },
      ],
      analysis: [
        {
          id: 'q5p9a1',
          theme: 1,
          sentiment: 'positive',
          reference: {
            resNum: 1,
            range: 'all',
          },
        },
      ],
    },
  ],
  participants: {
    0: {
      pid: 0,
      name: 'Will Cruise',
      age: 20,
      race: 'African American',
      gender: 'Male',
      location: 'Maine',
    },
    1: {
      pid: 1,
      name: 'Sam Cage',
      age: 22,
      race: 'African American',
      gender: 'Male',
      location: 'Maine',
    },
    2: {
      pid: 2,
      name: 'Emily Smith',
      age: 30,
      race: 'Caucasian',
      gender: 'Female',
      location: 'New York',
    },
    3: {
      pid: 3,
      name: 'Carlos Sanchez',
      age: 22,
      race: 'Hispanic',
      gender: 'Male',
      location: 'California',
    },
    4: {
      pid: 4,
      name: 'Jessica Williams',
      age: 48,
      race: 'African American',
      gender: 'Female',
      location: 'Texas',
    },
    5: {
      pid: 5,
      name: 'Li Wong',
      age: 42,
      race: 'Asian',
      gender: 'Other',
      location: 'New York',
    },
    6: {
      pid: 6,
      name: 'Maria Lopez',
      age: 30,
      race: 'Hispanic',
      gender: 'Female',
      location: 'Florida',
    },
    7: {
      pid: 7,
      name: 'Michael Jones',
      age: 30,
      race: 'Caucasian',
      gender: 'Male',
      location: 'Texas',
    },
    8: {
      pid: 8,
      name: 'Sarah Miller',
      age: 27,
      race: 'Caucasian',
      gender: 'Female',
      location: 'Texas',
    },
    9: {
      pid: 9,
      name: 'David Brown',
      age: 52,
      race: 'African American',
      gender: 'Male',
      location: 'Georgia',
    },
  },
  questions: {
    '0': {
      question: 'What are your initial impressions of the new smartphone?',
      meta: {},
    },
    '1': {
      question: 'How has the new smartphone improved your daily routine or activities?',
      meta: {},
    },
    '2': {
      question: 'What features or functionalities of the smartphone do you like the most?',
      meta: {},
    },
    '3': {
      question: 'Are there any drawbacks or areas where the new smartphone could be improved?',
      meta: {},
    },
    '4': {
      question: 'How does the new smartphone compare to your previous one in terms of performance?',
      meta: {},
    },
    '5': {
      question: 'Have you encountered any unique or unexpected features on the new smartphone?',
      meta: {},
    },
  },
  themes: {
    '0': 'Design and Aesthetics',
    '1': 'Price and Value',
    '2': 'Software and Ecosystem',
    '3': 'Performance and Specifications',
    '4': 'Battery Life',
    '5': 'Connectivity',
    '6': 'Security and Privacy',
    '7': 'Accessories',
  },
  insights: [
    {
      title: 'High Demand for Eco-Friendly Products',
      description: [
        'There is a growing consumer demand for eco-friendly and sustainable household cleaning products, with approximately 70% of respondents expressing a preference for environmentally friendly products over traditional options.',
      ],
      relatedThemes: [1],
    },
    {
      title: 'Price Sensitivity',
      description: [
        'Price remains a significant consideration, with about 60% of participants indicating they would only switch to eco-friendly products if the price point was comparable to conventional cleaning products.',
      ],
      relatedThemes: [1],
    },
    {
      title: 'Effectiveness Concerns',
      description: [
        'Approximately 50% of respondents are skeptical about the efficacy of eco-friendly products for tough cleaning jobs.',
      ],
      relatedThemes: [1],
    },
    {
      title: 'Brand Trust',
      description: [
        'Consumers tend to trust brands that are transparent about their ingredients and sustainability practices. Nearly 80% of participants stated that they would be more loyal to a brand that actively demonstrates its commitment to environmental sustainability.',
      ],
      relatedThemes: [1],
    },
    {
      title: 'Packaging Matters',
      description: [
        'About 65% of respondents prefer products with minimal, recyclable, or biodegradable packaging.',
      ],
      relatedThemes: [1],
    },
    {
      title: 'Education and Awareness',
      description: [
        'There is a gap in consumer knowledge regarding the benefits and availability of eco-friendly cleaning products. Around 55% of consumers expressed that they would likely purchase eco-friendly products if more information on their benefits and effectiveness was readily available.',
      ],
      relatedThemes: [1],
    },
    {
      title: 'Segment Preferences',
      description: [
        'Different demographics show varied preferences, with younger consumers (ages 18-34) more likely to try new eco-friendly products, while older consumers (ages 55 and above) prioritize product effectiveness and brand loyalty.',
      ],
      relatedThemes: [1],
    },
    {
      title: 'Online vs. In-store Purchases',
      description: [
        'The majority of eco-friendly product purchases are made online, but there is a significant market portion that still prefers to buy cleaning products in-store.',
      ],
      relatedThemes: [1],
    },
    {
      title: 'Battery life improvements',
      description: [
        'Try to remove bloatware, that might be draining battery life',
        'Dim display sooner, current display shuts off only after 5 minutes of inactivity',
      ],
      relatedThemes: [1],
    },
    {
      title: 'Phone Design',
      description: [
        'In addition to the current dark colored cases, Add some bright colors to appeal to a younger crowed',
        'Consider adding a screen protector as part of the package',
      ],
      relatedThemes: [3],
    },
    {
      title: 'Camera',
      description: [
        'Some users raised privacy issues, consider adding a camera cover, to physically block camera',
      ],
      relatedThemes: [3],
    },
  ],

  pieSegments: {
    age: [
      {
        values: [0, 9],
        label: '0-9',
      },
      {
        values: [10, 19],
        label: '10-19',
      },
      {
        values: [20, 29],
        label: '20-29',
      },
      {
        values: [30, 39],
        label: '30-39',
      },
      {
        values: [40, 49],
        label: '40-49',
      },
      {
        values: [50, 59],
        label: '50-59',
      },
      {
        values: [60, 69],
        label: '60-69',
      },
      {
        values: [70, 79],
        label: '70-79',
      },
      {
        values: [80, 89],
        label: '80-89',
      },
      {
        values: [90, 99],
        label: '90-99',
      },
      {
        values: [100, 200],
        label: '100+',
      },
    ],
  },
  pieKeys: {
    participants: { name: false },
  },
};

//console.log(JSON.stringify(sampleData));
export { sampleData };
