import {
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  Box,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { InsightData } from 'types/DataSet.type';

const AccordList = ({ data, isExpandAll }: { data: InsightData[]; isExpandAll: boolean }) => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>(
    isExpandAll ? data.map((_, i) => i) : []
  );
  const toggleIndex = (index: number) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes((activeIndexes) => activeIndexes.filter((i) => i !== index));
    } else {
      setActiveIndexes((activeIndexes) => [...activeIndexes, index]);
    }
  };
  return (
    <>
      <Accordion allowMultiple index={activeIndexes}>
        {data.map(({ title, points }, i) => (
          <AccordionItem key={i}>
            <h2>
              <AccordionButton
                onClick={() => {
                  toggleIndex(i);
                }}
              >
                <Box as="span" flex="1" textAlign="left">
                  {title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <List>
                {points.map((point, i) => (
                  <ListItem key={i}>
                    <div className="flex">
                      <ListIcon className="mt-1" as={AiFillEye} color="blue.400" />
                      <div className="whitespace-pre-wrap">{point.replaceAll('\\n', '\n')}</div>
                    </div>
                  </ListItem>
                ))}
              </List>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default AccordList;
