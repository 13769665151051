import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import LogoLabel from 'components/Logo/LogoLabel.component';
import JsonSelectorView from './JsonSelector.view';
import DashboardAdmin from './DashboardAdmin.view';

const AdminPanel = () => {
  return (
    <div className="h-screen">
      <header className="bg-slate-50 border-b-gray-400 border-b-2">
        <nav className="mx-auto flex items-start p-6 lg:px-2" aria-label="Global">
          <LogoLabel to="/" />
          <div className="w-full flex lg:flex-1 ml-8 text-2xl align-middle justify-center ">
            <span className="mt-2">Admin Console</span>
          </div>
        </nav>
      </header>
      <Tabs variant="line" colorScheme="green" style={{ height: 'calc(100% - 100px)' }}>
        <TabList>
          <Tab>Dashboard Editor</Tab>
          <Tab>Survey Editor</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <DashboardAdmin />
          </TabPanel>
          <TabPanel height={'80vh'}>
            <JsonSelectorView />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default AdminPanel;
