import React from 'react';
import { useAuth } from 'services/Providers/StoreProvider';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingScreen from 'views/LoadingScreen.view';

type Props = { redirectTo: string; children: React.ReactNode; isAdmin?: boolean };

const WithAuth = ({ redirectTo, children, isAdmin }: Props) => {
  const auth = useAuth();
  if (auth.isAuthLoading) return <LoadingScreen title="loading" />;
  if (isAdmin && !auth.user?.meta.isAdmin) return <Navigate to={redirectTo} />;
  if (auth.user == null) return <Navigate to={redirectTo} />;
  return <>{children}</>;
};

export default observer(WithAuth);
