import { AiOutlineClear } from 'react-icons/ai';
import { Checkbox, IconButton } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { QuestionColumn } from 'types/DataSet.type';

const columnHelper = createColumnHelper<QuestionColumn>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const questionColumns: ColumnDef<QuestionColumn, any>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <IconButton
        className="-ml-2"
        aria-label="Call Segun"
        size="sm"
        icon={<AiOutlineClear />}
        onClick={() => {
          table.resetRowSelection();
        }}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        isChecked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    size: 20,
    maxSize: 20,
  },
  columnHelper.accessor('qNum', {
    cell: (info) => info.getValue(),
    header: '#',
    size: 20,
    maxSize: 20,
  }),
  columnHelper.accessor('question', {
    cell: (info) => info.getValue(),
    header: 'Question',
    size: 400,
  }),
];

export { questionColumns };
