import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  DefaultLegendContent,
} from 'recharts';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { PieSectorDataItem } from 'recharts/types/polar/Pie';
import { ActiveShape } from 'recharts/types/util/types';
import { PieChartData } from 'types/DataSet.type';
import { Tooltip as CkToolTip } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';

const RADIAN = Math.PI / 180;

const ToolTipWrapper = ({ value, width }: { value: string; width: number }) => {
  const MAX_CHAR = width * 0.15 - 52;
  if (value.length < MAX_CHAR) return <span className="">{value}</span>;
  return (
    <CkToolTip label={value}>
      <span>{value.substring(0, MAX_CHAR)}...</span>
    </CkToolTip>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const scrollableLegend = (props: any) => {
  const newProps = { ...props };
  newProps.layout = 'vertical';
  return (
    <span className="block overflow-auto h-[300px] slim-sb">
      <DefaultLegendContent {...newProps} />
    </span>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderCustomizedLabel = (props: any) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
  const radius = (innerRadius + outerRadius) * 0.7; // average of inner and outer radius
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      style={{ pointerEvents: 'none' }}
      x={x}
      y={y}
      fill="white"
      textAnchor="middle" // center the text
      dominantBaseline="middle" // center the text vertically
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

type PieChartProps = {
  data: PieChartData[];
  outerRadius?: number;
  fill?: string;
  onSelection: (selected: string[]) => void;
  tooltipSuffix?: string;
};

const getSector = (isApart: boolean): ActiveShape<PieSectorDataItem> => {
  const sector: ActiveShape<PieSectorDataItem> = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, midAngle } = props;
    const ma = midAngle || 0;
    const oRadius = outerRadius || 0;
    const sin = Math.sin(-RADIAN * ma);
    const cos = Math.cos(-RADIAN * ma);
    const sx = (cx || 0) + (oRadius - 110) * cos;
    const sy = (cy || 0) + (oRadius - 110) * sin;
    return (
      <Sector
        cx={isApart ? cx : sx}
        cy={isApart ? cy : sy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        style={{ filter: 'brightness(70%)', outline: 'none' }}
        fill={fill}
      />
    );
  };
  return sector;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = (props: any) => {
  const { active, payload, tooltipSuffix } = props;
  if (active && payload && payload.length) {
    return (
      <div className="bg-black text-white rounded-md p-1 pr-2 pl-2">
        <p className="label">{`${payload[0]?.value} ${tooltipSuffix ?? ''}`}</p>
      </div>
    );
  }

  return null;
};

const PieChartDemo = memo(
  ({ data, outerRadius = 120, fill = '#8884d8', onSelection, tooltipSuffix }: PieChartProps) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const { width: mWidth } = useSize(elementRef) ?? { width: null };
    const [curWidth, setWidth] = useState<number>(mWidth || 500);

    useEffect(() => {
      if (mWidth) {
        setWidth(mWidth);
      }
    }, [mWidth]);

    const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
    //console.log('[[data]]:', toJS(data));
    const [overrideData, setOverideData] = useState(data);
    const displayData = selectedIndex !== undefined ? overrideData : data;
    const COLORS = displayData.map(({ color }) => color);
    useEffect(() => {
      if (selectedIndex === undefined) {
        setOverideData(data);
      }
    }, [data, selectedIndex]);

    const onMouseOver = useCallback((data: never, index: number) => {
      setActiveIndex(index);
    }, []);

    const onMouseLeave = useCallback(() => {
      if (!selectedIndex) setActiveIndex(undefined);
    }, [selectedIndex]);

    const onMouseClick = useCallback(() => {
      if (selectedIndex !== undefined && activeIndex === selectedIndex) {
        setSelectedIndex(undefined);
        onSelection([]);
      } else {
        // console.log(
        //   'set selected',
        //   activeIndex,
        //   activeIndex !== undefined ? ovverideData[activeIndex].name : 'na',
        //   toJS(ovverideData)
        // );
        onSelection(activeIndex !== undefined ? [displayData[activeIndex].name] : []);
        setSelectedIndex(activeIndex);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex, selectedIndex]);
    return (
      <div className="w-full h-full" ref={elementRef}>
        <ResponsiveContainer minWidth={100} minHeight={100} width="100%" height={300}>
          <PieChart width={500} height={500} style={{ outline: 'none' }}>
            <Pie
              style={{ outline: 'none' }}
              activeIndex={selectedIndex ?? activeIndex}
              activeShape={getSector(selectedIndex === undefined)}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
              onClick={onMouseClick}
              data={displayData.map(({ name, value }) => ({ name, value }))}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={outerRadius}
              fill={fill}
              dataKey="value"
            >
              {displayData.map((entry, index) => (
                <Cell
                  style={{ outline: 'none' }}
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip tooltipSuffix={tooltipSuffix} />} />
            <Legend
              content={scrollableLegend}
              formatter={(value, entry, index) =>
                selectedIndex !== undefined && selectedIndex !== index ? (
                  <span className="text-gray-200">
                    <ToolTipWrapper value={value} width={curWidth} />
                  </span>
                ) : (
                  <ToolTipWrapper value={value} width={curWidth} />
                )
              }
              verticalAlign="top"
              align="right"
              layout="vertical"
              height={36}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
              onClick={onMouseClick}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
);

export default PieChartDemo;
