import { useState, useEffect } from 'react';
import logger from 'services/Logger/Pino';

const log = logger.child({ context: 'usePromise' });

const usePromise = <T>(
  asyncFunc: () => Promise<T>,
  onSuccess?: (data: T) => void,
  refreshId = 'NONE'
) => {
  const [data, setData] = useState<T | null>(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsPending(true);
      try {
        const response = await asyncFunc();
        setIsPending(false);
        setData(response);
        if (onSuccess) onSuccess(response);
        setError(null);
      } catch (error) {
        log.error(JSON.stringify(error));
        setError(`${error}`);
        setIsPending(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshId]);
  return { data, isPending, error };
};

export default usePromise;
