/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatedProps, animated, useSpringRef, useTransition } from '@react-spring/web';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Page, UserData } from 'types/ClientForm';
import ClientPage from './ClientPage.view';
import { useParams } from 'react-router-dom';
import { useSurveyConfig } from 'services/Providers/StoreProvider';
import { observer } from 'mobx-react-lite';
import { getFollowupConfig } from 'utils/SampleBE/pFetch';
import Show from 'components/Show.component';
import { Spinner, useBoolean } from '@chakra-ui/react';
import ClosingPage from './ClosingPage.view';
import { firebase } from 'services/Firebase/Firebase.service';
import usePromise from 'hooks/usePromise';

type PageComponent = (props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement;

const getPagesComponents = (
  pages: Page[],
  onComplete: (data: Record<string, UserData>, pNum: number) => void
): PageComponent[] => {
  return pages.map((page, pNum) => {
    // eslint-disable-next-line react/prop-types
    return ({ style }) => (
      <animated.div style={{ ...style, width: '100%', overflow: 'hidden' }} key={pNum}>
        <ClientPage pageData={page} onPageComplete={(data) => onComplete(data, pNum)} />
      </animated.div>
    );
  });
};

const OuterClientForm = () => {
  const [index, setIdx] = useState(0);
  const [, setFormRes] = useState({});
  //const [selectedSurvey, setSelectedSurvey] = useState<ClientFormDef | null>(null);
  const survey = useSurveyConfig();
  const { cid, instanceId, userId } = useParams();
  const fb = firebase();
  const {
    data: config,
    error,
    isPending,
  } = usePromise(
    () => fb.getSurveyConfig(cid || '', instanceId || ''),
    (data) => data && survey.overrideConfig(data)
  );

  const onDone = () => {
    setIsDone.on();
    fb.updateSurveyResponse(cid || '', instanceId || '', userId || '', survey.demoResults?.ans);
  };
  const selectedSurvey = config ? survey.surveyMap[config.id] : null;
  const [loading, setLoading] = useBoolean(!selectedSurvey);
  const [isDone, setIsDone] = useBoolean(false);
  const isSurveyReady = !!selectedSurvey;
  useEffect(() => {
    if (selectedSurvey) setLoading.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveyReady]);
  const onComplete = (data: Record<string, UserData>, pNum: number) => {
    //survey.overrideConfig()
    //  <Spinner size='xl' />
    setFormRes((prevRes) => {
      const currentRes = { ...prevRes, ...data };
      if (selectedSurvey) survey.setDemoResults(selectedSurvey, currentRes);
      return currentRes;
    });
    if (selectedSurvey) {
      if (selectedSurvey?.pages[pNum].sync) {
        setLoading.on();
        getFollowupConfig(cid || '', instanceId || '', pNum, data).then((config) => {
          survey.overrideConfig(config);
          survey.setDemoResults(config);
          if (config.pages.length - 1 == pNum) {
            onDone();
          } else {
            setTimeout(() => setIdx((pNum) => pNum + 1), 20);
          }
          setLoading.off();
        }); //TODO:M: add error flow
      } else {
        if (selectedSurvey.pages.length - 1 == pNum) {
          onDone();
        } else setIdx((pNum) => (pNum + 1) % selectedSurvey.pages.length);
      }
    }
  };

  const pageComponents = useMemo(
    () => (selectedSurvey ? getPagesComponents(selectedSurvey.pages, onComplete) : []),
    [JSON.stringify(selectedSurvey?.pages || '')]
  );

  const transRef = useSpringRef();
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  });
  useEffect(() => {
    transRef.start();
  }, [index]);

  if (error)
    return (
      <div>
        <p>Error</p>
      </div>
    ); //TODO:M: add error flow
  return (
    <div className={`flex fill w-[100vw] h-[100vh] overflow-hidden`}>
      <Show ifTrue={!isDone} elseRender={<ClosingPage />}>
        <Show
          ifTrue={!(loading || isPending)}
          elseRender={
            <div className="flex flex-col h-screen w-screen justify-center items-center">
              <Spinner colorScheme="blue" thickness="40px" size={'xl'} />
              <p className="mt-4 text-xl">Loading</p>
            </div>
          }
        >
          {transitions((style, i) => {
            if (pageComponents[i]) {
              const Page = pageComponents[i];
              return <Page key={i} style={style} />;
            }
            return null;
          })}
        </Show>
      </Show>
    </div>
  );
};

export default observer(OuterClientForm);
