import { ClientFormDef } from 'types/ClientForm';

// const textFieldSchema = {
//   title: 'TextField',
//   description: 'basic text',
//   type: 'object',
//   properties: {
//     name: {
//       description: 'Name of the person',
//       type: 'string',
//       minLength: 3,
//     },
//   },
// };

// const sampleFormDef2: ClientFormDef = {
//   id: 'samsung',
//   name: 'Samsung S18 phone',
//   description:
//     "research firsthand experience with the Samsung S18 before it's officially released.",
//   goal: "Gather user insights about the phone's design, features, performance, and overall user experience",
//   model: 'gpt-4',
//   // system: `A professional interviewer,
//   // you are part of a research into the new Samsung phones,
//   // research goals is to find out what features users liked the most
//   // this is only a small segment of the interview, and you main focus is:
//   // what colors did the user liked the most?

//   // if at any point you feel the user has given a satisfactory answer simply say 'done'
//   // avoid drilling down into questions, or asking annoying follow-ups`,

//   defaultPrompt: `
// You are a professional interviewer, which is part of a research,
// The research description is: {{description}}
// research goal: {{goal}}

// you are doing an interview with a user, this is only a small segment of the interview,
// your goal is to figure out if the following transcript had answered the initial question asked
// if it has simply write 'no'
// if it has not, Ask a follow up questions, only if you feel the answers were lacking
// when writing the followup questions only write the question.

// transcript:
// {{transcript}}

// `,

//   pages: [
//     {
//       title: 'Question 1',
//       components: [
//         {
//           id: 'name',
//           type: 'input',
//           label: 'What is your name',
//           settings: {
//             validation: {
//               // see https://www.npmjs.com/package/schema-to-yup in regards to validation rules
//               yup: {
//                 schema: textFieldSchema,
//                 errorMessage: { name: { minLength: 'Name too short' } },
//               },
//               custom: ['text.basicFormat'],
//             },
//             submitText: 'Next',
//           },
//         },
//         // {
//         //   type: 'selection',
//         //   label: 'What is your gender',
//         //   validation: {},
//         //   settings: { options: ['Male', 'Female', 'Other'] },
//         // },
//       ],
//     },

//     {
//       title: 'Question 2',
//       components: [
//         {
//           id: 'age',
//           type: 'number',
//           label: 'How old are you ?',
//           settings: { min: 10, max: 140, start: 20, submitText: 'next' },
//         },
//       ],
//     },
//     {
//       title: 'Question 3',
//       components: [
//         {
//           id: 'gender',
//           type: 'buttonSelection',
//           label: 'Select your Gender ?',
//           settings: { options: ['male', 'female', 'other'] },
//         },
//       ],
//     },
//     {
//       title: 'Question 4',
//       components: [
//         {
//           id: 'chat',
//           type: 'chat',
//           label: 'Talk to us',
//           settings: {
//             placeholder: 'type here',
//             maxFollowups: 10,
//             questions: [
//               'What do you think about our new colors, do you like them',
//               'Do you like the screen size',
//             ],
//             closingMessage:
//               'Thanks for helping us understand what we can do better, press next to continue',
//             submitText: 'next',
//           },
//         },
//         // {
//         //   type: 'selection',
//         //   label: 'What is your gender',
//         //   validation: {},
//         //   settings: { options: ['Male', 'Female', 'Other'] },
//         // },
//       ],
//     },
//     {
//       title: 'Question 5',
//       sync: true,
//       components: [
//         {
//           id: 'product',
//           type: 'input',
//           label:
//             'How would you describe your firsthand experience with the Samsung S18 and its various features?',
//           settings: {
//             validation: {},
//             placeholder: 'Write here...',
//             multiLine: true,
//             disablePaste: true,
//           },
//         },
//       ],
//     },

//     {
//       title: 'Question 6',
//       sync: true,
//       components: [
//         {
//           id: 'userInfo',
//           type: 'video',
//           label: 'Tell us what you liked most about our product',
//           settings: {},
//         },
//       ],
//     },
//   ],
// };

const sampleFormDef: ClientFormDef = {
  id: 'samsung_S23_2',
  name: 'Samsung S23 phone',
  description:
    "research firsthand experience with the Samsung S23 before it's officially released.",
  goal: "Gather user insights about the phone's design, features, performance, and overall user experience",
  model: 'gpt-4',
  defaultPrompt:
    "\nYou are a professional interviewer which is part of a research.\nThe research description is: {{description}}\nResearch goal: {{goal}}\n\nYou are conducting an interview with a user, this is the current transcript of the interview:.\nTranscript:{{transcript}}\n\nYour goal is to figure out if the transcript contains an answer to the first question appearing there or if a followup question is needed.\nIf it's not needed, simply write 'no'. Otherwise, ask a follow up question only if you feel the answer(s) were lacking.\nWhen writing the followup question only write the question itself.",
  pages: [
    {
      title: 'Question 1',
      components: [
        {
          id: 'name',
          type: 'input',
          label: 'What is your name',
          settings: {
            validation: {
              yup: {
                schema: {
                  title: 'TextField',
                  description: 'basic text',
                  type: 'object',
                  properties: {
                    name: {
                      description: 'Name of the person',
                      type: 'string',
                      minLength: 3,
                    },
                  },
                },
                errorMessage: {
                  name: {
                    minLength: 'Name too short',
                  },
                },
              },
              custom: ['text.basicFormat'],
            },
            submitText: 'Next',
          },
        },
      ],
    },
    {
      title: '',
      components: [
        {
          id: 'age',
          type: 'message',
          label: 'Thank you, We can now continue to Interview, When ready click Start',
          settings: {
            submitText: 'Start',
          },
        },
      ],
    },
    {
      title: 'Question 2',
      components: [
        {
          id: 'age',
          type: 'number',
          label: 'How old are you ?',
          settings: {
            min: 10,
            max: 140,
            start: 20,
            submitText: 'next',
          },
        },
      ],
    },
    {
      title: 'Question 3',
      components: [
        {
          id: 'gender',
          type: 'buttonSelection',
          label: 'Select your Gender ?',
          settings: {
            submitOnSelect: true,
            options: ['male', 'female', 'other'],
          },
        },
      ],
    },
    {
      title: 'Question 4',
      components: [
        {
          id: 'chat',
          type: 'chat',
          label: 'Talk to us',
          settings: {
            placeholder: 'type here',
            maxFollowups: 3,
            questions: ["What is your overall experience with the phone's camera"],
            closingMessage:
              'Thanks for helping us understand what we can do better, press next to continue',
            submitText: 'next',
          },
        },
      ],
    },
    {
      title: 'Question 6',
      sync: true,
      components: [
        {
          id: 'userInfo',
          type: 'video',
          label: 'Tell us what you liked most about our product',
          settings: {},
        },
      ],
    },
  ],
};

export { sampleFormDef };
