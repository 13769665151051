//type Props = {};
import { useState } from 'react';
import { Button, IconButton, Textarea, Tooltip } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useSurveyConfig } from 'services/Providers/StoreProvider';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

const JsonSelector = () => {
  const [config, setConfig] = useState('');
  const [error, setError] = useState('');
  const survey = useSurveyConfig();

  return (
    <div className="flex h-full">
      <div className="flex-1 p-4 h-full">
        <Textarea
          onChange={(e) => {
            setConfig(e.target.value);
          }}
          height={'100%'}
        ></Textarea>
        <Button
          onClick={() => {
            const error = survey.addSurveyConfig(config);
            setError(error);
          }}
          className="mt-4"
          colorScheme="blue"
        >
          Add config
        </Button>
        <Link to={'/'}>
          <Button className="mt-4 ml-4" colorScheme="teal">
            Back Home
          </Button>
        </Link>
        <p className="text-red-500 text-sm">{error}</p>
      </div>
      <div className="flex-1 pl-4 pr-4">
        {survey.allConfigs.map((config) => (
          <div key={config.id}>
            <Textarea
              height={40}
              className="whitespace-pre mt-4"
              value={JSON.stringify(config, null, 2)}
              readOnly
            />
            <div className="mt-4 flex">
              <Tooltip isDisabled={config.id == survey.defaultConfId} label="Make default">
                <IconButton
                  className="mr-2"
                  isRound={true}
                  variant="solid"
                  background={config.id == survey.defaultConfId ? 'teal.400' : 'white'}
                  color={'white'}
                  aria-label="Done"
                  fontSize="20px"
                  icon={<CheckIcon />}
                  borderColor={'teal'}
                  borderWidth={2}
                  onClick={() => {
                    survey.makeDefault(config.id);
                  }}
                />
              </Tooltip>
              <Button
                colorScheme="red"
                onClick={() => {
                  survey.deleteSurveyConfig(config.id);
                }}
              >
                Delete config
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(JsonSelector);
