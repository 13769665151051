const ColorDotsLoader: React.FC = () => {
  return (
    <div className="flex flex-row gap-2">
      <div
        className="w-4 h-4 rounded-full animate-bounce bg-red-700"
        style={{ animation: 'bounce 1s infinite, colorChange 3s infinite' }}
      ></div>
      <div
        className="w-4 h-4 rounded-full animate-bounce bg-blue-700"
        style={{
          animation: 'bounce 1s infinite, colorChange 3s infinite',
          animationDelay: '-0.3s',
        }}
      ></div>
      <div
        className="w-4 h-4 rounded-full animate-bounce bg-amber-500"
        style={{
          animation: 'bounce 1s infinite, colorChange 3s infinite',
          animationDelay: '-0.5s',
        }}
      ></div>
    </div>
  );
};

// eslint-disable-next-line import/no-unused-modules
export default ColorDotsLoader;
