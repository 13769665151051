import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logger from 'services/Logger/Pino';
import { useAuth } from 'services/Providers/StoreProvider';
import { LoginForm } from 'utils/validationSchemas/Login.schema';
const log = logger.child({ context: 'LoginPage' });
type LoginForm = { email: string; password: string };

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  const redirectUrl = searchParams.get('redirect') || '/';
  return (
    <div className="bg-blue-100 h-screen flex flex-col justify-center">
      <Container
        maxW="lg"
        py={{ base: '12', md: '24' }}
        px={{ base: '0', sm: '8' }}
        className=" -mt-52"
      >
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginForm}
          validateOnChange={false}
          onSubmit={(values: LoginForm) => {
            auth.signInEmail(values.email, values.password).then((res) => {
              if (res?.error) {
                setError("Couldn't sign in. Please check your credentials.");
              } else {
                setError('');
                log.info('Redirecting to: ' + redirectUrl);
                navigate(redirectUrl);
              }
            });
          }}
        >
          {({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing="8">
                <Stack spacing="6">
                  <div className="flex align-middle justify-center ">
                    <div className="h-16 w-16 bg-logo bg-contain mr-4"></div>
                  </div>
                  <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                    <Heading size="lg">Log in to your account</Heading>
                  </Stack>
                </Stack>
                <Box
                  py={{ base: '0', sm: '8' }}
                  px={{ base: '4', sm: '10' }}
                  bg={{ base: 'transparent', sm: 'bg.surface' }}
                  boxShadow={{ base: 'none', sm: 'md' }}
                  borderRadius={{ base: 'none', sm: 'xl' }}
                  backgroundColor={'white'}
                >
                  <Stack spacing="6">
                    <Stack spacing="5">
                      <FormControl isInvalid={!!errors.email}>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Field as={Input} id="email" name="email" type="email" variant="filled" />

                        {!errors.password ? (
                          <div className="h-2"></div>
                        ) : (
                          <FormErrorMessage className="h-1">{errors.email}</FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl isInvalid={!!errors.password}>
                        <FormLabel htmlFor="email">Password</FormLabel>
                        <Field
                          as={Input}
                          id="password"
                          name="password"
                          type="password"
                          variant="filled"
                        />
                        {!errors.password ? (
                          <div className="h-2"></div>
                        ) : (
                          <FormErrorMessage className="h-1">{errors.password}</FormErrorMessage>
                        )}
                      </FormControl>
                      {/* <PasswordField /> */}
                    </Stack>

                    <Stack spacing="6">
                      <Button type="submit" colorScheme="blue">
                        Sign in
                      </Button>

                      {/* <OAuthButtonGroup /> */}
                    </Stack>
                  </Stack>
                </Box>
                <Text className="h-1 text-red-500 -mt-6 m-auto">{error}</Text>
              </Stack>
            </form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default LoginPage;
