/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClientFormDef } from 'types/ClientForm';
import { RootStore } from './RootStore';
import { makeAutoObservable, toJS } from 'mobx';
import localForage from 'localforage';
import { sampleFormDef } from 'utils/SampleBE/SampleFormDefinition';
import logger from 'services/Logger/Pino';
const log = logger.child({ context: 'SurveyStore' });

const DEFAULT_DEMO_ID_KEY = 'default';
const CONFIG_MAP_KEY = 'config';
const SITE_VERSION_KEY = 'version';
const SITE_VERSION = import.meta.env.VITE_SITE_VERSION;

export class SurveyStore {
  surveyMap: Record<string, ClientFormDef> = {};
  context: string | null = null;
  defaultConfId = '';
  demoResults: { conf: ClientFormDef; ans: any } | null = null;

  constructor(public root: RootStore) {
    makeAutoObservable(this);
    localForage.getItem(SITE_VERSION_KEY).then((version) => {
      const shouldUpdate = !version || version !== SITE_VERSION;
      log.info(
        shouldUpdate
          ? `NEW VERSION FOUND: ${SITE_VERSION} updating from ${version || '0.0.0'}`
          : `Version ${SITE_VERSION} is good, no update required`
      );
      localForage.getItem(CONFIG_MAP_KEY).then((config: any) => {
        const defaultConfig = { [sampleFormDef.id]: sampleFormDef };
        if (shouldUpdate) {
          config = null;
          localForage.setItem(SITE_VERSION_KEY, SITE_VERSION).then(() => {
            log.info('Update complete.');
          });
        }
        if (!config || Object.keys(config).length == 0) {
          config = defaultConfig;
          localForage.setItem(CONFIG_MAP_KEY, config);
        }
        localForage.getItem<string>(DEFAULT_DEMO_ID_KEY).then((defaultKey) => {
          this.defaultConfId = defaultKey || sampleFormDef.id;
        });
        this.surveyMap = config || {};
      });
    });
  }

  overrideConfig(config: ClientFormDef) {
    this.surveyMap[config.id] = config;
  }

  setContext(context: string) {
    this.context = context;
  }

  get allConfigs() {
    return Object.values(this.surveyMap);
  }

  addSurveyConfig(config: string): string {
    let configObj = {};
    let error = '';

    try {
      configObj = JSON.parse(config);
    } catch (e: any) {
      return e.message;
    }

    const configTyped = configObj as ClientFormDef;
    if (!configTyped.id || !configTyped.name) error = 'missing id or name';
    if (this.surveyMap[configTyped.id]) error = 'duplicate id given. please provide unique id';
    if (!error) {
      this.surveyMap[configTyped.id] = configTyped;
      if (Object.keys(this.surveyMap).length == 1) {
        localForage.setItem(DEFAULT_DEMO_ID_KEY, toJS(configTyped.id));
      }
      localForage.setItem(CONFIG_MAP_KEY, toJS(this.surveyMap));
    }
    return error;
  }

  deleteSurveyConfig(id: string) {
    delete this.surveyMap[id];
    if (Object.keys(this.surveyMap).length == 0) {
      this.surveyMap[sampleFormDef.id] = sampleFormDef;
      log.debug('Deleteing: ' + sampleFormDef);
    }
    if (this.defaultConfId == id) {
      const newDefaultId = Object.keys(this.surveyMap)?.[0] || '';
      localForage.setItem(DEFAULT_DEMO_ID_KEY, newDefaultId);
      this.defaultConfId = newDefaultId;
    }
    localForage.setItem(CONFIG_MAP_KEY, toJS(this.surveyMap));
  }

  makeDefault(id: string) {
    localForage.setItem(DEFAULT_DEMO_ID_KEY, id);
    this.defaultConfId = id;
  }

  setDemoResults(conf: ClientFormDef, ans?: any) {
    if (!ans && this.demoResults?.conf) this.demoResults.conf = conf;
    else {
      this.demoResults = { conf, ans };
    }
  }
}

/*
function determineIfIsAnimalOrHuman(toBeDetermined: PersonOrAnimal): toBeDetermined is Animal {
  if((toBeDetermined as Animal).type){
    return true
  }
  return false
}
*/
