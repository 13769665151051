/* eslint-disable jsx-a11y/anchor-is-valid */
import { DashBoardDTO, PieChartData } from 'types/DataSet.type';
import {
  Button,
  Box,
  Slide,
  Checkbox,
  CheckboxGroup,
  Stack,
  IconButton,
  useOutsideClick,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { RefObject, useRef, useState } from 'react';

type Props = {
  dashData: DashBoardDTO;
  onClose: () => void;
  isOpen: boolean;
  onSelection: (section: string, selection: string[]) => void;
  onClickOutside: () => void;
};

type CheckboxContainerProps = {
  title: string;
  values: string[];
  onSelection: (selection: string[]) => void;
};

const CheckboxContainer = ({ title, values, onSelection }: CheckboxContainerProps) => {
  const [selected, setSelected] = useState<string[]>([]);
  const invertSelection = () => {
    const newSelected = values.filter((value) => !selected.includes(value));
    handleSelection(newSelected);
  };

  const handleSelection = (selection: string[]) => {
    setSelected(selection);
    onSelection(selection);
  };

  return (
    <div>
      <div className="flex justify-between mt-2">
        <p className="text-xl">{title}</p>
        <div>
          <Button
            onClick={() => handleSelection([])}
            colorScheme="blue"
            variant={'ghost'}
            className="m-1"
            size="sm"
          >
            Clear
          </Button>
          <Button
            onClick={() => invertSelection()}
            colorScheme="blue"
            variant={'ghost'}
            className="m-1"
            size="sm"
          >
            Invert Selection
          </Button>
        </div>
      </div>
      <Box className="border-gray-400 border-[1px] p-1 rounded-md">
        <CheckboxGroup
          colorScheme="blue"
          value={selected}
          onChange={(e) => {
            handleSelection(e as string[]);
          }}
        >
          <Stack spacing={[0.5, 0.5]} direction={['column']}>
            {values.map((value) => (
              <Checkbox borderColor={'darkgray'} key={value} value={value}>
                {value}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </Box>
    </div>
  );
};

const getCbDataList = (data: Record<string, PieChartData[]>) => {
  const dataList: { section: string; values: string[] }[] = [];
  Object.keys(data).forEach((key) => {
    const currData = data[key];
    if (currData) dataList.push({ section: key, values: currData.map(({ name }) => name) });
  });
  return dataList;
};

const FilterSideBar = ({ dashData, onClose, isOpen, onSelection, onClickOutside }: Props) => {
  const [initialDashData] = useState(dashData);
  const { themesData, participantsPieCharts, analysisPieCharts } = initialDashData;
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick({
    ref: ref,
    handler: () => onClickOutside(),
  });
  const participantList = getCbDataList(participantsPieCharts);
  const analysisList = getCbDataList(analysisPieCharts);
  return (
    <>
      <div ref={ref as RefObject<HTMLDivElement>}>
        <Slide
          direction="left"
          in={isOpen}
          style={{ width: '400px', maxWidth: '100vw', zIndex: 40 }}
          className="bg-gray-100 overflow-y-auto tiny-sb"
        >
          <div className="p-4">
            <div className="flex justify-between">
              <p className="text-4xl mb-4">Filter</p>
              <IconButton
                colorScheme="facebook"
                variant={'ghost'}
                aria-label="Search database"
                icon={<CloseIcon />}
                onClick={onClose}
              />
            </div>
            <CheckboxContainer
              title="Themes"
              values={themesData.data.map(({ name }) => name)}
              onSelection={(s) => onSelection('theme', s)}
            />
            {participantList.map(({ section, values }) => (
              <CheckboxContainer
                key={section}
                title={section}
                values={values}
                onSelection={(s) => onSelection(section, s)}
              />
            ))}
            {analysisList.map(({ section, values }) => (
              <CheckboxContainer
                key={section}
                title={section}
                values={values}
                onSelection={(s) => onSelection(section, s)}
              />
            ))}
          </div>
        </Slide>
      </div>
    </>
  );
};

// const MiniSideBar = () => {
//   return (
//     <>
//       <div className="flex flex-col items-center w-16 h-full overflow-hidden text-gray-700 bg-gray-100 rounded">
//         <a className="flex items-center justify-center mt-3" href="#">
//           <svg
//             className="w-8 h-8 fill-current"
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 20 20"
//             fill="currentColor"
//           >
//             <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
//           </svg>
//         </a>
//         <div className="flex flex-col items-center mt-3 border-t border-gray-300">
//           <a
//             className="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
//             href="#"
//           >
//             <svg
//               className="w-6 h-6 stroke-current"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
//               />
//             </svg>
//           </a>
//           <a
//             className="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
//             href="#"
//           >
//             <svg
//               className="w-6 h-6 stroke-current"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
//               />
//             </svg>
//           </a>
//           <a
//             className="flex items-center justify-center w-12 h-12 mt-2 bg-gray-300 rounded"
//             href="#"
//           >
//             <svg
//               className="w-6 h-6 stroke-current"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
//               />
//             </svg>
//           </a>
//           <a
//             className="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
//             href="#"
//           >
//             <svg
//               className="w-6 h-6 stroke-current"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
//               />
//             </svg>
//           </a>
//         </div>
//         <div className="flex flex-col items-center mt-2 border-t border-gray-300">
//           <a
//             className="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
//             href="#"
//           >
//             <svg
//               className="w-6 h-6 stroke-current"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
//               />
//             </svg>
//           </a>
//           <a
//             className="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-300"
//             href="#"
//           >
//             <svg
//               className="w-6 h-6 stroke-current"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
//               />
//             </svg>
//           </a>
//           <a
//             className="relative flex items-center justify-center w-12 h-12 mt-2 hover:bg-gray-300"
//             href="#"
//           >
//             <svg
//               className="w-6 h-6 stroke-current"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
//               />
//             </svg>
//             <span className="absolute top-0 left-0 w-2 h-2 mt-2 ml-2 bg-indigo-500 rounded-full" />
//           </a>
//         </div>
//         <a
//           className="flex items-center justify-center w-16 h-16 mt-auto bg-gray-200 hover:bg-gray-300"
//           href="#"
//         >
//           <svg
//             className="w-6 h-6 stroke-current"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             stroke="currentColor"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth={2}
//               d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
//             />
//           </svg>
//         </a>
//       </div>
//     </>
//   );
// };

export default FilterSideBar;
