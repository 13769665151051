/* eslint-disable jsx-a11y/media-has-caption */
import {
  Card,
  CardHeader,
  Heading,
  Box,
  CardBody,
  Stack,
  StackDivider,
  Text,
  Button,
} from '@chakra-ui/react';
import Show from 'components/Show.component';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useSurveyConfig } from 'services/Providers/StoreProvider';
import { ChatMessage, ComponentType, VideoData } from 'types/ClientForm';

type PageBoxProps = {
  question: string;
  answer: string;
  type: ComponentType;
};

const PageBox = ({ question, answer }: PageBoxProps) => {
  return (
    <Box>
      <Heading size="xs" textTransform="uppercase">
        {question}
      </Heading>

      <Text pt="2" fontSize="sm">
        {answer}
      </Text>
    </Box>
  );
};

const DemoSurveyResponse = () => {
  const survey = useSurveyConfig();
  const results = survey.demoResults;
  if (!results) return <div className="text-4xl p-40">No results found</div>;
  const { conf, ans } = results;
  return (
    <div className="flex justify-center items-center align-middle flex-col">
      <div className="lg:p-20 lg:w-[30vw] w-[100vw]">
        <Card>
          <CardHeader>
            <Heading size="md">{conf.name} survey, participant result</Heading>
          </CardHeader>

          <CardBody>
            <Stack divider={<StackDivider />} spacing="4">
              {conf.pages.map(({ components }) => {
                return components.map(({ label, type, id }) => {
                  return (
                    <>
                      {type == 'video' && (
                        <Box>
                          <Heading size="xs" className="mb-2" textTransform="uppercase">
                            {label}
                          </Heading>
                          <video
                            className="border-2 rounded-lg"
                            width={500}
                            height={500}
                            src={URL.createObjectURL((ans[id] as VideoData).video)}
                            controls
                          />
                          <p>{(ans[id] as VideoData).transcript}</p>
                        </Box>
                      )}
                      {type == 'chat' && (
                        <Box>
                          {(ans[id] as ChatMessage[]).map && (
                            <>
                              <Heading size="xs" textTransform="uppercase">
                                Chat Results
                              </Heading>
                              {(ans[id] as ChatMessage[]).map(({ content, role }, i) => (
                                <Show
                                  key={i}
                                  ifTrue={role == 'user'}
                                  elseRender={
                                    <Text fontWeight={600} pt="2" fontSize="sm">
                                      {content}
                                    </Text>
                                  }
                                >
                                  <Text pt="2" fontSize="sm">
                                    {content}
                                  </Text>
                                </Show>
                              ))}
                            </>
                          )}
                        </Box>
                      )}

                      <Show ifTrue={type != 'video' && type !== 'chat'}>
                        <PageBox key={id} question={label} answer={String(ans[id])} type={type} />
                      </Show>
                    </>
                  );
                });
              })}
            </Stack>
          </CardBody>
        </Card>
      </div>
      <div className="flex">
        <Link to="/">
          <Button colorScheme="blue" className="mr-2">
            Home
          </Button>
        </Link>
        <Link to="/insights">
          <Button colorScheme="blue">Insights Demo</Button>
        </Link>
      </div>
    </div>
  );
};

export default observer(DemoSurveyResponse);
