import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

type Props = { children: React.ReactNode };

const MainContainer = ({ children }: Props) => {
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || 'en';
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang);
    document.body.dir = i18n.dir();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return <div>{children}</div>;
};

export default MainContainer;
