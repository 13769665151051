import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  NumberInput,
  Button,
} from '@chakra-ui/react';

type Props = {
  min?: number;
  max?: number;
  start?: number;
  submitText?: string;
  onSubmit: (number: number) => void;
};

const NumberComponent = ({ min, max, start, submitText, onSubmit }: Props) => {
  return (
    <div className="flex justify-center">
      <NumberInput className="w-fit" max={max} min={min} defaultValue={start || 0}>
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>

      <Button colorScheme="blue" className="ml-2" onClick={() => onSubmit(20)}>
        {submitText}
      </Button>
    </div>
  );
};

export default NumberComponent;
