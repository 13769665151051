import * as Yup from 'yup';

const DashboardAddForm = Yup.object().shape({
  name: Yup.string().min(2, 'Name is too short').max(40, 'Name is too long').required('Required'),
  defaultDashboard: Yup.string()
    .min(2, 'Name is too short')
    .max(30, 'Name is too long')
    .required('Required'),
  groups: Yup.array().of(
    Yup.object().shape({
      dashName: Yup.string().min(2, 'Name Too short').max(30, 'Name too long').required('Required'),
      data: Yup.string().test('json', 'Invalid JSON format', (value) => {
        try {
          if (typeof value !== 'string') return false;
          JSON.parse(value);
          return true;
        } catch (error) {
          return false;
        }
      }),
    })
  ),
});

export { DashboardAddForm };
