import { firebase } from 'services/Firebase/Firebase.service';
import { RootStore } from './RootStore';
import { makeAutoObservable, runInAction } from 'mobx';
import { UserInfo } from 'types/Auth.type';
import logger from 'services/Logger/Pino';

const log = logger.child({ context: 'AuthStore' });
class AuthStore {
  user: UserInfo | null = null;
  isAuthLoading = true;

  constructor(public root: RootStore) {
    makeAutoObservable(this);
    firebase().registerAuthChangeHandler((user) => {
      runInAction(() => {
        this.isAuthLoading = false;
        this.user = user;
      });
    });
  }

  signInEmail(email: string, password: string): Promise<{ error?: string }> {
    return firebase()
      .signInEmail(email, password)
      .then((userCredential) => {
        // Signed in
        const uCred = userCredential.user;
        const user = {
          credentials: userCredential,
          displayName: uCred?.displayName || 'N/A',
          email: uCred?.email || 'N/A',
          meta: { isAdmin: false },
        };
        this.user = user;
        log.debug('User signed in: ' + JSON.stringify(user));
        return {};
      })
      .catch((error) => {
        log.error('Error signing in:' + JSON.stringify(error));
        const errorMessage = error.message;
        return { error: errorMessage };
      });
  }
}

export { AuthStore };
