import './Landing.style.css';
import heroImage from 'assets/hero.png';
import InsightImage from 'assets/features/insight.png';
import ThemeAnalysisImage from 'assets/features/themes.png';
import PersonaImage from 'assets/features/persona.png';
import DashboardImage from 'assets/features/dashboard.png';
import SentimentImage from 'assets/features/sentiment.png';
import LinkImage from 'assets/features/link.png';
import { useTranslation } from 'react-i18next';
import TransKeys from 'services/i18n/keys/transKeys';
import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import InformationalLayout from 'views/Layouts/InformationalLayout.view';

const HeroWaveSVG = () => {
  return (
    <svg
      viewBox="0 0 1428 174"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
          <path
            d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
            opacity="0.100000001"
          ></path>
          <path
            d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
            opacity="0.100000001"
          ></path>
          <path
            d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
            id="Path-4"
            opacity="0.200000003"
          ></path>
        </g>
        <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
          <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
        </g>
      </g>
    </svg>
  );
};

const BottomWaveSVG = () => {
  return (
    <svg
      className="wave-top"
      viewBox="0 0 1439 147"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
          <g className="wave" fill="#f8fafc">
            <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
          </g>
          <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
            <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
              <path
                d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                opacity="0.100000001"
              ></path>
              <path
                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                opacity="0.100000001"
              ></path>
              <path
                d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                opacity="0.200000003"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const FeatureRow = (props: {
  title: string;
  description: string;
  image: string;
  isImageOnLeft?: boolean;
}) => {
  const { title, description, image, isImageOnLeft } = props;

  if (!isImageOnLeft)
    return (
      <div className="flex flex-col sm:flex-row">
        <div className="w-full sm:w-5/6 md:w-1/2 p-2 px-14 sm:p-6 flex flex-col justify-center items-center sm:items-start">
          <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">{title}</h3>
          <p className="text-gray-600 mb-8 text-md text-center sm:text-left">{description}</p>
        </div>
        <div className="w-full sm:w-1/2 p-2 sm:p-6 flex justify-center sm:justify-start">
          <img className="w-40 z-30 object-contain ml-0 sm:ml-10" src={image} alt="hero" />
        </div>
      </div>
    );
  return (
    <div className="flex flex-col-reverse sm:flex-row">
      <div className="w-full sm:w-1/2 p-2 sm:p-6 mt-6 flex justify-center sm:justify-end">
        <img className="w-40 z-30 object-contain mr-0 sm:mr-10" src={image} alt="hero" />
      </div>

      <div className="w-full sm:w-5/6 md:w-1/2 p-2 px-14 sm:p-6 flex flex-col justify-center items-center sm:items-start mt-6">
        <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">{title}</h3>
        <p className="text-gray-600 mb-8 text-md text-center sm:text-left">{description}</p>
      </div>
    </div>
  );
};

const FeatureList = () => {
  const { t } = useTranslation();
  const featuresData = [
    {
      title: t(TransKeys.landing.features.insights.title),
      description: t(TransKeys.landing.features.insights.description),
      image: InsightImage,
    },
    {
      title: t(TransKeys.landing.features.ThemesAnalysis.title),
      description: t(TransKeys.landing.features.ThemesAnalysis.description),
      image: ThemeAnalysisImage,
    },
    {
      title: t(TransKeys.landing.features.SentimentAnalysis.title),
      description: t(TransKeys.landing.features.SentimentAnalysis.description),
      image: SentimentImage,
    },
    {
      title: t(TransKeys.landing.features.dashboard.title),
      description: t(TransKeys.landing.features.dashboard.description),
      image: DashboardImage,
    },
    {
      title: t(TransKeys.landing.features.persona.title),
      description: t(TransKeys.landing.features.persona.description),
      image: PersonaImage,
    },
    {
      title: t(TransKeys.landing.features.responseFiltering.title),
      description: t(TransKeys.landing.features.responseFiltering.description),
      image: LinkImage,
    },
  ];
  return (
    <div className="flex flex-wrap mt-20">
      {featuresData.map((feature, i) => (
        <div className={i % 2 === 1 ? 'md:mr-40' : 'md:ml-40'} key={i}>
          <FeatureRow
            title={feature.title}
            description={feature.description}
            image={feature.image}
            isImageOnLeft={i % 2 === 1}
          />
        </div>
      ))}
    </div>
  );
};

const Landing2 = () => {
  const { t } = useTranslation();
  const featureRef = useRef<HTMLDivElement | null>(null);

  const scrollToFeatures = () => {
    if (window.location.hash === '#features' && featureRef.current) {
      featureRef.current.scrollIntoView({ behavior: 'smooth' });
      featureRef.current.focus();
    }
  };

  useEffect(() => {
    scrollToFeatures();
  }, []);

  return (
    <InformationalLayout>
      <div
        className="leading-normal tracking-normal text-white gradient"
        style={{ fontFamily: 'Source Sans Pro, sans-serif' }}
      >
        <div className="pt-24">
          <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
            <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
              {/* <p className="uppercase tracking-loose w-full">What business are you?</p> */}
              <h1 className="my-4 text-5xl font-bold leading-tight">
                {t(TransKeys.landing.hero.title)}
              </h1>
              <p className="leading-normal text-2xl m-auto md:m-0 mb-8 text-gray-50 ">
                {t(TransKeys.landing.hero.subtitle)}
              </p>
              <Link to="https://calendly.com/rise-mind" target="_blank" className="m-auto md:m-0">
                <button className="mx-auto lg:mx-0 bg-amber-300 text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out no-underline">
                  {t(TransKeys.landing.scheduleDemo)}
                </button>
              </Link>
            </div>
            {/* <!--Right Col--> */}
            <div className="w-full md:w-3/5 flex justify-center py-6 text-center">
              <img className="w-full md:w-1/2 z-30" src={heroImage} alt="hero" />
            </div>
          </div>
        </div>
        <div className="relative -mt-12 lg:-mt-24">
          <HeroWaveSVG />
        </div>
        {/* <!-- Feature section --> */}
        <section className="bg-white border-b py-8">
          <div className="container max-w-5xl mx-auto m-8">
            <div>
              <p className="text-lg font-semibold text-black p-8">
                {t(TransKeys.landing.mainDescription)}
              </p>
            </div>
            <section ref={featureRef} id="features">
              <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                {t(TransKeys.landing.features.title)}
              </h2>
              <div className="w-full mb-4">
                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
              </div>
              <FeatureList />
            </section>
          </div>
        </section>

        {/* <section className="bg-white border-b py-8">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Title
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <a href="#" className="flex flex-wrap no-underline hover:no-underline">
                <p className="w-full text-gray-600 text-xs md:text-sm px-6">xGETTING STARTED</p>
                <div className="w-full font-bold text-xl text-gray-800 px-6">
                  Lorem ipsum dolor sit amet.
                </div>
                <p className="text-gray-800 text-base px-6 mb-5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc
                  commodo posuere et sit amet ligula.
                </p>
              </a>
            </div>
            <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
              <div className="flex items-center justify-start">
                <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Action
                </button>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <a href="#" className="flex flex-wrap no-underline hover:no-underline">
                <p className="w-full text-gray-600 text-xs md:text-sm px-6">xGETTING STARTED</p>
                <div className="w-full font-bold text-xl text-gray-800 px-6">
                  Lorem ipsum dolor sit amet.
                </div>
                <p className="text-gray-800 text-base px-6 mb-5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc
                  commodo posuere et sit amet ligula.
                </p>
              </a>
            </div>
            <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
              <div className="flex items-center justify-center">
                <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Action
                </button>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
              <a href="#" className="flex flex-wrap no-underline hover:no-underline">
                <p className="w-full text-gray-600 text-xs md:text-sm px-6">xGETTING STARTED</p>
                <div className="w-full font-bold text-xl text-gray-800 px-6">
                  Lorem ipsum dolor sit amet.
                </div>
                <p className="text-gray-800 text-base px-6 mb-5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc
                  commodo posuere et sit amet ligula.
                </p>
              </a>
            </div>
            <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
              <div className="flex items-center justify-end">
                <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Action
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        {/* <section className="bg-gray-100 py-8">
        <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Pricing
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
            <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
              <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                <div className="p-8 text-3xl font-bold text-center border-b-4">Free</div>
                <ul className="w-full text-center text-sm">
                  <li className="border-b py-4">Thing</li>
                  <li className="border-b py-4">Thing</li>
                  <li className="border-b py-4">Thing</li>
                </ul>
              </div>
              <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                  £0
                  <span className="text-base">for one user</span>
                </div>
                <div className="flex items-center justify-center">
                  <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="w-full p-8 text-3xl font-bold text-center">Basic</div>
                <div className="h-1 w-full gradient my-0 py-0 rounded-t"></div>
                <ul className="w-full text-center text-base font-bold">
                  <li className="border-b py-4">Thing</li>
                  <li className="border-b py-4">Thing</li>
                  <li className="border-b py-4">Thing</li>
                  <li className="border-b py-4">Thing</li>
                </ul>
              </div>
              <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div className="w-full pt-6 text-4xl font-bold text-center">
                  £x.99
                  <span className="text-base">/ per user</span>
                </div>
                <div className="flex items-center justify-center">
                  <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
              <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                <div className="p-8 text-3xl font-bold text-center border-b-4">Pro</div>
                <ul className="w-full text-center text-sm">
                  <li className="border-b py-4">Thing</li>
                  <li className="border-b py-4">Thing</li>
                  <li className="border-b py-4">Thing</li>
                </ul>
              </div>
              <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                  £x.99
                  <span className="text-base">/ per user</span>
                </div>
                <div className="flex items-center justify-center">
                  <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        <BottomWaveSVG />

        <section className="container mx-auto text-center py-6 ">
          <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-white">
            Stop wasting time and money
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <h3 className="my-4 text-xl leading-tight">
            Unlock Efficiency and Savings, Empower Your Qualitative Research with AI
          </h3>
          <Link to="https://calendly.com/rise-mind" target="_blank">
            <button
              id="navAction"
              className="mx-auto lg:mx-0 bg-amber-300 text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            >
              {t(TransKeys.landing.scheduleDemo)}
            </button>
          </Link>
        </section>
        <div className="h-52 md:h-40"></div>
      </div>
    </InformationalLayout>
  );
};

export default Landing2;
