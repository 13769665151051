import { ReactNode } from 'react';
import robo from '../assets/rbo.png';
import { AspectRatio, Image, Text } from '@chakra-ui/react';

type props = { message?: string; children?: ReactNode };

const MainError = ({ message, children }: props) => {
  if (!message) message = 'Somthing went wrong... go back and try again';
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <AspectRatio minW="400px" ratio={4 / 3}>
          <Image className="w-full" alt={'Login Image'} objectFit={'cover'} src={robo} />
        </AspectRatio>
        <Text className="text-2xl">{message}</Text>
        {children}
      </div>
    </div>
  );
};

export default MainError;
