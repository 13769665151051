import { Drawer, DrawerBody, DrawerContent, DrawerOverlay, useDisclosure } from '@chakra-ui/react';
import useWindowSize from 'hooks/useWindowSize';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
//import logger from 'services/Logger/Pino';
import TransKeys from 'services/i18n/keys/transKeys';
//const log = logger.child({ context: 'NavBar' });

type LinkProps = {
  isDrawer?: boolean;
  onNavClick?: () => void;
};

const LinkList = ({ isDrawer, onNavClick }: LinkProps) => {
  const { t } = useTranslation();
  const className = isDrawer ? 'p-2 m-2 text-lg' : '';
  return (
    <ul className="list-reset lg:flex justify-end flex-1 items-center">
      <li className={'mr-3 ' + className}>
        <a
          onClick={onNavClick}
          className="transition ease-in-out cursor-pointer inline-block text-black font-bold no-underline hover:text-pink-900 hover:text-underline py-2 px-4"
          href="/#features"
        >
          {t(TransKeys.navbar.features)}
        </a>
      </li>
      <li className={'mr-3 ' + className}>
        <Link to={'/about'} onClick={onNavClick}>
          <div className="transition ease-in-out cursor-pointer inline-block text-black font-bold no-underline hover:text-pink-900 hover:text-underline py-2 px-4">
            {t(TransKeys.navbar.aboutUS)}
          </div>
        </Link>
      </li>
      <li className={'mr-3 ' + className}>
        <Link to="/login?redirect=/hub" onClick={onNavClick}>
          <div className="transition ease-in-out cursor-pointer inline-block text-black font-bold no-underline hover:text-pink-900 hover:text-underline py-2 px-4">
            {t(TransKeys.navbar.Login)}
          </div>
        </Link>
      </li>
    </ul>
  );
};

const NavBar = () => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.join(',')]);
  const boxShadow = scrollPosition > 500 ? '0px 1px 6px 0px #0000004a' : 'none';
  return (
    <nav
      id="header"
      className="fixed w-full z-40 top-0 text-white"
      style={{
        background: `rgb(214, 82, 99,${Math.min(1, scrollPosition / 400)}`,
        boxShadow,
      }}
    >
      <Drawer placement={'top'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent background={'yellow.50'}>
          <DrawerBody>
            <LinkList isDrawer onNavClick={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <Link
          to={'/'}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <div className="pl-4 flex items-center cursor-pointer">
            <div className="h-12 w-12 bg-logo bg-contain mr-4"></div>

            <div className="toggleColour transition hover:-translate-y-1 text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
              {t(TransKeys.navbar.riseMind)}
            </div>
          </div>
        </Link>
        <div className="block lg:hidden pr-4">
          <button
            id="nav-toggle"
            className="flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            onClick={onOpen}
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>{t(TransKeys.navbar.menu)}</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
          id="nav-content"
        >
          <LinkList />
          <Link to="https://calendly.com/rise-mind" target="_blank">
            <button
              id="navAction"
              className="mx-auto lg:mx-0 bg-amber-300 text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            >
              {t(TransKeys.landing.scheduleDemo)}
            </button>
          </Link>
        </div>
      </div>
      <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
  );
};

export default NavBar;
