const TransKeys = {
  dashboard: {
    subthemes: 'dashboard.subthemes',
    themes: 'dashboard.themes',
    participantDemographics: 'dashboard.participantDemographics',
    ResponseInsights: 'dashboard.ResponseInsights',
    UserResponses: 'dashboard.UserResponses',
    Questions: 'dashboard.Questions',
  },
  responseTable: {
    question: 'responseTable.question',
    response: 'responseTable.response',
    name: 'responseTable.name',
    themes: 'responseTable.themes',
    link: 'responseTable.link',
  },
  navbar: {
    riseMind: 'navbar.riseMind',
    aboutUS: 'navbar.aboutUS',
    features: 'navbar.features',
    Login: 'navbar.Login',
    menu: 'navbar.menu',
  },
  landing: {
    scheduleDemo: 'landing.scheduleDemo',
    hero: { title: 'landing.hero.title', subtitle: 'landing.hero.subtitle' },
    mainDescription: 'landing.mainDescription',
    features: {
      title: 'landing.features.title',
      insights: {
        title: 'landing.features.insights.title',
        description: 'landing.features.insights.description',
      },
      ThemesAnalysis: {
        title: 'landing.features.ThemesAnalysis.title',
        description: 'landing.features.ThemesAnalysis.description',
      },
      SentimentAnalysis: {
        title: 'landing.features.SentimentAnalysis.title',
        description: 'landing.features.SentimentAnalysis.description',
      },
      dashboard: {
        title: 'landing.features.dashboard.title',
        description: 'landing.features.dashboard.description',
      },
      persona: {
        title: 'landing.features.persona.title',
        description: 'landing.features.persona.description',
      },
      responseFiltering: {
        title: 'landing.features.responseFiltering.title',
        description: 'landing.features.responseFiltering.description',
      },
    },
  },
  aboutUS: {
    hero: { title: 'aboutUS.hero.title', subtitle: 'aboutUS.hero.subtitle' },
    mainDescription: 'aboutUS.mainDescription',
    team: {
      title: 'aboutUS.team.title',
      description: 'aboutUS.team.description',
      yoel: {
        name: 'aboutUS.team.yoel.name',
        title: 'aboutUS.team.yoel.title',
        description: 'aboutUS.team.yoel.description',
      },
      michael: {
        name: 'aboutUS.team.michael.name',
        title: 'aboutUS.team.michael.title',
        description: 'aboutUS.team.michael.description',
      },
    },
  },
  contactUs: {
    title: 'contactUs.title',
    description: 'contactUs.description',
    emailSegment: 'contactUs.emailSegment',
    form: {
      title: 'contactUs.form.title',
      name: 'contactUs.form.name',
      email: 'contactUs.form.email',
      company: 'contactUs.form.company',
      job: 'contactUs.form.job',
      submit: 'contactUs.form.submit',
    },
  },
};
export default TransKeys;
