import { DashBoardDTO, Filter, RawData } from 'types/DataSet.type';
import { RootStore } from './RootStore';
import { makeAutoObservable, runInAction } from 'mobx';
import { sampleData } from 'utils/SampleBE/sampleDataCollections';
import { rawToStructured } from 'utils/SampleBE/dataProvider';
import logger from 'services/Logger/Pino';
import { getUnique, jstr } from 'utils/general.utils';
import { firebase } from 'services/Firebase/Firebase.service';
import { DashboardResponse } from 'types/DashboardEdit';

const log = logger.child({ context: 'DashboardStore' });

class DashboardStore {
  private dashboardGroupRawData: Record<string, RawData>;
  //dashboardData: DashBoardDTO;
  filter: Filter[] = [];
  dashboardGroupData: Record<string, DashBoardDTO> = {};
  _selectedDashboardGroupKey: string | undefined = undefined;
  defaultGroupKey: string | undefined = undefined;
  dashboardList: Record<string, DashboardResponse<string>> | null = null;

  constructor(public root: RootStore) {
    makeAutoObservable(this);
    this._selectedDashboardGroupKey = 'general';
    this.dashboardGroupData = {
      [this._selectedDashboardGroupKey]: rawToStructured(sampleData, []),
    };
    this.defaultGroupKey = this._selectedDashboardGroupKey;
    this.dashboardGroupRawData = {
      [this._selectedDashboardGroupKey]: sampleData,
    };
  }

  getAllDashboards() {
    if (this.dashboardList == null) {
      this.dashboardList = {};
      firebase().registerDashboardListHandler((data) => {
        runInAction(() => {
          this.dashboardList = data;
        });
      });
    }
    return this.dashboardList;
  }

  get dashboardGroupKeys() {
    const gKeys = Object.keys(this.dashboardGroupData);
    if (!this.defaultGroupKey) return gKeys;
    const index = gKeys.indexOf(this.defaultGroupKey);
    if (index === -1) return gKeys;

    gKeys.splice(index, 1);
    gKeys.unshift(this.defaultGroupKey);
    return gKeys;
  }

  get selectedDashboardData() {
    if (this._selectedDashboardGroupKey)
      return this.dashboardGroupData[this._selectedDashboardGroupKey];
    return null;
  }

  get selectedDashboardUnfilteredData() {
    if (this._selectedDashboardGroupKey) {
      return rawToStructured(this.dashboardGroupRawData[this._selectedDashboardGroupKey], []);
    }
  }

  set selectedDashboardGroupKey(key: string) {
    this.clearFilter();
    this._selectedDashboardGroupKey = key;
  }

  get selectedDashboardGroupKeyIndex() {
    const index = this.dashboardGroupKeys.indexOf(this._selectedDashboardGroupKey || '');
    return index === -1 ? 0 : index;
  }

  // updateData(newData: RawData) {
  //   this.rawData = newData;
  //   this.dashboardData = rawToStructured(this.rawData, []);
  // }

  updateDashboardGroup(group: Record<string, RawData>, defaultDashboard?: string) {
    this.dashboardGroupData = {};
    this.dashboardGroupRawData = group;

    Object.entries(group).forEach(([key, value]) => {
      try {
        this.dashboardGroupData[key] = rawToStructured(value, []);
      } catch (e) {
        const error = e as Error;
        log.error(`Error updating dashboard group: ${error}`);
        log.trace(error.stack);
      }
    });
    this._selectedDashboardGroupKey = defaultDashboard || Object.keys(group)[0];
    this.defaultGroupKey = this._selectedDashboardGroupKey;
  }

  clearFilter() {
    this.setFilter([], true);
  }

  setFilter(filter: Filter[], clear = false) {
    log.trace(`setFilter(${clear},${jstr(filter)},${jstr(this.filter)})`);
    const joinedFilter = clear ? filter : [...this.filter, ...filter];
    const newFilter = joinedFilter.filter(({ key, value }) => {
      const sameKeyValueFilter = filter.filter(({ key: oKey }) => key === oKey);

      return (
        sameKeyValueFilter.some(({ value: oValue }) => value === oValue) ||
        sameKeyValueFilter.length == 0
      );
    });

    this.filter = getUnique(newFilter, (a, b) => a.key === b.key && a.value === b.value);
    log.debug(`After (${jstr(this.filter)})`);
    if (this._selectedDashboardGroupKey)
      this.dashboardGroupData[this._selectedDashboardGroupKey] = rawToStructured(
        this.dashboardGroupRawData[this._selectedDashboardGroupKey],
        this.filter
      );
  }

  removeFilter(keys: string[] | string) {
    const removeKeys: string[] = Array.isArray(keys) ? keys : [keys];
    log.trace(`removeFilter(${jstr(removeKeys)})`);
    this.filter = this.filter.filter(({ key }) => !removeKeys.includes(key));
    log.debug(`filter after remove(${jstr(this.filter)})`);

    if (this._selectedDashboardGroupKey)
      this.dashboardGroupData[this._selectedDashboardGroupKey] = rawToStructured(
        this.dashboardGroupRawData[this._selectedDashboardGroupKey],
        this.filter
      );
  }

  get data() {
    return this._selectedDashboardGroupKey
      ? this.dashboardGroupData[this._selectedDashboardGroupKey]
      : null;
  }
}

export { DashboardStore };
