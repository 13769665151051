import React, { useEffect, useRef } from 'react';
import { ChatMessage } from 'types/chat.types';
import ButtonSelection from '../widgets/ButtonSelection.component';
import logger from 'services/Logger/Pino';
import Show from '../Show.component';
import ColorDotsLoader from '../Loader/ColorDotsLoader.component';
const log = logger.child({ context: 'Messages' });

interface ChatMessagesProps {
  messages: ChatMessage[];
  updateMessage: (response: string, id: string) => void;
  loading?: boolean;
}

const ChatMessages: React.FC<ChatMessagesProps> = ({ messages, updateMessage, loading }) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages.length, loading]);

  return (
    <div className="flex-1 overflow-y-auto p-4">
      {messages.map((msg) => (
        <div key={msg.id}>
          <Message message={msg} updateMessage={(r) => updateMessage(r, msg.id)} />
          {msg.type !== 'select' && <Response response={msg.response || ''} />}
        </div>
      ))}
      <Show ifTrue={!!loading}>
        <div className="w-full flex justify-center items-center mt-8">
          <ColorDotsLoader />
        </div>
      </Show>
      <div ref={bottomRef}></div>
    </div>
  );
};

const Response: React.FC<{ response: string }> = ({ response }) => {
  if (response.length === 0) return null;
  return (
    <div className="p-3 rounded-lg max-w-3/4 break-words mt-4 bg-white shadow">{response}</div>
  );
};

const Message: React.FC<{
  message: ChatMessage;
  updateMessage: (response: string) => void;
}> = ({ message, updateMessage }) => {
  switch (message.type) {
    case 'query':
    case 'text':
      return (
        <div className="p-3 rounded-lg max-w-3/4 break-words mt-4 bg-[#357497] text-white shadow-md">
          {message.content}
        </div>
      );
    case 'image':
      return (
        <div className="mt-4 bg-[#357497] p-2 rounded-lg">
          <img src={message.content} alt="chat" className="w-full rounded-lg" />
        </div>
      );
    case 'audio':
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls src={message.content} className="w-full h-16 mt-2"></audio>
      );
    case 'select':
      return (
        <div className="mt-4">
          <ButtonSelection
            requireSelection={true}
            hideSubmit={true}
            multiSelect={false}
            onSubmit={(selection) => {
              log.info('Selected:', selection);
              updateMessage(selection);
            }}
            defaultValue={message.response}
            submitOnSelect={true}
            options={message.content.split(';')}
          />
        </div>
      );
    case 'video':
      return (
        <div className="bg-white p-2 rounded-lg shadow mt-4">
          {/*eslint-disable-next-line jsx-a11y/media-has-caption*/}
          <video src={message.content} controls className="w-full rounded-lg" />
        </div>
      );
    default:
      return null;
  }
};

export default ChatMessages;
