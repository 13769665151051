//type Props = {};
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import contactImage from 'assets/contact/contact.png';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Image,
  Text,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Box,
  useBoolean,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { ScheduleDemoForm } from 'utils/validationSchemas/scheduleDemo.schema';
import Show from 'components/Show.component';
import { firebase } from 'services/Firebase/Firebase.service';
import { DemoRequest } from 'types/Demo.type';
import logger from 'services/Logger/Pino';
import TransKeys from 'services/i18n/keys/transKeys';
import { useTranslation } from 'react-i18next';
import Wave from 'components/SVGs/Wave.component';
import InformationalLayout from 'views/Layouts/InformationalLayout.view';
const log = logger.child({ context: 'ContactUs' });
// pFetch('sync', {
//   formId: 'samsung',
//   page: 3,
//   answers: {
//     product:
//       "I had a fantastic experience using your mobile phone! The sleek design and intuitive user interface made it incredibly user-friendly. The camera quality exceeded my expectations, and the battery life kept me connected throughout the day without any worries. I was particularly impressed with the speed and reliability of the device. It's truly a game-changer in the world of smartphones. Thank you for creating such an outstanding product!",
//   },
// }).then((r) => {
//   console.log('R:', r);
// });

const DemoConfirmation = () => {
  return (
    <>
      <CardHeader>
        <Heading size="md">Thank You</Heading>
      </CardHeader>
      <CardBody paddingRight={8} paddingLeft={8} className="flex flex-col align-middle">
        <Text>
          We appreciate your interest in our product and look forward to showcasing its features to
          you. Our team will reach out to you shortly to confirm the details of the demo.
        </Text>
        <Link to="/">
          <Button type="submit" colorScheme="purple" className="mt-10">
            Close
          </Button>
        </Link>
      </CardBody>
    </>
  );
};

const ContactUs = () => {
  const [wasSubmitPressed, setSubmitPressed] = useBoolean(false);
  const [isSubmitted, setSubmitted] = useBoolean(false);
  const { t } = useTranslation();
  return (
    <InformationalLayout>
      <div className="h-full min-h-screen">
        <div className="absolute top-0 w-full h-20">
          <Wave />
        </div>

        <div className="z-40 flex flex-col  lg:flex-row pt-20 sm:pt-32 md:pt-64 lg:pt-80 items-center md:items-start justify-center w-full md:w-10/12 xl:w-7/12 m-auto">
          <Flex p={8} flex={1} flexDirection={'column'} width={'100%'} className="w-full">
            <div className="h-full flex flex-col w-4/5 justify-end items-center m-auto">
              {/* <Stack direction={['row', 'column']} spacing="50px"> */}

              <h2 className="my-4 text-4xl text-black font-bold leading-tight">
                {t(TransKeys.contactUs.title)}
              </h2>

              <div className="w-full p-4 text-sm md:text-black">
                <p>{t(TransKeys.contactUs.description)}</p>
              </div>
              <div className="w-full p-4 text-sm md:text-black z-40">
                <p>
                  {t(TransKeys.contactUs.emailSegment) + ' '}
                  <a
                    className="cursor-pointer text-blue-500 hover:underline"
                    href="mailto:info@rise-mind.com"
                  >
                    info@rise-mind.com
                  </a>
                </p>
              </div>
              <Image marginTop={10} className="w-[200px]  xl:w-[220px]" src={contactImage} />
            </div>
          </Flex>
          <Flex
            flex={1}
            className="overflow-hidden h-full p-10 w-full justify-center"
            width={'100%'}
          >
            <div className="shadow-lg shadow-pink-200 h-fit">
              <Card variant={'elevated'} className="flex w-96 items-center shadow-2xl h-fit">
                <Show ifTrue={!isSubmitted} elseRender={<DemoConfirmation />}>
                  <>
                    <CardHeader>
                      <Heading size="md">{t(TransKeys.contactUs.form.title)}</Heading>
                    </CardHeader>
                    <Box bg="white" p={6} rounded="md" w={80}>
                      <Formik
                        initialValues={{
                          email: '',
                          fullName: '',
                          title: '',
                          company: '',
                        }}
                        validationSchema={ScheduleDemoForm}
                        onSubmit={(values: DemoRequest) => {
                          firebase()
                            .requestDemo(values)
                            .catch((e) => {
                              log.error('OnSubmit error:' + e?.message);
                              //TODO: HANDLE ERROR
                              // console.log('[E]', e);
                            });
                          setSubmitted.on();
                        }}
                      >
                        {({ handleSubmit, errors }) => (
                          <form onSubmit={handleSubmit}>
                            <VStack spacing={4} align="flex-start">
                              <FormControl
                                isInvalid={!!errors.fullName && wasSubmitPressed}
                                minHeight={24}
                              >
                                <FormLabel htmlFor="password">
                                  {t(TransKeys.contactUs.form.name)}*
                                </FormLabel>
                                <Field
                                  as={Input}
                                  id="fullName"
                                  name="fullName"
                                  type="text"
                                  variant="filled"
                                />
                                <FormErrorMessage className="min-h-[10px]">
                                  {errors.fullName}
                                </FormErrorMessage>
                              </FormControl>

                              <FormControl
                                isInvalid={!!errors.email && wasSubmitPressed}
                                minHeight={24}
                              >
                                <FormLabel htmlFor="email">
                                  {t(TransKeys.contactUs.form.email)}*
                                </FormLabel>
                                <Field
                                  as={Input}
                                  id="email"
                                  name="email"
                                  type="email"
                                  variant="filled"
                                />
                                <FormErrorMessage>{errors.email}</FormErrorMessage>
                              </FormControl>

                              <FormControl
                                isInvalid={!!errors.company && wasSubmitPressed}
                                minHeight={24}
                              >
                                <FormLabel htmlFor="password">
                                  {t(TransKeys.contactUs.form.company)}
                                </FormLabel>
                                <Field
                                  as={Input}
                                  id="company"
                                  name="company"
                                  type="text"
                                  variant="filled"
                                />
                                <FormErrorMessage>{errors.company}</FormErrorMessage>
                              </FormControl>

                              <FormControl
                                isInvalid={!!errors.title && wasSubmitPressed}
                                minHeight={24}
                              >
                                <FormLabel htmlFor="password">
                                  {t(TransKeys.contactUs.form.job)}
                                </FormLabel>
                                <Field
                                  as={Input}
                                  id="title"
                                  name="title"
                                  type="text"
                                  variant="filled"
                                />
                                <FormErrorMessage>{errors.title}</FormErrorMessage>
                              </FormControl>

                              <Button
                                type="submit"
                                colorScheme="yellow"
                                width="full"
                                onClick={() => {
                                  setSubmitPressed.on();
                                }}
                              >
                                {t(TransKeys.contactUs.form.submit)}
                              </Button>
                            </VStack>
                          </form>
                        )}
                      </Formik>
                    </Box>
                  </>
                </Show>
              </Card>
            </div>
          </Flex>
        </div>
      </div>
      <div className="h-56 md:h-40"></div>
    </InformationalLayout>
  );
};

export default observer(ContactUs);
