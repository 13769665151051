import { IconButton, Tooltip } from '@chakra-ui/react';
import Show from 'components/Show.component';
import { toPng } from 'html-to-image';
import { useCallback, useRef } from 'react';
import { AiFillFileImage } from 'react-icons/ai';
import logger from 'services/Logger/Pino';

const log = logger.child({ context: 'ChartContainer' });

export const ChartContainer = ({
  children,
  h = 300,
  category,
  title,
  headerColor = '#eef9ff',
  downloadPng = false,
}: {
  children: JSX.Element;
  h?: number;
  category: string;
  title: string;
  headerColor?: string;
  downloadPng?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const onDownloadPngClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${category ? category + ' - ' : ''}${title}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        log.error('Error saving png:' + err);
      });
  }, [ref, category, title]);

  //const className = `relative h-[${h}px]`;
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
      <div className="rounded-t mb-0 px-4 py-3 bg-white" style={{ backgroundColor: headerColor }}>
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase mb-1 text-xs font-semibold">{category}</h6>
            <h2 className="text-gray-600 text-xl font-semibold">{title}</h2>
          </div>
          <Show ifTrue={downloadPng}>
            <div>
              <Tooltip label={'Download as png'}>
                <IconButton
                  variant="outline"
                  colorScheme="blue"
                  aria-label="Send email"
                  icon={<AiFillFileImage />}
                  onClick={onDownloadPngClick}
                />
              </Tooltip>
            </div>
          </Show>
        </div>
      </div>
      <div ref={ref} className="p-4 flex-auto bg-white">
        <div style={{ height: h }}>{children}</div>
      </div>
    </div>
  );
};
