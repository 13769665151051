import { useMediaQuery } from '@chakra-ui/react';

const Wave = () => {
  const [isLargerThan500] = useMediaQuery('(min-width: 500px)');
  const scale = isLargerThan500 ? 0.08 : 0.2;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transition: '.3s',
        transform: `scale(1, ${scale})`,
        transformOrigin: 'top',
      }}
      viewBox="0 0 100 200"
    >
      <defs>
        <linearGradient id="a" x1="0%" x2="100%" y1="0%" y2="0%">
          <stop offset="0%" stopColor="rgba(219, 39, 119, 1)" />
          <stop offset="80%" stopColor="rgb(245, 158, 11, 1)" />
        </linearGradient>
      </defs>
      <path
        fill="url(#a)"
        d="M 0 70 C 150 175 300 0 500 130 L 500 0 L 0 0"
        style={{
          transform: 'translate(0,0)',
          opacity: 1,
        }}
      />
      <defs>
        <linearGradient id="b" x1="0%" x2="100%" y1="0%" y2="0%">
          <stop offset="0%" stopColor="rgba(219, 39, 119, 1)" />
          <stop offset="80%" stopColor="rgb(245, 158, 11, 1)" />
        </linearGradient>
      </defs>
      <path
        fill="url(#b)"
        d="M 0 60 C 150 150 330 -60 500 82 L 500 0 L 0 0"
        style={{
          transform: 'translate(0,50px)',
          opacity: 0.9,
        }}
      />
    </svg>
  );
  // return (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     style={{
  //       transform: 'rotate(180deg)',
  //       transition: '.3s',
  //     }}
  //     viewBox="0 0 1440 100"
  //   >
  //     <defs>
  //       <linearGradient id="a" x1={0} x2={0} y1={1} y2={0}>
  //         <stop offset="0%" stopColor="rgba(219, 39, 119, 1)" />
  //         <stop offset="100%" stopColor="rgba(217, 119, 6, 1)" />
  //       </linearGradient>
  //     </defs>
  //     <path
  //       fill="url(#a)"
  //       d="M0 20h80c80 0 240 0 400 8.3C640 37 800 53 960 63.3c160 9.7 320 13.7 480 15 160 1.7 320 1.7 480-1.6 160-3.7 320-9.7 480-20 160-9.7 320-23.7 480-23.4 160-.3 320 13.7 480 10C3520 40 3680 20 3840 15s320 5 480 16.7C4480 43 4640 57 4800 65c160 8 320 12 480 5s320-23 480-35 320-18 480-16.7c160 1.7 320 11.7 480 23.4C6880 53 7040 67 7200 63.3c160-3.3 320-23.3 480-35C7840 17 8000 13 8160 25s320 38 480 51.7C8800 90 8960 90 9120 80s320-30 480-41.7C9760 27 9920 23 10080 20s320-7 480-3.3c160 3.3 320 13.3 480 11.6 160-1.3 320-15.3 400-21.6l80-6.7v100H0Z"
  //       style={{
  //         transform: 'translate(0,0)',
  //         opacity: 1,
  //       }}
  //     />
  //     <defs>
  //       <linearGradient id="b" x1={0} x2={0} y1={1} y2={0}>
  //         <stop offset="0%" stopColor="rgba(243, 106, 62, 0.75)" />
  //         <stop offset="100%" stopColor="rgba(255, 179, 11, 0.43)" />
  //       </linearGradient>
  //     </defs>
  //     <path
  //       fill="url(#b)"
  //       d="m0 70 80-10c80-10 240-30 400-41.7C640 7 800 3 960 1.7 1120 0 1280 0 1440 13.3c160 13.7 320 39.7 480 50 160 9.7 320 3.7 480-5C2560 50 2720 40 2880 40s320 10 480 10 320-10 480-8.3C4000 43 4160 57 4320 55s320-18 480-16.7c160 1.7 320 21.7 480 20 160-1.3 320-25.3 480-36.6 160-11.7 320-11.7 480-8.4 160 3.7 320 9.7 480 13.4C6880 30 7040 30 7200 40s320 30 480 33.3c160 3.7 320-10.3 480-21.6C8320 40 8480 30 8640 25s320-5 480-5 320 0 480-1.7c160-1.3 320-5.3 480-1.6 160 3.3 320 13.3 480 23.3s320 20 480 18.3c160-1.3 320-15.3 400-21.6l80-6.7v70H0Z"
  //       style={{
  //         transform: 'translate(0,50px)',
  //         opacity: 0.9,
  //       }}
  //     />
  //   </svg>
  // );
};

export default Wave;
