import { Button } from '@chakra-ui/react';

type InputComponentProps = {
  onSubmit: () => void;
  submitText?: string;
};

const MessageComponent = ({ onSubmit, submitText }: InputComponentProps) => {
  if (!submitText) submitText = 'next';
  return (
    <div className="w-full flex align-middle justify-center">
      <Button
        className="mt-2 w-fit"
        size="md"
        onClick={() => {
          onSubmit();
        }}
        colorScheme="blue"
      >
        {submitText}
      </Button>
    </div>
  );
};

export default MessageComponent;
