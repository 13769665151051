import { ChakraProvider } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';
import { StoreProvider } from 'services/Providers/StoreProvider';
import * as Sentry from '@sentry/react';
import './services/i18n/i18n';
import router from 'Routes';
import ReactGA from 'react-ga4';
import 'styles/global.style.css';

ReactGA.initialize('G-PQGFKHJX5Y');

const IS_DEVELOPMENT = import.meta.env.DEV;

if (!IS_DEVELOPMENT) {
  Sentry.init({
    dsn: 'https://49f62f53a826833e555b35ae6fec6be6@o4506200993824768.ingest.sentry.io/4506200995594240',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function App() {
  return (
    <StoreProvider>
      <ChakraProvider>
        {/* <OuterClientForm formDef={sampleFormDef} /> */}
        <RouterProvider router={router} />
      </ChakraProvider>
    </StoreProvider>
  );
}

export default App;
