import { memo, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from 'recharts';

const CustomizedAxisTick = (props: {
  x?: number;
  y?: number;
  payload?: { value: string };
  width?: number;
  total: number;
}) => {
  const { x, y, payload } = props;
  const MAX_WORD_LEN = 60; //Math.trunc((width || 1000) / total / 7);
  return (
    <Text x={x} y={y} width={40} textAnchor="middle" verticalAnchor="start" fontSize={14}>
      {(payload?.value.length || 0) > MAX_WORD_LEN
        ? `${payload?.value.substring(0, MAX_WORD_LEN)}...`
        : payload?.value}
    </Text>
  );
};

type BarChartProps = {
  data: Record<string, number | string>[];
  colorKey: Record<string, string>;
  onSelection: (key: string[]) => void;
  selectedBarsIndex?: number[];
};

const BarChartDemo = memo(({ data, colorKey, onSelection, selectedBarsIndex }: BarChartProps) => {
  const [selectedBars, setSelectedBars] = useState<number[]>(selectedBarsIndex || []);
  const [overrideData, setOverideData] = useState<Record<string, number | string>[]>(data);
  const hasSelection = selectedBars.length != 0 || selectedBarsIndex?.length != 0;
  const displayData = hasSelection ? overrideData : data;

  useEffect(() => {
    if (!hasSelection) {
      setOverideData(data);
    }
  }, [data, hasSelection]);

  useEffect(() => {
    setSelectedBars(selectedBarsIndex || []);
  }, [selectedBarsIndex]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={displayData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        onClick={(barData) => {
          // console.log('DATA', toJS(data), '\n\n==\n\n', barData, barData?.activeTooltipIndex);
          if (barData.activeTooltipIndex === undefined) return;
          const activeIdx: number = barData.activeTooltipIndex;
          if (selectedBars.length > 0 && selectedBars?.includes(activeIdx)) {
            setSelectedBars((pBars) => {
              const pCpy = [...pBars];
              pCpy.splice(pBars.indexOf(activeIdx), 1);
              onSelection(pCpy.map<string>((aInd) => String(displayData[aInd].name)));
              return pCpy;
            });
          } else {
            setSelectedBars((pBars) => {
              const selection = [...pBars, activeIdx];
              onSelection(selection.map<string>((aInd) => String(displayData[aInd].name)));
              return selection;
            });
          }
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          height={100}
          interval={0}
          dataKey="name"
          tick={<CustomizedAxisTick total={Object.keys(data).length} />}
        />
        <YAxis />
        <Tooltip cursor={{ fill: '#d3edf9' }} />
        <Legend verticalAlign="top" align="right" layout="vertical" />

        {Object.entries(colorKey).map(([key, color]) => (
          <Bar key={key} dataKey={key} stackId="a" fill={color}>
            {displayData.map((entry, index) => {
              return (
                <Cell
                  key={index}
                  cursor="pointer"
                  fill={
                    selectedBars.includes(index) || selectedBars.length === 0 ? color : 'lightgray'
                  }
                />
              );
            })}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
});

export default BarChartDemo;
