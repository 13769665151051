import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const ClosingPage = () => {
  return (
    <div className="flex flex-col w-full h-full justify-center items-center ">
      <p className="text-4xl">Thank you for participating !</p>
      <p className="text-xl mt-2">feel free to close page</p>
      <div className="flex">
        <Link to="/insights">
          <Button colorScheme="blue" className="mt-4 mr-2">
            Insights Demo
          </Button>
        </Link>
        <Link to={'/response'}>
          <Button colorScheme="blue" className="mt-4">
            See Response Json
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ClosingPage;
