import {
  Card,
  CardBody,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import mikeBwImage from 'assets/mikec-bw.png';
import mikeColImage from 'assets/mikec-col.png';
import yoelBwImage from 'assets/yoel_bw.png';
import yoelColImage from 'assets/yoel_color.png';
import aboutUsMain from 'assets/aboutUs/aboutMain.png';
import { useTranslation } from 'react-i18next';
import TransKeys from 'services/i18n/keys/transKeys';
import { Link } from 'react-router-dom';
import Footer from 'components/Footer/Footer.component';
import Wave from 'components/SVGs/Wave.component';
import InformationalLayout from 'views/Layouts/InformationalLayout.view';

type infoProps = {
  name: string;
  title: string;
  description?: string;
  colorImgSrc: string;
  bnwImgSrc: string;
};

type ModalInfoProps = {
  name: string;
  title: string;
  description: string;
  onClose: () => void;
  isOpen: boolean;
};

function InfoModal({ description, title, onClose, isOpen, name }: ModalInfoProps) {
  // const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={'lg'}>
      <ModalOverlay />
      <ModalContent bgGradient="linear(to-b, #f9e9ce 0% , #fbf8f3 58% )">
        <ModalHeader>
          <div className="text-md font-bold">{name} </div>
          <div className="text-sm font-bold text-gray-700">{title} </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody marginBottom={4} className="text-sm">
          {description}
        </ModalBody>
        {/* <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
}

const InfoCard = ({ name, title, description, colorImgSrc, bnwImgSrc }: infoProps) => {
  const [isInColor, setIsInColor] = useBoolean();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <InfoModal
        description={description || ''}
        title={title || ''}
        name={name || ''}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Card
        maxW="sm"
        className="m-4"
        onClick={onOpen}
        onMouseEnter={() => setIsInColor.on()}
        onMouseLeave={() => setIsInColor.off()}
      >
        <CardBody>
          <Image
            src={isInColor ? colorImgSrc : bnwImgSrc}
            alt="Green double couch with wooden legs"
            borderRadius="lg"
            width={160}
          />
          <Stack mt="6" spacing="3">
            <Heading size="md">{name}</Heading>
            <Text>{title}</Text>
            {/* <Text className="text-sm" width={180}>
            {description}
          </Text> */}
          </Stack>
        </CardBody>
      </Card>
    </>
    // <Box
    //   className="mt-6 mb-12 md:m-6"
    //   maxW={'90vw'}
    //   w="420px"
    //   h="fit-content"
    //   onMouseEnter={() => setIsInColor.on()}
    //   onMouseLeave={() => setIsInColor.off()}
    // >
    //   <Image
    //     className="w-full"
    //     objectFit={'cover'}
    //     src={isInColor ? colorImgSrc : bnwImgSrc}
    //   ></Image>
    //   <div className="mt-4 bg-transparent md:bg-white  text-white md:text-black">
    //     <Text className="text-2xl font-black">{name}</Text>
    //     <Text className="text-2xl">{title}</Text>
    //     <Text className="text-md">{description}</Text>
    //   </div>
    // </Box>
  );
};

function AboutUs() {
  const { t } = useTranslation();
  return (
    <InformationalLayout>
      <div className="md:visible absolute top-0 w-full h-full">
        <Wave />
      </div>

      <div className="relative h-full w-full">
        <div className="pt-24 lg:pt-40 z-20 lg:w-10/12 m-auto">
          <div className="mt-20 container  px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center justify-center">
            <div className=" p-2 flex flex-col w-full lg:w-2/5 justify-center md:items-start text-center md:text-left pt-8 ">
              {/* <p className="uppercase tracking-loose w-full">What business are you?</p> */}
              <h1 className="my-4 text-5xl  font-bold leading-tight">
                {t(TransKeys.aboutUS.hero.title)}
              </h1>
              <p className="leading-normal text-2xl mb-8 ">{t(TransKeys.aboutUS.hero.subtitle)}</p>

              <p className="leading-normal text-md mb-8 ">{t(TransKeys.aboutUS.mainDescription)}</p>
            </div>

            <Image
              src={aboutUsMain}
              alt="Green double couch with wooden legs"
              borderRadius="lg"
              width={80}
              className="md:ml-20"
            />
          </div>
        </div>
        {/* <div className="mt-20 mb-40 h-full flex justify-center align-middle items-center flex-col w-4/5 m-auto">
          <h2 className="my-4 text-4xl text-black font-bold leading-tight">Our Values</h2>
        </div> */}
        <div>
          <div className="m-auto">
            {/* <div
            className="w-screen h-[34vh] bg-gradient-to-r from-pink-600 via-amber-600 to-amber-500 min-h-[200px] flex items-center align-middle justify-center text-blue-50"
            style={{
              fontFamily: 'fantasy',
            }}
          >
            <Text fontSize="5xl">Our Team</Text>
          </div> */}

            <div className="mt-28 h-full flex justify-center align-middle items-center flex-col w-full lg:w-10/12 m-auto">
              {/* <Stack direction={['row', 'column']} spacing="50px"> */}

              <h2 className="my-4 text-4xl text-black font-bold leading-tight">
                {t(TransKeys.aboutUS.team.title)}
              </h2>

              <div className="w-full md:w-10/12  2xl:w-6/12 p-4 text-md md:text-black">
                <p>{t(TransKeys.aboutUS.team.description)}</p>
              </div>
              <div className="flex flex-col md:flex-row z-20">
                <InfoCard
                  name={t(TransKeys.aboutUS.team.yoel.name)}
                  title={t(TransKeys.aboutUS.team.yoel.title)}
                  description={t(TransKeys.aboutUS.team.yoel.description)}
                  colorImgSrc={yoelColImage}
                  bnwImgSrc={yoelBwImage}
                />

                <InfoCard
                  name={t(TransKeys.aboutUS.team.michael.name)}
                  title={t(TransKeys.aboutUS.team.michael.title)}
                  description={t(TransKeys.aboutUS.team.michael.description)}
                  colorImgSrc={mikeColImage}
                  bnwImgSrc={mikeBwImage}
                />
              </div>
              {/* <p className="mt-8">
                For inquiries about our AI-driven focus groups and research services, reach out to
                our team at
                <a
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  href="mailto:sales@rise-mind.com."
                >
                  sales@rise-mind.com.
                </a>
              </p> */}
            </div>
            <div className="mt-20 h-full flex justify-center align-middle items-center flex-col w-4/5 m-auto">
              <div className="h-56 w-full md:w-10/12 p-4 text-md md:text-black flex flex-col justify-center align-middle items-center">
                <p className="text-2xl">Talk to us today, schedule a demo</p>
                <Link to="https://calendly.com/rise-mind" target="_blank">
                  <button className="mx-auto lg:mx-0 bg-amber-300 text-gray-800 font-bold rounded-full my-6 py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out no-underline">
                    {t(TransKeys.landing.scheduleDemo)}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="z-30 ">
          <Footer />
        </div>
      </div>
    </InformationalLayout>
  );
}

export default AboutUs;
