import * as Yup from 'yup';

const ScheduleDemoForm = Yup.object().shape({
  fullName: Yup.string()
    .min(2, 'Name is too short')
    .max(30, 'Name is too long')
    .required('Required'),
  company: Yup.string().min(1, 'Too short').max(30, 'Company Name too long'),
  title: Yup.string().min(2, 'Too short').max(30, 'Too long'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export { ScheduleDemoForm };
