import { FC, ReactNode, createContext, useContext } from 'react';
//import { useParams } from 'react-router-dom';
import { RootStore } from 'stores/RootStore';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StoreContext = createContext<any>(null);
const rootStore = new RootStore();

interface StoreProviderProps {
  children: ReactNode;
}

const StoreProvider: FC<StoreProviderProps> = ({ children }) => {
  const Provider = StoreContext.Provider;
  return <Provider value={rootStore}>{children}</Provider>;
};

function useSurveyConfig() {
  const root = useContext<RootStore>(StoreContext);
  return root.surveyStore;
}

function useDashboard() {
  const root = useContext<RootStore>(StoreContext);
  return root.dashboardStroe;
}

function useAuth() {
  const root = useContext<RootStore>(StoreContext);
  return root.authStore;
}

function useChat(cid: string, instanceId: string, userId: string) {
  const root = useContext<RootStore>(StoreContext);
  root.chatStore.initConfig(cid, instanceId, userId);
  return root.chatStore;
}

export { StoreProvider, useSurveyConfig, useDashboard, useAuth, useChat };
