import { SurveyStore } from './SurveyStore';
import { DashboardStore } from './DashboardStore';
import { AuthStore } from './AuthStore';
import ChatStore from './Chat.store';
export class RootStore {
  public surveyStore: SurveyStore;
  public dashboardStroe: DashboardStore;
  public authStore: AuthStore;
  public chatStore: ChatStore;

  constructor() {
    this.surveyStore = new SurveyStore(this);
    this.dashboardStroe = new DashboardStore(this);
    this.authStore = new AuthStore(this);
    this.chatStore = new ChatStore(this);
  }
}
