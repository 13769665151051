/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder-2';
import { Button, useBoolean } from '@chakra-ui/react';
import Show from 'components/Show.component';
import useAudio from 'hooks/useAudio';
import { transcribeAudioBlob } from 'utils/SampleBE/pFetch';
import { VideoData } from 'types/ClientForm';
type VideoProps = {
  onSubmit: (data: VideoData) => void;
  //error?: string;
  submitText?: string;
  maxCapture?: number;
};

let videoBlob: Blob | null = null;

const VideoInput = ({ onSubmit, submitText = 'Submit' }: VideoProps) => {
  const [userTrans, setTrans] = useState('');
  const [isTransDone, setIsTransDone] = useBoolean(false);
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({
      video: true,
      onStop: (url, blob) => {
        videoBlob = blob;
      },
    });
  const [, setAudio] = useState<string | null>(null);
  const [hasRecording, setHasRecording] = useBoolean(false);

  const audio = useAudio({
    onAudioReady: (blob) => {
      const audioUrl = URL.createObjectURL(blob[0]);
      setAudio(audioUrl);
      transcribeAudioBlob(blob[0]).then((t) => {
        setTrans(() => {
          setIsTransDone.on();
          return t?.text || '';
        });
      });
    },
  });

  const onRecordRequest = () => {
    setIsTransDone.off();
    setAudio(null);
    startRecording();
    audio.startRecording();
  };

  const onRecordStopRequest = () => {
    stopRecording();
    audio.stopRecording();
    setHasRecording.on();
  };
  const NOT_RECORDING = status !== 'recording';
  return (
    <div className="flex flex-col items-center">
      <Show ifTrue={hasRecording && NOT_RECORDING}>
        <video
          className="border-2 rounded-lg"
          width={500}
          height={500}
          src={mediaBlobUrl}
          controls
        />
      </Show>
      <Show ifTrue={status == 'recording'}>
        <VideoPreview stream={previewStream} />
      </Show>

      <div className="flex mt-4 justify-center">
        <Show ifTrue={NOT_RECORDING}>
          <Button className="mr-2" onClick={onRecordRequest}>
            {hasRecording ? 'Record Again' : 'Start Recording'}
          </Button>
        </Show>

        <Show ifTrue={status == 'recording'}>
          <Button className="mr-2" onClick={onRecordStopRequest}>
            Stop Recording
          </Button>
        </Show>

        <Show ifTrue={hasRecording && NOT_RECORDING}>
          <Button
            isDisabled={!isTransDone}
            onClick={() => {
              if (videoBlob) {
                onSubmit({ video: videoBlob, transcript: userTrans });
              }
            }}
          >
            {submitText}
          </Button>
        </Show>
      </div>
    </div>
  );
};

const VideoPreview = ({ stream }: { stream: MediaStream | null }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }

  return <video className="border-2 rounded-lg" ref={videoRef} width={500} height={500} autoPlay />;
};

export default VideoInput;
