import { Link } from 'react-router-dom';

type Props = {
  className?: string;
  onClick?: () => void;
  to?: string;
};

const LogoLabel = ({ to, className, onClick }: Props) => {
  return (
    <div className={className}>
      <Link to={to || '#'}>
        <button onClick={() => onClick && onClick()}>
          <div className="flex relative items-center align-middle cursor-pointer hover:bg-white/40 w-fit p-2 rounded-md">
            <div className="h-8 w-8 bg-logo bg-contain mr-4"></div>
            <span className="text-xl"> RiseMind</span>
          </div>
        </button>
      </Link>
    </div>
  );
};

export default LogoLabel;
