/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Table,
  Thead,
  Tr,
  Th,
  chakra,
  Text,
  Box,
  TagLabel,
  Tag,
  Tooltip,
  Stack,
} from '@chakra-ui/react';
import { TriangleUpIcon, TriangleDownIcon, UpDownIcon, WarningTwoIcon } from '@chakra-ui/icons';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  RowSelectionState,
  OnChangeFn,
} from '@tanstack/react-table';
import { ResTableTheme, SingleRes } from 'types/DataSet.type';
import Show from 'components/Show.component';
import { useTranslation } from 'react-i18next';
import { useVirtualizer } from '@tanstack/react-virtual';
import logger from 'services/Logger/Pino';
const log = logger.child({ context: 'Table.Component' });
log.trace('Table component loaded');

const isStr = (myVar: unknown): boolean => typeof myVar === 'string' || myVar instanceof String;

//TODO:M: extract to utils
function getTextColorForBackground(hexColor: string): string {
  if (!/^#[0-9A-Fa-f]{6}$/.test(hexColor)) {
    //console.log('Invalid HEX color', hexColor);
    return 'black';
  }
  const r: number = parseInt(hexColor.substr(1, 2), 16);
  const g: number = parseInt(hexColor.substr(3, 2), 16);
  const b: number = parseInt(hexColor.substr(5, 2), 16);
  const brightness: number = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128 ? 'black' : 'white';
}

function getWidth(meta: any, size: number): string {
  return meta?.vw ? `${meta.vw}vw` : `${size}px`;
}

type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  colorKey: Record<string, string>;
  onSelectedRow?: (rows: RowSelectionState) => void;
  height: number;
};

function DataTable<Data extends object>({
  data,
  columns,
  colorKey,
  onSelectedRow,
  height,
}: DataTableProps<Data>) {
  const { t } = useTranslation();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const onTableRowSelection: OnChangeFn<RowSelectionState> = (rows) => {
    setRowSelection((pRow) => {
      let newRow: RowSelectionState;
      if (typeof rows == 'function') {
        newRow = rows(pRow);
      } else {
        newRow = rows;
      }
      const newRowKeys = Object.keys(newRow).reduce<Record<number, boolean>>((acc, key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[data[Number(key)].qNum] = true;
        return acc;
      }, {});
      onSelectedRow && onSelectedRow(newRowKeys);
      return newRow;
    });
  };

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      rowSelection,
    },
    onRowSelectionChange: onTableRowSelection,
  });

  const parentRef = React.useRef(null);
  const rowModels = table.getRowModel().rows;
  const rowVirtualizer = useVirtualizer({
    count: rowModels.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 10,
    overscan: 10,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
  });
  const virtualItems = rowVirtualizer.getVirtualItems();

  // log.debug(
  //   'virtual Items count: ' +
  //     virtualItems.length +
  //     ' | total :' +
  //     rowModels.length +
  //     ' : ' +
  //     rowVirtualizer.getTotalSize()
  // );
  return (
    <div
      ref={parentRef}
      className="slim-sb"
      style={{
        overflow: 'auto',
        position: 'relative',
        height: height,
      }}
    >
      <Table style={{ display: 'grid' }}>
        <Thead
          style={{ display: 'grid', position: 'sticky', top: 0, zIndex: 1 }}
          className="bg-white"
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id} style={{ display: 'flex', width: '100%' }}>
              {headerGroup.headers.map((header) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    style={{
                      display: 'flex',
                      width: getWidth(meta, header.getSize()),
                      paddingLeft: 0,
                    }}
                  >
                    {isStr(header.column.columnDef.header) &&
                      t(header.column.columnDef.header as string)}
                    <chakra.span>
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <tbody
          style={{
            //height: 36000,
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            position: 'relative', //needed for absolute positioning of rows
          }}
        >
          {virtualItems.map((virtualRow) => {
            const row = rowModels[virtualRow.index];

            return (
              <tr
                key={row.id}
                ref={(node) => rowVirtualizer.measureElement(node)}
                data-index={virtualRow.index}
                style={{
                  display: 'flex',
                  position: 'absolute',
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                  width: '100%',
                  borderBottom: '1px solid #e2e8f0',
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: any = cell.column.columnDef.meta;

                  return (
                    <td
                      key={cell.id}
                      className="whitespace-pre-line pr-3 pb-1"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: getWidth(meta, cell.column.getSize()),
                      }}
                    >
                      {meta?.isLabel ? (
                        <div className="flex max-w-[240px] flex-wrap">
                          {cell
                            .getContext()
                            .getValue<ResTableTheme[]>()
                            .map((val, i) => {
                              const bgColor = colorKey[val.theme?.toUpperCase()];
                              const textColor = getTextColorForBackground(bgColor);
                              const borderClass =
                                textColor == 'black'
                                  ? 'border-b-2 border-black'
                                  : 'border-b-2 border-white';
                              return (
                                <Tooltip
                                  bg={bgColor}
                                  key={i}
                                  color={textColor}
                                  label={
                                    <Stack>
                                      <Text className={borderClass}>{val.theme}</Text>

                                      <Text>{val.subTheme}</Text>
                                    </Stack>
                                  }
                                >
                                  <div className="flex justify-center align-middle items-center">
                                    <Show ifTrue={val.sentiment == 'negative'}>
                                      <div className="rounded-full flex items-center justify-center bg-red-100 p-1">
                                        <TriangleDownIcon color={val.color} />
                                      </div>
                                    </Show>
                                    <Show ifTrue={val.sentiment == 'positive'}>
                                      <div className="rounded-full flex items-center justify-center bg-green-100 p-1">
                                        <TriangleUpIcon color={val.color} />
                                      </div>
                                    </Show>
                                    <Show ifTrue={val.sentiment == 'neutral'}>
                                      <div className="rounded-full flex items-center justify-center bg-yellow-100 p-1">
                                        <UpDownIcon color={val.color} />
                                      </div>
                                    </Show>
                                    <Show ifTrue={val.sentiment == 'critical'}>
                                      <div className="rounded-full flex items-center justify-center bg-red-300-100 p-1">
                                        <WarningTwoIcon color={val.color} />
                                      </div>
                                    </Show>
                                    <Tag
                                      size={'sm'}
                                      variant="subtle"
                                      background={bgColor}
                                      margin={2}
                                      borderColor={val.color}
                                      borderLeftWidth={0}
                                      borderTopWidth={0}
                                      borderBottomWidth={0}
                                      borderRightWidth={0}
                                      borderLeftStyle={'solid'}
                                    >
                                      {/* <TagLeftIcon boxSize="18px" as={AiFillPlusCircle} color={'#00896f'} /> */}

                                      <TagLabel>{val.theme?.toUpperCase()}</TagLabel>
                                    </Tag>
                                  </div>
                                </Tooltip>
                              );
                            })}
                        </div>
                      ) : meta?.isResponse ? (
                        cell
                          .getContext()
                          .getValue<SingleRes[]>()
                          .map((res, i) => <SingleResView key={i} res={res} />)
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

function SingleResView({ res }: { res: SingleRes }) {
  return (
    <Box className="mt-2">
      <Show ifTrue={typeof res.q == 'string'}>
        <Text color="gray.400">{res.q}</Text>
      </Show>
      <Text>{res.a}</Text>
    </Box>
  );
}

export default DataTable;
// <Badge
//   className="m-1 cursor-default hover:opacity-80"
//   key={val}
//   background={colorKey[val]}
// >
//   {val}
// </Badge>
