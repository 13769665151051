import React, { useRef, useState, useEffect } from 'react';
import logger from 'services/Logger/Pino';
const log = logger.child({ context: 'VideoModal' });
interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (videoBlob: Blob) => void;
}

const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onClose, onConfirm }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    if (isOpen) {
      startCamera();
    } else {
      stopCamera();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const startCamera = async () => {
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      setStream(newStream);
      if (videoRef.current) {
        videoRef.current.srcObject = newStream;
      }
    } catch (error) {
      log.error('Error accessing media devices:', error);
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
    setVideoBlob(null);
    setIsRecording(false);
  };

  const startRecording = () => {
    if (stream) {
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      const chunks: BlobPart[] = [];
      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        setVideoBlob(blob);
      };
      mediaRecorder.start();
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleConfirm = () => {
    if (videoBlob) {
      onConfirm(videoBlob);
      onClose();
    }
  };

  const handleCancel = () => {
    stopCamera();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg max-w-2xl w-full">
        <div className="relative">
          {!videoBlob && <video ref={videoRef} autoPlay muted className="w-full h-64 bg-black" />}
          {videoBlob && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video src={URL.createObjectURL(videoBlob)} controls className="w-full h-64 bg-black" />
          )}
        </div>
        <div className="mt-4 flex justify-between">
          {!isRecording && !videoBlob && (
            <button
              onClick={startRecording}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Record
            </button>
          )}
          {isRecording && (
            <button
              onClick={stopRecording}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Stop
            </button>
          )}
          {videoBlob && (
            <>
              <button
                onClick={handleConfirm}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                Confirm
              </button>
              <button
                onClick={handleCancel}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
