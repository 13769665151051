import { FormControl } from '@chakra-ui/react';
import React from 'react';

type Props = { title?: string; label: string; children: React.ReactNode; isInvalid?: boolean };

const SurveySingleComposition = ({ children, title, label, isInvalid }: Props) => {
  return (
    <div className="w-full  m-auto h-1/3 flex items-center align-middle justify-around flex-col will-change-transform overflow-hidden">
      <div className="text-center w-11/12 md:w-8/12">
        <div>{title || ''}</div>
        <div className="text-2xl md:text-4xl">{label}</div>
      </div>
      <div className="w-[95%] md:w-[60%] overflow-hidden">
        {' '}
        <FormControl isInvalid={!!isInvalid}>{children} </FormControl>
      </div>
    </div>
  );
};

const ChatComposition = ({ children, title, label }: Props) => {
  return (
    <div className="w-full h-full flex items-center align-middle justify-around flex-col will-change-transform overflow-hidden">
      <div className="text-center ">
        <div>{title}</div>
        <div className="text-4xl ">{label}</div>
      </div>
      <div className="w-[95%] md:w-[60%] overflow-hidden">{children}</div>
    </div>
  );
};

export default SurveySingleComposition;
export { ChatComposition };
