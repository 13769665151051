import { firebase } from '../../services/Firebase/Firebase.service';

const SITE_VERSION = import.meta.env.VITE_SITE_VERSION;

const Footer = () => {
  const FooterLink = ({ title, link }: { title: string; link: string }) => (
    <div className="flex-1">
      <ul className="list-reset mb-1">
        <li className="mt-1 inline-block mr-2 md:block md:mr-0 z-40">
          <a href={link} className="no-underline  text-pink-50 hover:text-amber-400 cursor-pointer">
            {title}
          </a>
        </li>
      </ul>
    </div>
  );

  return (
    <footer className="bg-pink-900 z-50">
      <div className="container mx-auto px-8">
        <div className="w-full flex flex-col md:flex-row py-6">
          {/* <p className="uppercase text-gray-500 md:mb-6">About Us</p> */}
          <FooterLink title="Contact US" link="/contact" />
          <FooterLink title="About US" link="/about" />
          <FooterLink title="Term & Conditions" link="/terms" />
          <FooterLink title="Privacy Policy" link="/privacy" />
        </div>
      </div>
      <div className="absolute bottom-20 text-opacity-10 xl:text-opacity-20 right-2 text-pink-600 xl:text-amber-900  hover:text-opacity-80">
        <button
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            const noOp = () => {};
            firebase().signIn(noOp, noOp);
          }}
        >
          Rise-Mind LTD © v{SITE_VERSION}
        </button>
      </div>
    </footer>
  );
};

export default Footer;
