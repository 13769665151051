import React, { useState, useRef, useEffect } from 'react';
import { FaMicrophone, FaVideo, FaStop } from 'react-icons/fa';
import VideoModal from 'components/Chat/VideoModal.component';
import ChatMessages from '../components/Chat/Messages.component';
import { ChatMessage } from '../types/chat.types';
import { useChat } from '../services/Providers/StoreProvider';
import { observer } from 'mobx-react-lite';
import logger from 'services/Logger/Pino';
import { useParams } from 'react-router-dom';
import { transcribeAudioBlob } from '../utils/SampleBE/pFetch';
import Show from 'components/Show.component';
const log = logger.child({ context: 'ChatSurvey' });

function getLastFeedMessageIndex(messages: ChatMessage[], isLoading: boolean) {
  let prevIndex = 0;
  for (let i = 0; i < messages.length; i++) {
    const currentMessage = messages[i];
    const responseLength = currentMessage?.response?.length || 0;
    if (!(responseLength > 0) && !currentMessage.linkedResponse && !currentMessage.isNoResponse)
      return isLoading ? prevIndex : i;
    if (!currentMessage.isNoResponse) prevIndex = i;
  }
  return messages.length;
}

const ChatSurvey: React.FC = () => {
  const { cid, instanceId, userId } = useParams();
  const [message, setMessage] = useState('');
  const [isRecordingAudio, setIsRecordingAudio] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const chatInfo = useChat(cid || '', instanceId || '', userId || '');
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const currentMessageIndex = getLastFeedMessageIndex(
    chatMessages,
    chatInfo.chatMessageState == 'loading'
  );

  const feed = chatMessages.slice(0, currentMessageIndex + 1);
  const messages = chatInfo.config?.messages;
  const hideControls = messages?.[currentMessageIndex]?.type == 'select';
  // const updateMessage = (response: string, id: string) => {
  //   if (!messages) return;
  //   const currentMessageIndex = messages.findIndex((msg) => msg.id === id);
  //   if (currentMessageIndex === -1) return;
  //   messages[currentMessageIndex].response = response;
  //   chatInfo.updateMessage(messages[currentMessageIndex]);
  // };

  const configData = JSON.stringify(messages || []);
  useEffect(() => {
    if (configData) setChatMessages(chatInfo.config?.messages || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);

  const handleAudioRecording = async () => {
    if (!isRecordingAudio) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
          // const audioUrl = URL.createObjectURL(audioBlob);
          // const newMessage: ChatMessage = {
          //   id: String(Date.now()),
          //   type: 'audio',
          //   content: audioUrl,
          // };

          transcribeAudioBlob(audioBlob).then((res) => {
            if (res.text) chatInfo.addTextMessage(res.text, currentMessageIndex);
          });
        };

        mediaRecorder.start();
        setIsRecordingAudio(true);
      } catch (error) {
        log.error('Error accessing microphone:', error);
      }
    } else {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        setIsRecordingAudio(false);
      }
    }
  };

  const handleVideoRecording = () => {
    setIsVideoModalOpen(true);
  };

  const handleVideoConfirm = (videoBlob: Blob) => {
    const videoUrl = URL.createObjectURL(videoBlob);
    const newMessage: ChatMessage = {
      id: String(Date.now()),
      type: 'video',
      content: videoUrl,
    };
    setChatMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  return (
    <div className="w-screen bg-slate-700">
      <div className="flex flex-col h-screen bg-slate-100 max-w-2xl mx-auto">
        <div className="bg-gradient-to-r from-sky-950  to-sky-900 p-4">
          <h1 className="text-3xl font-semibold text-white text-center">
            {chatInfo.config?.title || ''}
          </h1>
        </div>
        <div className="flex-1 overflow-y-auto p-4">
          <ChatMessages
            messages={feed}
            updateMessage={(res, id) => chatInfo.updateMessageResponse(id, res)}
            loading={chatInfo.chatMessageState == 'loading'}
          />
        </div>
        {/* User Controls */}
        <Show ifTrue={hideControls}>
          <div className="bg-sky-950 text-white font-semibold text-xl p-4 flex items-center justify-center">
            Answer Above
          </div>
        </Show>
        <Show ifTrue={!hideControls}>
          <div className="bg-white p-4 flex items-center">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="flex-1 border rounded-l-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-300"
              placeholder="Type a message..."
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  chatInfo.addTextMessage(message, currentMessageIndex);
                  setMessage('');
                }
              }}
            />
            <button
              onClick={() => {
                chatInfo.addTextMessage(message, currentMessageIndex);
                setMessage('');
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Send
            </button>
            <button
              onClick={handleAudioRecording}
              className={`ml-2 p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-300 ${
                isRecordingAudio ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-700'
              }`}
            >
              {isRecordingAudio ? <FaStop /> : <FaMicrophone />}
            </button>
            <AudioButtonStop onStop={handleAudioRecording} show={isRecordingAudio} />
            <button
              onClick={handleVideoRecording}
              className="ml-2 p-2 rounded-full bg-gray-200 text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              <FaVideo />
            </button>
          </div>
        </Show>
        <VideoModal
          isOpen={isVideoModalOpen}
          onClose={() => setIsVideoModalOpen(false)}
          onConfirm={handleVideoConfirm}
        />
      </div>
    </div>
  );
};

function AudioButtonStop({ onStop, show }: { onStop: () => void; show: boolean }) {
  if (!show) return null;
  return (
    <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2">
      <button
        onClick={onStop}
        className="bg-red-500 hover:bg-red-700 text-white font-bold p-4 rounded-full flex items-center justify-center"
      >
        <span className="mr-2">Stop</span>
        <div className="w-4 h-4 bg-white rounded-full animate-pulse"></div>
      </button>
    </div>
  );
}

export default observer(ChatSurvey);
