import { ViewIcon, EditIcon, DeleteIcon, ExternalLinkIcon, AddIcon } from '@chakra-ui/icons';
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  IconButton,
  VStack,
  FormLabel,
  Input,
  FormErrorMessage,
  FormControl,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Textarea,
  Select,
  useBoolean,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import Show from 'components/Show.component';
import { Field, FieldArray, FieldProps, Form, Formik, FormikErrors } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { firebase } from 'services/Firebase/Firebase.service';
import logger from 'services/Logger/Pino';
import { useDashboard } from 'services/Providers/StoreProvider';
import { DashboardData, DashboardGroup, DashboardResponse } from 'types/DashboardEdit';
import { deepCopy } from 'utils/general.utils';
import { DashboardAddForm } from 'utils/validationSchemas/DashboardAdd.schema';
const log = logger.child({ context: 'DashboardAdmin' });

type ConfigTableProps = {
  onEdit: (dash: { id: string; data: DashboardResponse<string> }) => void;
  onView: (dash: { id: string; data: DashboardResponse<string> }) => void;
  onCreate: () => void;

  dashboards: Record<string, DashboardResponse<string>>;
  initialSelectedId?: string;
};

const ConfigTable = observer(
  ({ onEdit, onView, onCreate, dashboards, initialSelectedId }: ConfigTableProps) => {
    const [selectedRowId, setSelectedRowId] = useState(initialSelectedId);
    const onRowSelect = (id: string | undefined) => {
      if (id) onView({ id, data: dashboards[id] });
      setSelectedRowId(id);
    };

    useEffect(() => {
      onRowSelect(initialSelectedId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSelectedId]);

    return (
      <div>
        <TableContainer>
          <RadioGroup
            isDisabled={!initialSelectedId}
            onChange={onRowSelect}
            value={selectedRowId || undefined}
          >
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Dashboard Name</Th>
                  <Th>id</Th>
                  <Th>default</Th>
                  <Th>view</Th>
                  <Th>edit</Th>
                  <Th>delete</Th>
                  <Th>goto</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.entries(dashboards).map(([id, { name, defaultDashboard }]) => (
                  <Tr key={id}>
                    <Td>
                      <Radio value={id}></Radio>
                    </Td>
                    <Td>{name}</Td>
                    <Td>{id}</Td>
                    <Td>{defaultDashboard}</Td>
                    <Td>
                      <IconButton
                        isDisabled={!initialSelectedId}
                        isRound={true}
                        variant="ghost"
                        colorScheme="teal"
                        aria-label="Done"
                        fontSize="20px"
                        icon={<ViewIcon />}
                        onClick={() => {
                          onRowSelect(id);
                        }}
                      />
                    </Td>
                    <Td>
                      {' '}
                      <IconButton
                        isDisabled={!initialSelectedId}
                        isRound={true}
                        variant="ghost"
                        colorScheme="teal"
                        aria-label="Done"
                        fontSize="20px"
                        icon={<EditIcon />}
                        onClick={() => onEdit({ id, data: dashboards[id] })}
                      />
                    </Td>
                    <Td>
                      <Popover>
                        <PopoverTrigger>
                          <IconButton
                            isDisabled={!initialSelectedId}
                            isRound={true}
                            variant="ghost"
                            colorScheme="red"
                            aria-label="Done"
                            fontSize="20px"
                            icon={<DeleteIcon />}
                          />
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent
                            color="white"
                            bg="blue.800"
                            borderColor="blue.800"
                            padding={2}
                          >
                            <PopoverArrow />
                            <PopoverHeader className="font-bold">
                              Warning : Confirm Delete
                            </PopoverHeader>
                            <PopoverCloseButton />
                            <PopoverBody>
                              <p>
                                Are you sure you want to delete this dashboard, it cannot be undone
                              </p>
                              <Button
                                marginTop={4}
                                leftIcon={<DeleteIcon />}
                                colorScheme="red"
                                variant="solid"
                                size={'sm'}
                                onClick={() => {
                                  firebase().deleteDashboard(id);
                                }}
                              >
                                Delete
                              </Button>
                            </PopoverBody>
                          </PopoverContent>
                        </Portal>
                      </Popover>
                    </Td>
                    <Td>
                      <Link to={`/${id}/dashboard`} target="_blank" rel="noopener noreferrer">
                        <IconButton
                          isRound={true}
                          variant="ghost"
                          colorScheme="teal"
                          aria-label="Done"
                          fontSize="20px"
                          icon={<ExternalLinkIcon />}
                        />
                      </Link>
                    </Td>
                  </Tr>
                ))}
                <Show ifTrue={!initialSelectedId}>
                  <Tr background={'blue.200'}>
                    <Td>
                      <RadioGroup value="new">
                        <Radio value="new"></Radio>{' '}
                      </RadioGroup>
                    </Td>
                    <Td colSpan={7}>New Dashboard (update form to the right)</Td>
                  </Tr>
                </Show>
              </Tbody>
            </Table>
          </RadioGroup>
        </TableContainer>
        <Show ifTrue={!!initialSelectedId}>
          <Button variant={'solid'} colorScheme="blue" className="mt-4" onClick={onCreate}>
            Create New Dashboard
          </Button>
        </Show>
      </div>
    );
  }
);

type DashFormProps = {
  initialValues?: DashboardData;
  editId?: string;
  isReadonly?: boolean;
  onCancel: () => void;
  onSubmit: (id: string | undefined) => void;
};

const DashboardEditForm = observer(
  ({ initialValues, editId, isReadonly, onCancel, onSubmit }: DashFormProps) => {
    const initialTabs = initialValues?.groups.map(({ dashName }) => dashName) || ['main'];
    const [tabs, setTabs] = useState(initialTabs);
    const [tabIndex, setTabIndex] = useState(0);
    const defaultInitialValues: DashboardData = {
      name: '',
      defaultDashboard: 'main',
      groups: [{ dashName: 'main', data: '' }],
    };
    initialValues?.groups.forEach(({ data }, i) => {
      try {
        initialValues.groups[i].data = JSON.stringify(JSON.parse(data), null, 2);
      } catch (e) {
        log.error(e);
      }
    });
    useEffect(() => {
      setTabIndex(tabs.length - 1);
    }, [tabs.length]);
    return (
      <Formik
        initialValues={initialValues || defaultInitialValues}
        validationSchema={DashboardAddForm}
        onSubmit={(values: DashboardData) => {
          const valueCopy = deepCopy(values);
          valueCopy.groups.forEach(({ data }, i) => {
            valueCopy.groups[i].data = JSON.parse(data);
          });

          firebase()
            .addDashboard(valueCopy, editId)
            .then((newID) => {
              onSubmit(newID);
            });
        }}
      >
        {({ handleSubmit, errors }) => {
          const curErrGroup = (i: number) => {
            const group = errors.groups;
            const curGroup = (group as FormikErrors<DashboardGroup>[])?.[i];
            return curGroup;
          };

          return (
            <>
              <Form onSubmit={handleSubmit}>
                <FormControl isInvalid={!!errors.name} minHeight={24}>
                  <FormLabel htmlFor={`name`}>Dashboard Group Name*</FormLabel>
                  <Field
                    as={Input}
                    name={`name`}
                    type="text"
                    variant="filled"
                    isDisabled={isReadonly}
                  />
                  <FormErrorMessage className="min-h-[10px]">{errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.defaultDashboard} minHeight={24}>
                  <FormLabel htmlFor={`defaultDashboard`}>defaultDashboard</FormLabel>
                  <Field>
                    {({ field, form }: FieldProps) => (
                      <>
                        <Select
                          isDisabled={isReadonly}
                          name="defaultDashboard"
                          onChange={field.onChange} // or {form.handleChange}
                        >
                          {form.values.groups.map(
                            ({ dashName }: { dashName: string }, i: number) => (
                              <option key={i} value={dashName}>
                                {dashName}
                              </option>
                            )
                          )}
                        </Select>
                      </>
                    )}
                  </Field>
                  {/* <Field as={Input} name={`defaultDashboard`} type="text" variant="filled" /> */}
                  <FormErrorMessage className="min-h-[10px]">
                    {errors.defaultDashboard}
                  </FormErrorMessage>
                </FormControl>
                <FieldArray
                  name="groups"
                  render={(arrayHelpers) => (
                    <Tabs
                      index={tabIndex}
                      onChange={(index) => {
                        setTabIndex(index);
                      }}
                      variant="enclosed-colored"
                      colorScheme="green"
                      className="border-2 border-solid p-2 rounded-md mb-2"
                    >
                      <TabList>
                        {tabs.map((tab, i) => (
                          <Tab key={i}>{tab}</Tab>
                        ))}
                        <Show ifTrue={!isReadonly}>
                          <IconButton
                            isRound={true}
                            variant="link"
                            size={'sm'}
                            colorScheme="teal"
                            aria-label="Done"
                            icon={<AddIcon />}
                            onClick={() => {
                              arrayHelpers.push({
                                dashName: '',
                                data: '',
                              });
                              setTabs((prev) => {
                                return [...prev, 'Dash-' + (prev.length + 1)];
                              });
                            }}
                          />
                        </Show>
                      </TabList>
                      <TabPanels>
                        {tabs.map((tab, i) => (
                          <TabPanel key={i}>
                            <VStack spacing={4} align="flex-start">
                              <>
                                <FormControl isInvalid={!!curErrGroup(i)?.dashName} minHeight={24}>
                                  <FormLabel htmlFor={`groups[${i}].dashName`}>
                                    Dashboard Name*
                                  </FormLabel>
                                  <Field
                                    readOnly={isReadonly}
                                    as={Input}
                                    name={`groups[${i}].dashName`}
                                    type="text"
                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                      if (!curErrGroup(i)?.dashName) {
                                        setTabs((prev) => {
                                          const newTabs = [...prev];
                                          newTabs[i] = e.target.value;
                                          return newTabs;
                                        });
                                      }
                                    }}
                                    variant="filled"
                                  />
                                  <FormErrorMessage className="min-h-[10px]">
                                    {curErrGroup(i)?.dashName}
                                  </FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={!!curErrGroup(i)?.data} minHeight={24}>
                                  <FormLabel htmlFor="data">Dashboard JSON*</FormLabel>
                                  <Field
                                    readOnly={isReadonly}
                                    as={Textarea}
                                    name={`groups[${i}].data`}
                                    type="text"
                                    variant="filled"
                                    style={{ minHeight: '30vh' }}
                                  />
                                  <FormErrorMessage>{curErrGroup(i)?.data}</FormErrorMessage>
                                </FormControl>
                                <Show ifTrue={!isReadonly}>
                                  <Button
                                    hidden={tabs.length === 1}
                                    leftIcon={<DeleteIcon />}
                                    colorScheme="red"
                                    variant="solid"
                                    size={'sm'}
                                    onClick={() => {
                                      arrayHelpers.remove(i);
                                      setTabs((prev) => {
                                        return prev.filter((_, j) => j !== i);
                                      });
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </Show>
                              </>
                            </VStack>
                          </TabPanel>
                        ))}
                      </TabPanels>
                    </Tabs>
                  )}
                />
                <Show ifTrue={!isReadonly}>
                  <>
                    <Button
                      className="mt-4"
                      isDisabled={!!errors.groups}
                      type="submit"
                      colorScheme="blue"
                      width="fit-content"
                    >
                      {editId ? 'Update' : 'Add'}
                    </Button>
                    <Button
                      className="mt-4 ml-4"
                      isDisabled={!!errors.groups}
                      type="reset"
                      colorScheme="red"
                      width="fit-content"
                      onClick={() => {
                        onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                </Show>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
);

const DashboardAdmin = observer(() => {
  const dashboardStore = useDashboard();
  const dashboardGroups = dashboardStore.getAllDashboards();
  const initialId = Object.keys(dashboardGroups)?.[0] || undefined;
  const [isRead, setIsRead] = useBoolean(true);
  const [dash, setDash] = useState<{ id: string; data: DashboardResponse<string> }>();
  const [initial, setInitial] = useState(initialId);
  const dashToDashData = (dash: DashboardResponse<string>): DashboardData => {
    return {
      name: dash.name,
      defaultDashboard: dash.defaultDashboard || 'main',
      groups: Object.entries(dash.data).map(([dashName, data]) => {
        return { dashName, data };
      }),
    };
  };

  useEffect(() => {
    setInitial(initialId);
  }, [initialId]);

  return (
    <div className="flex h-full">
      <div className="flex-1 p-4 h-full">
        <ConfigTable
          onEdit={(data) => {
            setIsRead.off();
            setDash(data);
          }}
          onView={(data) => {
            setIsRead.on();
            setDash(data);
          }}
          onCreate={() => {
            setIsRead.off();
            setDash(undefined);
            setInitial(undefined);
          }}
          dashboards={dashboardGroups}
          initialSelectedId={initial}
        />
      </div>
      <div className="flex-1 p-4 h-full">
        <DashboardEditForm
          key={dash?.id || initial || 'new'}
          initialValues={
            dash?.data
              ? dashToDashData(dash.data)
              : initial
              ? dashToDashData(dashboardGroups[initial])
              : undefined
          }
          onCancel={() => {
            setIsRead.on();
            setInitial(initialId);
          }}
          onSubmit={(dashId) => {
            if (dashId) setInitial(dashId);
            setIsRead.on();
          }}
          editId={dash?.id || undefined}
          isReadonly={isRead}
        />
      </div>
    </div>
  );
});

// eslint-disable-next-line import/no-unused-modules
export default DashboardAdmin;
