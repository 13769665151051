import { useState, useEffect } from 'react';
import logger from 'services/Logger/Pino';

const log = logger.child({ context: 'useAudio' });
type props = { onAudioReady: (blob: Blob[]) => void };

const useAudio = ({ onAudioReady }: props) => {
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        recorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            const chunksC = [...audioChunks, e.data];
            setAudioChunks((chunks) => [...chunks, e.data]);
            onAudioReady(chunksC);
            clearAudioChunks();
          }
        };
        recorder.start();
      })
      .catch((error) => log.error('Error accessing microphone: ' + error));
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      setMediaRecorder(null);
    }
  };

  const clearAudioChunks = () => {
    setAudioChunks([]);
  };

  const getAudioBlob = () => {
    return new Blob(audioChunks, { type: 'audio/wav' });
  };

  useEffect(() => {
    return () => {
      //      Ensure that media resources are released when the component unmounts
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
    };
  }, [mediaRecorder]);

  return { startRecording, stopRecording, clearAudioChunks, getAudioBlob };
};

export default useAudio;
