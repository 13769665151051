import CardStats from 'components/Charts/CardStat.component';
import Show from 'components/Show.component';
import { useState } from 'react';
import {
  AiOutlineUser,
  AiOutlineQuestion,
  AiOutlineOrderedList,
  AiOutlineSolution,
} from 'react-icons/ai';
import { TotalCount } from 'types/DataSet.type';
type HeaderProps = {
  totalCount: TotalCount;
};
const HeaderStats = ({ totalCount }: HeaderProps) => {
  const { totalParticipants, insightCount, themeCount, totalQuestion } = totalCount;
  const [initialCount] = useState(totalCount);
  return (
    <>
      {/* Header */}
      <div className="relative bg-[#0284c7] md:pt-4 pb-32 pt-4">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total Participants"
                  statTitle={totalParticipants}
                  statArrow="up"
                  statPercent=""
                  statPercentColor="black"
                  statDescripiron={`${totalParticipants} out of ${initialCount.totalParticipants}`}
                  statIconName={<AiOutlineUser size={50} />}
                  statIconColor="bg-red-500"
                />
              </div>

              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Theme Count"
                  statTitle={themeCount}
                  statArrow=""
                  statPercent=""
                  statPercentColor="text-orange-500"
                  statDescripiron="Total theme count"
                  statIconName={<AiOutlineOrderedList size={50} />}
                  statIconColor="bg-pink-500"
                />
              </div>
              <Show ifTrue={totalQuestion > 0}>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="Total Questions"
                    statTitle={totalQuestion}
                    statArrow="down"
                    statPercent=""
                    statPercentColor="text-emerald-500"
                    statDescripiron={`Segment questions`}
                    statIconName={<AiOutlineQuestion size={50} />}
                    statIconColor="bg-orange-500"
                  />
                </div>
              </Show>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Insights (Beta)"
                  statTitle={insightCount == 0 ? '(none available)' : insightCount}
                  statArrow="up"
                  statPercent=""
                  statPercentColor="text-emerald-500"
                  statDescripiron="Insights collected (Beta)"
                  statIconName={<AiOutlineSolution size={50} />}
                  statIconColor="bg-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderStats;
