import randomColor from 'randomcolor';

type RecordType<T> = Record<string, T>;

const colorMap: Record<string, Record<string, string>> = {};
const colorGroupIndex: Record<string, number> = {};
const PASTEL_COLOR_LIST = [
  '#ea5545',
  '#f46a9b',
  '#ef9b20',
  '#bdcf32',
  '#87bc45',
  '#b33dc6',
  '#e60049',
  '#edbf33',
  '#0bb4ff',
  '#50e991',
  '#e6d800',
  '#9b19f5',
  '#ffa300',
  '#dc0ab4',
  '#b3d4ff',
  '#ede15b',
  '#00bfa0',
];

/**
 * Groups a given list by a given key
 * @param list a list to group by the given key
 * @param key to group by
 * @returns a given list grouped by a the given keys
 */
function groupBy<T, K extends keyof T>(list: T[], key: K): RecordType<T[]> {
  return list.reduce((rv: RecordType<T[]>, x: T) => {
    (rv[String(x[key])] = rv[String(x[key])] || []).push(x);
    return rv;
  }, {});
}

function getColor(
  value: string,
  luminosity: 'bright' | 'light' | 'dark' | 'random' | undefined = 'bright',
  group: string
) {
  const curColorInd = colorGroupIndex[group] || 0;
  const COLOR =
    PASTEL_COLOR_LIST.length > curColorInd
      ? PASTEL_COLOR_LIST[curColorInd]
      : randomColor({ luminosity });
  colorGroupIndex[group] = curColorInd + 1;
  if (!colorMap[group]) colorMap[group] = {};
  if (!colorMap[group][value]) colorMap[group][value] = COLOR;
  return colorMap[group][value];
}

function deepCopy<T extends object>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

function hash(str: string) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function jstr(obj: object) {
  return JSON.stringify(obj, null, 2);
}

function getUnique<T>(list: T[], comparator?: (a: T, b: T) => boolean): T[] {
  let uniqueItems: T[] = [];
  if (comparator) {
    list.forEach((item) => {
      if (!uniqueItems.some((existingItem) => comparator(existingItem, item))) {
        uniqueItems.push(item);
      }
    });
  } else {
    uniqueItems = [...new Set(list)];
  }
  return uniqueItems;
}

function uuid() {
  if (!self || !self.crypto)
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: string) =>
      (
        Number(c) ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))
      ).toString(16)
    );
  else return self.crypto.randomUUID();
}

export { groupBy, getColor, deepCopy, hash, jstr, getUnique, uuid };
