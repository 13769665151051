// Email link sign in info : https://firebase.google.com/docs/auth/web/email-link-auth?hl=en&authuser=0&_gl=1*1g2qkaa*_ga*MTA4MDk3Mjc3Ni4xNjk5MzY4Mzg4*_ga_CW55HF8NVT*MTY5OTM2ODM4Ny4xLjEuMTY5OTM2OTA5MC41OS4wLjA.
// 27 ,323,  5/11
import { initializeApp, FirebaseApp, FirebaseOptions } from 'firebase/app';
import {
  ref as sRef,
  uploadBytes,
  getDownloadURL,
  UploadMetadata,
  getStorage,
  FirebaseStorage,
} from 'firebase/storage';
import {
  getDatabase,
  Database,
  ref,
  set,
  get,
  DataSnapshot,
  onValue,
  remove,
} from 'firebase/database';
import {
  getAuth,
  Auth,
  GoogleAuthProvider,
  signInWithPopup,
  //signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { DemoRequest } from 'types/Demo.type';
import { hash, uuid } from 'utils/general.utils';
import * as Sentry from '@sentry/react';
import { RawData, Response } from 'types/DataSet.type';
import { UserInfo, UserMeta } from 'types/Auth.type';
import logger from 'services/Logger/Pino';
import { DashboardData, DashboardResponse } from 'types/DashboardEdit';
import { ClientFormDef } from 'types/ClientForm';
import { ChatConfig } from '../../types/chat.types';
const log = logger.child({ context: 'Firebase' });
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: 'select_account',
});

interface UploadAudioParams {
  audioBlob: Blob;
  cid: string;
  instanceId: string;
  userId: string;
  fileName: string;
}

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAFlJZYNleQDLq8O9PR6GeXinheHozK2Z4',
  authDomain: 'rise-mind.firebaseapp.com',
  projectId: 'rise-mind',
  storageBucket: 'rise-mind.appspot.com',
  messagingSenderId: '769604240312',
  appId: '1:769604240312:web:37398eb289171d1d99054d',
  measurementId: 'G-PQGFKHJX5Y',
  databaseURL: 'https://rise-mind-default-rtdb.firebaseio.com',
};
const IS_DEVELOPMENT = import.meta.env.DEV;

const sentrySetUser = (email?: string | undefined) => {
  if (!IS_DEVELOPMENT) {
    Sentry.setUser({ email: email });
  }
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

//const app = initializeApp(firebaseConfig);

const userChatConfig = (cid: string, instanceId: string, userId: string) =>
  `chat/${cid}/instances/${instanceId}/response/${userId}`;

class Firebase {
  app: FirebaseApp;
  auth: Auth;
  db: Database;
  user: UserInfo | undefined;
  dashboardSnapshot: DataSnapshot | null = null;
  AuthChangeHandlerList: ((user: UserInfo | null) => void)[] = [];
  dashboardListHandlerList: ((data: Record<string, DashboardResponse<string>>) => void)[] = [];
  storage: FirebaseStorage;
  constructor() {
    log.trace('Initializing Firebase');
    this.app = app;
    this.db = getDatabase();
    this.auth = getAuth();
    this.storage = getStorage();
    onAuthStateChanged(this.auth, (user) => {
      log.trace('Auth state changed' + JSON.stringify(user));
      if (user) {
        this.getUserInfo(user.uid).then((meta) => {
          this.user = {
            displayName: user?.displayName || 'N/A',
            email: user?.email || 'N/A',
            meta: meta || { isAdmin: false },
          };
          this.AuthChangeHandlerList.forEach((handler) => handler(this.user || null));
          sentrySetUser(this?.user?.email);
        });
      } else {
        this.user = undefined;
        this.AuthChangeHandlerList.forEach((handler) => handler(null));
        sentrySetUser();
      }
    });
  }

  registerAuthChangeHandler(handler: (user: UserInfo | null) => void) {
    this.AuthChangeHandlerList.push(handler);
  }

  signInEmail(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  signIn(onComplete: (uid: string) => void, onError: (error: string) => void) {
    signInWithPopup(this.auth, provider)
      .then((result) => {
        // this.user = {
        //   credentials: result,
        //   oauthCredentials: GoogleAuthProvider.credentialFromResult(result),
        //   displayName: result.user.displayName || 'N/A',
        //   email: result.user.email || 'N/A',
        // };
        // console.log('TEST:::', this.user);
        onComplete(result.user.uid);
      })
      .catch((error) => {
        // Handle Errors here.
        //const errorCode = error.code;
        const errorMessage = error.message;
        onError(errorMessage);
        // The email of the user's account used.
        //const email = error.customData.email;
        // The AuthCredential type that was used.
        //const credential = GoogleAuthProvider.credentialFromError(error);
      });
  }

  // test(signIn = true) {
  //   if (!signIn)
  //     signOut(this.auth).then(() => {
  //       console.log('OUT');
  //     });
  //   if (signIn)
  //     signInWithPopup(this.auth, provider)
  //       .then(() => {
  //         // this.user = {
  //         //   credentials: result,
  //         //   oauthCredentials: GoogleAuthProvider.credentialFromResult(result),
  //         //   displayName: result.user.displayName || 'N/A',
  //         //   email: result.user.email || 'N/A',
  //         // };
  //         //console.log('TEST:::', this.user);
  //       })
  //       .catch((error) => {
  //         // Handle Errors here.
  //         const errorCode = error.code;
  //         const errorMessage = error.message;
  //         // The email of the user's account used.
  //         const email = error.customData.email;
  //         // The AuthCredential type that was used.
  //         const credential = GoogleAuthProvider.credentialFromError(error);
  //         console.log(`${errorCode}:::[${errorMessage}]:::[${email}]:::[${credential}]`);
  //       });
  // }

  // dbtest() {
  //   set(ref(this.db, 'users/aaa'), {
  //     username: 'mike2',
  //     email: 'AAA',
  //   })
  //     .then(() => {
  //       console.log('DONE');
  //     })
  //     .catch((e) => console.log('E:', e));
  // }

  requestDemo(request: DemoRequest) {
    //const randomKey = this.db.ref().push().key
    const createdAt = new Date().toISOString();
    return set(ref(this.db, `demoRequest/${hash(request.email)}`), {
      ...request,
      createdAt,
    });
  }

  addDashboard(dashboardData: DashboardData, dashId?: string) {
    if (!dashId) dashId = uuid();
    return set(ref(this.db, `dashboard/${dashId}`), {
      defaultDashboard: dashboardData.defaultDashboard,
      name: dashboardData.name,
      data: dashboardData.groups.reduce((acc, cur) => {
        acc[cur.dashName] = JSON.stringify(cur.data);
        return acc;
      }, {} as Record<string, string>),
    }).then(() => dashId);
  }

  deleteDashboard(dashId: string) {
    return remove(ref(this.db, `dashboard/${dashId}`));
  }

  getUserInfo(uid: string): Promise<UserMeta | null> {
    return get(ref(this.db, `users/${uid}`)).then((data) => {
      const val = data.val();
      if (!val) return null;
      return val as UserMeta;
    });
  }

  registerDashboardListHandler(handler: (data: Record<string, DashboardResponse<string>>) => void) {
    this.dashboardListHandlerList.push(handler);
    if (this.dashboardListHandlerList.length === 1) {
      onValue(ref(this.db, `dashboard`), (sanpshot) => {
        const val = sanpshot.val() as Record<string, DashboardResponse<string>>;
        if (!val) return;
        this.dashboardListHandlerList.forEach((handler) => handler(val));
      });
    }
  }

  async getSurveyConfig(cid: string, instanceId: string): Promise<ClientFormDef | null> {
    try {
      const surveyConfigSnapshot = await get(
        ref(this.db, `survey/${cid}/instances/${instanceId}/config`)
      );
      const val = surveyConfigSnapshot.val();
      if (!val) return null;
      return JSON.parse(val) as ClientFormDef;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      log.error('Error getting survey config data: ' + e.message);
      throw e.message;
    }
  }

  async getChatConfig(cid: string, instanceId: string, userId: string): Promise<ChatConfig | null> {
    try {
      const userConfig = await get(ref(this.db, userChatConfig(cid, instanceId, userId)));
      if (userConfig.exists()) return JSON.parse(userConfig.val()) as ChatConfig;
      const chatConfigSnapshot = await get(
        ref(this.db, `chat/${cid}/instances/${instanceId}/config`)
      );
      const val = chatConfigSnapshot.val();
      if (!val) return null;

      const chatConfigObj = JSON.parse(val) as ChatConfig;
      this.updateChatConfig(chatConfigObj, userId);
      return chatConfigObj;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      log.error('Error getting chat config data: ' + e.message);
      throw e.message;
    }
  }

  async updateChatConfig(config: ChatConfig, userId: string) {
    try {
      await set(
        ref(this.db, userChatConfig(config.cid, config.instanceId, userId)),
        JSON.stringify(config)
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      log.error('Error updating chat config: ' + e.message);
      throw e.message;
    }
  }

  async updateSurveyResponse(cid: string, instanceId: string, userId: string, response: unknown) {
    try {
      await set(
        ref(this.db, `survey/${cid}/instances/${instanceId}/responses/${userId}`),
        JSON.stringify(response)
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      log.error('Error updating survey response: ' + e.message);
      throw e.message;
    }
  }

  addResponseKeys(responses: Response[]) {
    return responses.map((response, i) => {
      response.id = String(i);
      return response;
    });
  }

  async getDashboardsData(cid: string): Promise<DashboardResponse<RawData> | null> {
    if (!this.dashboardSnapshot) {
      try {
        this.dashboardSnapshot = await get(ref(this.db, `dashboard/${cid}`)).then((data) => {
          const val = data.val();
          if (!val) return null;
          return data;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        log.error('Error getting dashboard data: ' + e.message);
        throw e.message;
      }
    }
    if (!this.dashboardSnapshot) return null;

    const val = this.dashboardSnapshot.val() as DashboardResponse<string>;
    const res: DashboardResponse<RawData> = {
      name: val.name,
      data: {},
      defaultDashboard: val.defaultDashboard,
    };
    Object.entries(val.data).forEach(([key, value]) => {
      res.data[key] = JSON.parse(value) as RawData;
      res.data[key].responses = this.addResponseKeys(res.data[key].responses);
    });
    return res;
  }

  // doSignInWithEmailAndPassword = (email, password) =>
  //   this.auth. //signInWithEmailAndPassword(email, password);

  // doSignOut = () => this.auth.signOut();

  // user = (uid) => this.db.ref(`users/${uid}`);

  // users = () => this.db.ref('users');

  // sol = (uid, ex) => this.db.ref(`sol/${uid}/${ex}`);
  // allSol = (uid) => this.db.ref(`sol/${uid}`);

  // res = (uid) => this.db.ref(`res/${uid}`);
  // scores = () => this.db.ref(`scores`);

  async uploadAudio({
    audioBlob,
    cid,
    instanceId,
    userId,
    fileName,
  }: UploadAudioParams): Promise<string> {
    // Create a unique file path using the IDs

    const filePath = `audio/${cid}/${instanceId}/${userId}/${fileName}`;
    const storageRef = sRef(this.storage, filePath);

    // Create metadata including the IDs
    const metadata: UploadMetadata = {
      contentType: audioBlob.type, // Ensure the correct content type is set
      customMetadata: {
        cid,
        instanceId,
        userId,
      },
    };

    try {
      // Upload the file with metadata
      const snapshot = await uploadBytes(storageRef, audioBlob, metadata);

      // Get the download URL
      const downloadURL = await getDownloadURL(snapshot.ref);

      return downloadURL;
    } catch (error) {
      log.error('Error uploading audio:', error);
      throw error; // Re-throw the error so the caller can handle it
    }
  }
}

let fbInstance: Firebase = new Firebase();

function firebase() {
  if (!fbInstance) fbInstance = new Firebase();
  return fbInstance;
}

export { firebase };
