import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import { firebase } from '../services/Firebase/Firebase.service';
import { ChatConfig, ChatMessage, MessageState, MessageType } from '../types/chat.types';
import { chatFollowups } from '../utils/SampleBE/pFetch';
import { EXIT_MARKER } from '../utils/consts/chat.consts';

class ChatStore {
  config: ChatConfig | null = null;
  cid = '';
  instanceId = '';
  userId = '';
  chatMessageState: MessageState = 'idle';

  constructor(public root: RootStore) {
    makeAutoObservable(this);
  }

  async initConfig(cid: string, instanceId: string, userId: string) {
    if (cid === this.cid && instanceId === this.instanceId) return;
    this.cid = cid;
    this.instanceId = instanceId;
    this.userId = userId;
    const config = await firebase().getChatConfig(cid, instanceId, userId);
    runInAction(() => {
      this.config = config;
    });
  }

  async updateMessage(message: ChatMessage, messageIdx?: number) {
    if (!this.config) return;
    const updatedMessageIdx =
      messageIdx || this.config.messages.findIndex((m) => m.id === message.id);
    if (updatedMessageIdx === undefined || !this.config) return;
    this.config.messages[updatedMessageIdx] = message;
    await firebase().updateChatConfig(this.config, this.userId);
  }

  async updateMessageResponse(messageId: string, response: string) {
    if (!this.config) return;
    const updatedMessageIdx = this.config.messages.findIndex((m) => m.id === messageId);
    if (updatedMessageIdx === undefined) return;
    const msgCopy = { ...this.config.messages[updatedMessageIdx] };
    msgCopy.response = response;
    this.updateMessage(msgCopy, updatedMessageIdx);
  }

  async addContextQuestion(contextId: string, question: string, type: MessageType) {
    if (!this.config) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    let lastContextMessageId = this.config.messages.findLastIndex(
      (m: ChatMessage) => m.context === contextId
    );
    if (lastContextMessageId === -1) {
      lastContextMessageId = this.config.messages.findIndex((m) => m.id === contextId);
    }
    if (lastContextMessageId === -1) return;
    const newMessage: ChatMessage = {
      id: String(new Date().getTime()),
      content: question,
      type,
      context: contextId,
    };
    const messagesCopy = [...this.config.messages];
    messagesCopy.splice(lastContextMessageId + 1, 0, newMessage);
    this.config.messages = messagesCopy;
    await firebase().updateChatConfig(this.config, this.userId);
  }

  addTextMessage(responseText: string, currentMessageIndex: number) {
    if (!this.config) return;
    const messages = this.config.messages;
    const trimmedMessage = responseText.trim();
    if (!messages || !currentMessageIndex || !trimmedMessage) return;
    const currentMessage = messages[currentMessageIndex];
    currentMessage.response = trimmedMessage;
    if (currentMessage.type == 'query') {
      if (!this.config) return;
      let originalQuestion = currentMessage;
      if (currentMessage.context)
        originalQuestion =
          messages.find((msg) => msg.id === currentMessage.context) || currentMessage;
      const contextMessages = [
        originalQuestion,
        ...messages.filter((msg) => msg.context === originalQuestion.id),
      ];
      this.chatMessageState = 'loading';
      chatFollowups(this.config, contextMessages, EXIT_MARKER)
        .then((res) => {
          if (res) {
            this.addContextQuestion(originalQuestion.id, res, 'query');
          }
        })
        .finally(() => {
          runInAction(() => {
            this.chatMessageState = 'done';
          });
        });
    }
    if (currentMessage) {
      this.updateMessageResponse(currentMessage.id, responseText);
    }
  }
}

export default ChatStore;
