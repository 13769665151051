import { Button, Input, useBoolean } from '@chakra-ui/react';
import BouncingDotsLoader from 'components/Loader/BouncingDotsLoader.component';
import Show from 'components/Show.component';
import OpenAI from 'openai';
import { useEffect, useRef, useState } from 'react';
import { ChatMessage } from 'types/ClientForm';
import { promptGenerator } from 'utils/SampleBE/pFetch';
import { CHAT_DEFAULT_CLOSING_TEXT } from 'utils/consts/Site.consts';
import { getTranscript } from 'utils/prompt.utils';

type MessageProps = { text: string; userTag?: string; loading?: boolean };
type CMessages = OpenAI.Chat.Completions.ChatCompletionMessage;
type Props = {
  formId: string;
  instanceId: string;
  maxQuestions: number;
  closingMessage?: string;
  submitText?: string;
  questions: string[];
  onSubmit: (selected: ChatMessage[]) => void;
};

const Message = ({ text, userTag, loading }: MessageProps) => {
  return (
    <Show
      ifTrue={!!userTag}
      elseRender={
        <div className="col-start-1 col-end-8 p-3 rounded-lg">
          <div className="flex flex-row items-center">
            <div className="flex items-center justify-center h-10 w-10 rounded-full flex-shrink-0 bg-teal-500 bg-logo bg-contain"></div>
            <div className="relative ml-3 text-sm md:text-xl bg-white py-2 px-4 shadow rounded-xl">
              {/* <div>{text}</div> */}
              <Show ifTrue={!loading} elseRender={<BouncingDotsLoader />}>
                <div>{text}</div>
              </Show>
            </div>
          </div>
        </div>
      }
    >
      <div className="col-start-6 col-end-13 p-3 rounded-lg">
        <div className="flex items-center justify-start flex-row-reverse">
          <div className="flex items-center justify-center h-10 w-10 rounded-full bg-user-tag flex-shrink-0 text-white ">
            {userTag}
          </div>
          <div className="relative mr-3 text-sm md:text-xl bg-indigo-100 py-2 px-4 shadow rounded-xl">
            <div>{text}</div>
          </div>
        </div>
      </div>
    </Show>
  );
};

const ChatInput = ({
  formId,
  instanceId,
  maxQuestions,
  closingMessage,
  submitText,
  questions,
  onSubmit,
}: Props) => {
  const initialMessage: CMessages = {
    role: 'assistant',
    content: questions[0],
  };
  const [qInfo, setQInfo] = useState({ qNum: 0, messageIdx: 0 });
  const [userText, setUserText] = useState('');
  const [messages, setMessages] = useState<CMessages[]>([initialMessage]);
  const [isSendDisabled, setDisabled] = useBoolean(false);
  const [isDone, setDone] = useBoolean(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLInputElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  //TODO: extract to store
  const isDoneCheck = (answer: string | false): string => {
    const askedMessageCount = messages.filter(({ role }) => role == 'assistant').length;
    let response = answer;
    const finished = !response && qInfo.qNum + 1 == questions.length;
    if (askedMessageCount >= maxQuestions || finished) {
      setDone.on();
      response = closingMessage || CHAT_DEFAULT_CLOSING_TEXT;
    } else if (!response) {
      const qNum = qInfo.qNum;
      setQInfo(({ qNum }) => ({ messageIdx: messages.length, qNum: qNum + 1 }));
      return questions[qNum + 1];
    }
    return response;
  };

  const onChatSend = () => {
    setDisabled.on();
    const content = userText;
    // setMessages((prev) => {
    //   setUserText('');
    //   return [...prev, { role: 'user', content }];
    // });

    const transcript = getTranscript(
      messages.slice(qInfo.messageIdx == 0 ? qInfo.messageIdx : qInfo.messageIdx + 1),
      content
    );
    const res = promptGenerator(formId, instanceId, transcript);
    res.then((answer) => {
      setMessages((prev) => {
        const content = isDoneCheck(answer);
        setDisabled.off();
        setTimeout(() => inputRef.current?.focus(), 20);
        return [...prev, { role: 'assistant', content }];
      });
    });
  };
  return (
    <div className="flex h-full antialiased text-gray-800 align-middle justify-center">
      <div className="flex flex-row h-[80vh] w-[100vw] md:w-[50vw] overflow-x-hidden">
        <div className="flex flex-col flex-auto h-full p-0 md:p-6 ">
          <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-chat-2 h-full p-4">
            <div className="flex flex-col h-full overflow-x-auto mb-4 custom-scroll">
              <div className="flex flex-col h-full">
                <div className="grid grid-cols-12 gap-y-2">
                  {messages.map(({ role, content }, i) => (
                    <Message
                      key={i}
                      text={content || ''}
                      userTag={role == 'assistant' ? 'U' : undefined}
                    />
                  ))}
                  <Show ifTrue={isSendDisabled}>
                    <Message loading={true} text={''} />
                  </Show>
                  <div ref={messagesEndRef} />
                </div>
              </div>
            </div>
            <div
              className={`flex flex-row items-center h-16 rounded-xl w-full px-4 ${
                !isDone ? 'bg-white' : 'bg-blue-200'
              }`}
            >
              <Show
                ifTrue={!isDone}
                elseRender={
                  <div className="w-full flex justify-center">
                    <Button
                      colorScheme="blue"
                      minWidth={'20%'}
                      size={'lg'}
                      onClick={() => {
                        onSubmit(messages as ChatMessage[]);
                      }}
                    >
                      {submitText}
                    </Button>
                  </div>
                }
              >
                <>
                  <div className="flex-grow">
                    <div className="relative w-full">
                      {/* <input
                    type="text"
                    className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                  /> */}
                      <Input
                        borderColor="gray.100"
                        focusBorderColor="gray.200"
                        onChange={(e) => setUserText(e.target.value)}
                        value={userText}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter' && !isSendDisabled) onChatSend();
                        }}
                        ref={inputRef}
                        isDisabled={isSendDisabled}
                      />
                    </div>
                  </div>
                  <div className="ml-4">
                    <Button colorScheme="blue" isDisabled={isSendDisabled} onClick={onChatSend}>
                      <span>Send</span>
                      <span className="ml-2">
                        <svg
                          className="w-4 h-4 transform rotate-45 -mt-px"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                          ></path>
                        </svg>
                      </span>
                    </Button>
                  </div>
                </>
              </Show>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const AudioMessage = () => {
//   return (
//     <div className="col-start-1 col-end-8 p-3 rounded-lg">
//       <div className="flex flex-row items-center">
//         <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
//           A
//         </div>
//         <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
//           <div className="flex flex-row items-center">
//             <button className="flex items-center justify-center bg-indigo-600 hover:bg-indigo-800 rounded-full h-8 w-10">
//               <svg
//                 className="w-6 h-6 text-white"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="1.5"
//                   d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
//                 ></path>
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="1.5"
//                   d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//                 ></path>
//               </svg>
//             </button>
//             <div className="flex flex-row items-center space-x-px ml-4">
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-4 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-10 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-10 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-12 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-10 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-6 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-5 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-4 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-3 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-10 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-10 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-1 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-1 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-8 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-2 w-1 bg-gray-500 rounded-lg"></div>
//               <div className="h-4 w-1 bg-gray-500 rounded-lg"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

/* <button className="flex items-center justify-center text-gray-400 hover:text-gray-600">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                    ></path>
                  </svg>
                </button> */

export default ChatInput;
