type Props = {
  ifTrue: boolean;
  elseRender?: JSX.Element;
  children: JSX.Element;
};

const Show = ({ ifTrue, elseRender, children }: Props): JSX.Element => {
  return ifTrue ? children : elseRender || <></>;
};

export default Show;
