/* eslint-disable import/no-unused-modules */
/* eslint-disable @typescript-eslint/no-explicit-any */
//import localforage from 'localforage';

import OpenAI from 'openai';
import { ClientFormDef, Page, UserData, VideoData } from 'types/ClientForm';
//import { sampleFormDef } from './SampleFormDefinition';
import { firebase } from 'services/Firebase/Firebase.service';
import { ChatConfig, ChatMessage } from '../../types/chat.types';
//let localDB: Record<string, ClientFormDef> | null = null;
const systemRoleContent = `You are a helpful assistant`;
const API_KEY = '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';
//const CONFIG_MAP_KEY = 'config';

const openai = new OpenAI({
  apiKey: API_KEY,
  dangerouslyAllowBrowser: true,
});

function buildPromptFromTemplate(template: string, values: Record<string, string>): string {
  const regex = /\{\{([^}]+)\}\}/g;

  return template.replace(regex, (match, key) => {
    return values[key] || '';
  });
}

function replacePlaceholders(template: string, values: { [key: string]: string }): string {
  return template.replace(/{(.*?)}/g, (_, key) => values[key] || `{${key}}`);
}

async function chatFollowups(config: ChatConfig, messages: ChatMessage[], exitMarker: string) {
  const systemMessage: OpenAI.Chat.Completions.ChatCompletionMessageParam = {
    role: 'system',
    content: replacePlaceholders(config.systemPrompt, {
      exit: exitMarker,
      content: messages[0].content,
      requirement: messages[0].requirement || 'answer the question so we have decent input to use',
    }),
  };

  // const prompt: OpenAI.Chat.Completions.ChatCompletionMessageParam = {
  //   role: 'user',
  //   content: replacePlaceholders(config.promptTemplate, {
  //     exit: exitMarker,
  //     content: messages[0].content,
  //     requirement: messages[0].requirement || 'answer the question so we have decent input to use',
  //   }),
  // };

  //console.log('PROMPT::::', prompt.content, JSON.parse(JSON.stringify(messages)));

  // Prepare the conversation messages
  let chatMessages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = messages.flatMap(
    (msg) => [
      { role: 'assistant', content: msg.content || '' },
      { role: 'user', content: msg.response || '' },
    ]
  );

  chatMessages = [systemMessage, ...chatMessages];
  //console.log('---SYSTEM---', systemMessage.content);
  //console.log('------', JSON.parse(JSON.stringify(chatMessages)));
  const chatCompletion = await openai.chat.completions.create({
    messages: chatMessages,
    model: config.model,
    temperature: config.temperature || 0,
  });

  const res = chatCompletion.choices.pop()?.message?.content;
  if (!res || res.toLocaleLowerCase().includes(exitMarker)) return false;
  return res;
}

async function promptGenerator(formId: string, instanceId: string, transcript: string) {
  const config = await getConfig(formId, instanceId);
  if (!config) return false;
  const { defaultPrompt, description, goal } = config;
  const prompt = buildPromptFromTemplate(defaultPrompt, { description, goal, transcript });

  //console.log('Prompt: ', prompt);
  const chatCompletion = await openai.chat.completions.create({
    messages: [{ role: 'user', content: prompt }],
    model: config.model,
    temperature: 0,
  });
  const res = chatCompletion.choices.pop()?.message?.content;
  //console.log('prompt result:', res);
  if (!res || res.toLocaleLowerCase().startsWith('no')) return false;
  return res;
}

async function branchPrediction(
  currentConfig: ClientFormDef,
  page: number,
  answer: Record<string, UserData>
) {
  //console.log('PNUNM', page, toJS(currentConfig));
  const { components } = currentConfig.pages[page];
  let userQuestions = '';
  let userAnswers = '';

  components.forEach(({ id, label }) => {
    userQuestions += label + ' ';
    //console.log('ANSWER', id, answer);
    let ansById = answer?.[id];
    if ((ansById as VideoData)?.transcript) {
      ansById = (ansById as VideoData)?.transcript;
    }
    userAnswers += String(ansById || '') + ' ';
  });

  const question = `given the following context of a focus group: ${currentConfig.description}.\nassume i asked the following question : ${userQuestions}.\nand a someone answered the following:${userAnswers}.\nwhat would be a good follow up question, only write question, if you think no followup question is needed, simply write 'no'`;

  //console.log('BEFORE');
  const chatCompletion = await openai.chat.completions.create({
    messages: [{ role: 'user', content: question }],
    model: currentConfig.model,
    temperature: 0,
  });
  //console.log('C:', question, chatCompletion);
  return chatCompletion.choices.pop()?.message?.content;
}

async function getConfig(formId: string, instanceId: string) {
  // if (!localDB) {
  //   localDB = (await localforage.getItem(CONFIG_MAP_KEY)) as Record<string, ClientFormDef>;
  // }

  // return localDB?.[formId];
  const config = await firebase().getSurveyConfig(formId, instanceId);
  return config;
}

async function chat(
  formId: string,
  instanceId: string,
  chatHistory: OpenAI.Chat.Completions.ChatCompletionMessage[],
  goals: string[]
) {
  const config = await getConfig(formId, instanceId);
  if (!config) throw new Error(`Could not find config with id ${formId}`);
  const description = config.description || 'not available';
  const plS = goals.length > 1;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const systemContent = `${systemRoleContent}. the following is the context of this session: "${description}"`;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const instructions = `your objective is to ask me questions to answer the following quer${
    plS ? 'ies' : 'y'
  }: ${goals.join(', also ')}.
if at any point i have given an answer that satisfies the given quer${
    plS ? 'ies' : 'y'
  }  (even a little), simply respond with 'done' (don't add any other text).
  Also try to make the conversation natural`;

  const messages: OpenAI.Chat.Completions.ChatCompletionMessage[] = [
    // { role: 'assistant', content: systemContent },
    // { role: 'user', content: instructions },
    ...chatHistory,
  ];
  //console.log('MMM:', messages);
  const chatCompletion = await openai.chat.completions.create({
    messages,
    model: config.model,
    temperature: 0,
  });
  //console.log('C:', JSON.stringify(chatCompletion, null, 2));
  return chatCompletion.choices.pop()?.message?.content;
}

async function getUpdatedConfig(
  formId: string,
  instanceId: string,
  page: number,
  answer: Record<string, UserData>
): Promise<ClientFormDef> {
  const currentConfig = await getConfig(formId, instanceId);
  if (!currentConfig) throw new Error(`Could not find config with id ${formId}`);
  const newQuestion = await branchPrediction(currentConfig, page, answer);
  const newConfig = { ...currentConfig };
  const newPage: Page = {
    title: `Question ${page}.2`,
    components: [
      {
        id: 'followup' + Math.random(), //random id
        type: 'input',
        label: newQuestion || '',
        settings: {
          validation: {},
          placeholder: 'Write here...',
          multiLine: true,
          disablePaste: true,
        },
      },
    ],
  };
  //console.log('NEW::Q::::', newQuestion);
  if (!newQuestion || newQuestion == 'no') return currentConfig;
  newConfig.pages.splice(page + 1, 0, newPage);
  for (let i = 0; i < newConfig.pages.length; i++) {
    newConfig.pages[i].title = `Question ${i + 1}`;
  }

  return newConfig;
}

function getRandomNumberBetween(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const pFetch = (resource: string, params: any): Promise<ClientFormDef> => {
  const NET_TIME_SIM = resource == 'sync' ? 0 : getRandomNumberBetween(10, 200);
  return new Promise((res, rej) => {
    setTimeout(() => {
      if (resource == 'sync') {
        //console.log('params', params);
        getUpdatedConfig(
          params.formId,
          params.instanceId,
          params.page,
          params.answer as Record<string, UserData>
        ).then((ans) => {
          res(ans);
        });
      } else {
        rej('No such resource exists');
      }
    }, NET_TIME_SIM);
  });
};

const getFollowupConfig = (
  formId: string,
  instanceId: string,
  page: number,
  answer: Record<string, UserData>
): Promise<ClientFormDef> => {
  return pFetch('sync', { formId, instanceId, page, answer });
};

async function transcribeAudioBlob(audioBlob: Blob) {
  const body = new FormData();
  const file = new File([audioBlob], 'audio.wav');
  body.append('file', file);
  body.append('', '\\');
  body.append('model', 'whisper-1');

  const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
    body,
    headers: {
      Authorization: `Bearer ${API_KEY}`,
      // 'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
  });

  const transcription = await response.json();
  return transcription;
}

//////////////////////////////////////

// systemPrompt:
//   'you are a marketing research company conducting a survey on smartphone users, sound natural and speak as if you are a human, light hearted and friendly',
// promptTemplate:
//   'i want you to figure out if following question: "{content}" was answered, use the previous context given and check if the requirement for this question which is: "{requirement}" was met. if it is, you should return only the word "{exit}", if not ask a followup to help fullfil requirement.',

export { pFetch, getFollowupConfig, chat, promptGenerator, transcribeAudioBlob, chatFollowups };
