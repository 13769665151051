import logo from 'assets/Logo.png';
import { Image } from '@chakra-ui/react';
import './LogoLoader.style.css';

const LogoLoader = () => {
  return (
    <div className="circleBase pulse">
      <Image width={100} height={100} objectFit={'cover'} src={logo}></Image>
    </div>
  );
};

export default LogoLoader;
