import {
  useCheckbox,
  Box,
  useRadioGroup,
  useCheckboxGroup,
  HStack,
  Button,
} from '@chakra-ui/react';
import Show from 'components/Show.component';
import { useState } from 'react';

type Props = {
  hideSubmit?: boolean;
  submitText?: string;
  options: string[];
  defaultValue?: string;
  submitOnSelect?: boolean;
  onClick?: () => void;
  onSubmit: (selected: string) => void;
  multiSelect?: boolean;
  requireSelection?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Card = (props: any) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" onClick={props.onClick}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        borderColor={'blue.400'}
        _checked={{
          bg: 'blue.600',
          color: 'white',
          borderColor: 'teal.600',
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const ButtonSelection = ({
  options,
  defaultValue,
  onSubmit,
  submitText = 'Submit',
  submitOnSelect,
  hideSubmit,
  multiSelect = false,
  requireSelection = false,
}: Props) => {
  const [selected, setSelected] = useState<string | string[]>(
    defaultValue ? (multiSelect ? defaultValue?.split(';') : defaultValue) : []
  );

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'selection',
    defaultValue,
    onChange: (value) => !multiSelect && setSelected(value),
  });

  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue: multiSelect ? defaultValue?.split(';') : undefined,
    onChange: (values) => multiSelect && setSelected(values.map(String)),
  });

  const group = getRootProps();

  const handleSelect = (value: string) => {
    if (multiSelect) {
      setSelected((prevSelected) => {
        if (Array.isArray(prevSelected)) {
          const newSelected = prevSelected.includes(value)
            ? requireSelection && prevSelected.length === 1
              ? prevSelected
              : prevSelected.filter((item) => item !== value)
            : [...prevSelected, value];
          if (submitOnSelect) onSubmit(newSelected.join(';'));
          return newSelected;
        }
        return [value];
      });
    } else {
      setSelected(value);
      if (submitOnSelect) onSubmit(value);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center max-w-screen-xl">
      <HStack
        {...group}
        justifyContent={'center'}
        className="mb-8"
        overflowWrap={'anywhere'}
        wrap={'wrap'}
      >
        {options.map((value) => {
          const props = multiSelect ? getCheckboxProps({ value }) : getRadioProps({ value });
          return (
            <Card key={value} onClick={() => handleSelect(value)} {...props}>
              {value}
            </Card>
          );
        })}
      </HStack>
      <Show
        ifTrue={
          (!multiSelect && !!selected && !hideSubmit) ||
          (multiSelect &&
            Array.isArray(selected) &&
            selected.length > 0 &&
            !submitOnSelect &&
            !hideSubmit)
        }
      >
        <Button
          colorScheme="blue"
          className="w-fit"
          onClick={() =>
            onSubmit(multiSelect ? (selected as string[]).join(';') : (selected as string))
          }
        >
          {submitText}
        </Button>
      </Show>
    </div>
  );
};

export default ButtonSelection;
