import { OnComplete, Page } from 'types/ClientForm';
import WidgetSelector from './WidgetSelector.view';

type Props = {
  pageData: Page;
  onPageComplete: OnComplete;
};

const ClientPage = ({ pageData, onPageComplete }: Props) => {
  const { components, title } = pageData;
  if (components.length > 1) {
    //TODO: need to decide if we support it
    throw new Error('Currently multi components in page is not supported');
  }
  //const { label } = components[0];

  return (
    <div className="flex h-full">
      <WidgetSelector
        title={title}
        component={components[0]}
        onSubmit={(data) => {
          onPageComplete(data);
        }}
      />
    </div>
  );
};

export default ClientPage;
