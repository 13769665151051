import OuterClientForm from 'views/OuterClientForm.view';
import { createBrowserRouter } from 'react-router-dom';
import MainError from 'views/MainError.view';
import DemoSurveyResponseView from 'views/DemoSurveyResponse.view';
import Dashboard from 'views/Insights.view';
import ContactUs from 'views/Informational/ContactUsNew.view';
import AboutUs from 'views/Informational/AboutUs.view';
import WithAuth from 'components/Route/ProtectedRoute.component';
import AdminPanel from 'views/AdminPanel.view';
import LoginPage from 'views/LoginPage.view';
import MainContainer from 'views/MainContainer.view';
import Landing2 from 'views/Informational/Landing2.view';
import Terms from 'views/Informational/Terms.view';
import Privacy from 'views/Informational/Privacy.view';
import ChatSurvey from './views/ChatSurvey.view';
import Test from './components/Utility/Test.component';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing2 />,
    errorElement: <MainError />,
  },
  {
    path: '/Landing',
    element: <Landing2 />,
    errorElement: <MainError />,
  },
  {
    path: '/admin',
    element: (
      <WithAuth redirectTo="/" isAdmin={true}>
        <AdminPanel />
      </WithAuth>
    ),
    errorElement: <MainError />,
  },
  // {
  //   path: 'survey/:surveyId/:userId',
  //   element: <OuterClientForm />,
  //   errorElement: <MainError />,
  // },
  {
    path: 'interview/:cid/:instanceId/:userId/questions',
    element: <OuterClientForm />,
    errorElement: <MainError />,
  },
  {
    path: '/survey/:cid/:instanceId/:userId',
    element: <ChatSurvey />,
  },
  {
    path: '/response',
    element: <DemoSurveyResponseView />,
    errorElement: <MainError />,
  },
  {
    path: '/insights',
    element: <Dashboard />,
    errorElement: <MainError />,
  },
  {
    path: '/contact',
    element: <ContactUs />,
    errorElement: <MainError />,
  },
  {
    path: '/about',
    element: <AboutUs />,
    errorElement: <MainError />,
  },
  {
    path: '/terms',
    element: <Terms />,
    errorElement: <MainError />,
  },
  {
    path: '/privacy',
    element: <Privacy />,
    errorElement: <MainError />,
  },
  {
    path: '/:cid/dashboard',
    element: (
      <MainContainer>
        <Dashboard />
      </MainContainer>
    ),
    errorElement: <MainError />,
  },
  {
    path: '/login',
    element: <LoginPage />,
    errorElement: <MainError />,
  },
  {
    path: '/test',
    element: <Test />,
  },
]);

export default router;
