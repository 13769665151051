const SentimentColorKey: Record<string, string> = {
  positive: '#00C49F',
  neutral: '#FFBB28',
  negative: '#ff4c24',
};

const GenderColorKey: Record<string, string> = {
  male: '#1263c7',
  female: '#db76db',
  other: '#f0f06e',
};

export { SentimentColorKey, GenderColorKey };
